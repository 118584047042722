import React, { Component } from 'react';
import './company.css';
import logo from "./logo.jpg";
import hr from "./hr.svg";
import Axios from 'axios';
import { NavLink } from 'react-router-dom';
import Spinner from '../../app/shared/Spinner';


class SingleCompanyView extends Component{
    
    constructor(props) {
        super(props);

        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            name: '',
            phone: '',
            email: '',
            logo: '',
            address: '',
            noOfTeam: '',
            noOfHiredEmployee: '',
            dataStatus: "false"
        }
    }
    componentDidMount() {
        var id = sessionStorage.getItem("companyId");
        let formData = new FormData();
        formData.append("id", id);
        // console.log(obj)
        var url = this.state.url + "getSingleCompanyData.php";
        Axios.post(url, formData).then((res) => {
          // console.log(res.data);
          this.setState({
            name: res.data[0].name,
            phone: res.data[0].phone,
            email: res.data[0].email,
            logo: res.data[0].logo,
            address: res.data[0].address,
            noOfTeam: res.data[0].noOfTeam,
            noOfHiredEmployee: res.data[0].noOfHiredEmployee,
            dataStatus: "true"
            });
        });
    }

    render(){
        return(
            <div>
                {sessionStorage.getItem("aId") ? (
                    <>
                        <div className="page-header">
                            <h3 className="page-title">Company Details</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Requested Project</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">View Detail</li>
                                </ol>
                            </nav>
                        </div>
                        {this.state.dataStatus === "false" ? (
                              <Spinner />
                        ) : (
                        <div className="row mt-5">
                            <div className="col-md-12 view_project bg-white col-12">
                                <div className="row border-bottom">
                                    <div className="col-md-6 col-8">
                                        <h6 className=" pl-3 pt-3 pb-1">View Company Detail</h6>
                                    </div>  
                                    <div className="col-md-6 col-4 close_button">
                                        <NavLink  to="/company"> <i className="mdi mdi-close"></i></NavLink>
                                    </div>
                                </div>

                                <div className="row mt-2 px-4 req_project_detail">
                                    <div className="col-md-12">
                                        {(() => {
                                            if (this.state.logo !== null && this.state.logo !== "null" && this.state.logo !== "") {
                                              return (
                                                <div class="form-group row" id="companyLogo" style={{borderBottom:'none'}}>
                                                    <div className="col-md-4"><label class="col-form-label">Company Logo</label></div>
                                                    <div className="col-md-8">
                                                        <img className="img-fluid" src={"https://www.digitizedresume.com/api/company/assets/profile/"+this.state.logo} alt={this.state.name}/>
                                                    </div>
                                                </div>
                                              );
                                            }  
                                        })()}
                                        
                                        <div class="form-group row">
                                            <label class="col-md-4 col-form-label">Company Name</label>
                                            <div class="col-md-8">
                                                <input type="text" disabled readonly class="form-control-plaintext" value={this.state.name} />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-4 col-form-label">Mobile Number</label>
                                            <div class="col-md-8">
                                                <input type="text" readonly class="form-control-plaintext" value={this.state.phone} />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-4 col-form-label">Email Id</label>
                                            <div class="col-md-8">
                                                <input type="text" disabled readonly class="form-control-plaintext" value={this.state.email} />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-4 col-form-label">Total Number of Team</label>
                                            <div class="col-md-8">
                                                <input type="text" disabled readonly class="form-control-plaintext" value={this.state.noOfTeam} />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-md-4 col-form-label">Total Number of Hired Employee</label>
                                            <div class="col-md-8">
                                                <input type="text" disabled readonly class="form-control-plaintext" value={this.state.noOfHiredEmployee} />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-md-4 col-form-label">Address</label>
                                            <div class="col-md-8">
                                               <textarea disabled rows="4" cols="30" readonly class="form-control-plaintext" value={this.state.address} />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        )}
                    </>
                ) : false}
            </div>
        )
    }
}
export default SingleCompanyView;