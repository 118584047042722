import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import Axios from 'axios';
import profileImage from './pic.png';


class Navbar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      url: "https://www.digitizedresume.com/api/admin/",
      data: [],
      logo: "",
      aId: "",
      name: ''
    };
    var aId = sessionStorage.getItem("aId");
    this.setState({
      aId: aId,
    });
  }

  componentDidMount() {
    var aId = sessionStorage.getItem("aId");
    if(sessionStorage.getItem("aId")){
    let formData = new FormData();
    formData.append("id", aId);
    // console.log(obj)
    var url = this.state.url + "getSingleAdminData.php";
        Axios.post(url, formData).then((res) => {
          // console.log(res.data);
          this.setState({
            name: res.data[0].name,
            phone: res.data[0].phone,
            email: res.data[0].email,
            password: res.data[0].password,
            image: res.data[0].image
            });
        });
    }    
  }
  logOut = (e) => {
    sessionStorage.removeItem("aId");
    window.location.href = "https://admin.digitizedresume.com/";
  };

  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }
  render () {  
    return (
      <div>
        {sessionStorage.getItem("aId") ? (
          <>
            <nav className="navbar col-lg-12 shadow-sm col-12 p-lg-0 fixed-top d-flex flex-row" id="topnavigationbar">
              <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
              <a className="navbar-brand brand-logo-mini align-self-center d-lg-none" href="!#" onClick={evt =>evt.preventDefault()}><img src={require("../../assets/images/logo-mini.svg")} alt="logo" /></a>
                <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
                  <i className="mdi mdi-menu"></i>
                </button>
               
               
                <ul className="navbar-nav navbar-nav-right">            
                  <li className="nav-item  nav-profile border-0">
                    <Dropdown>
                      <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                        {(() => {
                          if (this.state.logo === null ||  this.state.logo === "" || this.state.logo === "") {
                            return (
                              <img className="img-xs rounded-circle" src={profileImage} alt="Profile" />
                            );
                          } else {
                            return (
                              <img
                                className="img-xs rounded-circle"
                                src={
                                  "https://www.digitizedresume.com/api/admin/assets/profile/" +
                                  this.state.logo
                                }
                                alt={this.state.name}
                              />
                            );
                          }
                        })()}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="preview-list navbar-dropdown pb-3">  
                        <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-bottom" style={{backgroundColor:'rgb(82, 118, 218)',color:'#fff'}}>
                          <Trans>{this.state.name}</Trans>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={this.logOut}>
                          <Trans>Sign Out</Trans>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
                  <span className="mdi mdi-menu"></span>
                </button>
              </div>
            </nav>
          </>
        ) : false}
      </div>  
    );
  }
}

export default Navbar;
