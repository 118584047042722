import React, { Component } from 'react';
import './req-project.css';
import { NavLink } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';


class SingleReqProjectView extends Component{

    constructor(props) {
        super(props);

        this.state = {
              url: "https://www.digitizedresume.com/api/admin/",
              name: '',
              companyName: '',
              devNeed: '',
              description: '',
              devType: '',
              projectDuration: '',
              techDescription: '',
              note: '',
              dataStatus: "false",
        }
    }
    componentDidMount() {
        var id = sessionStorage.getItem("companyProjectId");
        let formData = new FormData();
        // alert(id);
        formData.append("id", id);
        // console.log(obj)
        var url = this.state.url + "getSingleProjectData.php";
        Axios.post(url, formData).then((res) => {
          // alert(res.data);
          this.setState({
            name: res.data[0].name,
            companyName: res.data[0].companyName,
            devNeed: res.data[0].devNeed,
            description: res.data[0].description,
            devType: res.data[0].devType,
            projectDuration: res.data[0].projectDuration,
            techDescription: res.data[0].techDescription,
            note: res.data[0].note,
            dataStatus: "true"
            });
        });
    }

    render(){
        return(
            <div>
                {sessionStorage.getItem("aId") ? (
                    <>
                        <div className="page-header">
                            <h3 className="page-title">Requested Project</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Requested Project</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">View Detail</li>
                                </ol>
                            </nav>
                        </div>
                        {this.state.dataStatus === "false" ? (
                              <Spinner />
                        ) : (
                        <div className="row mt-5">
                            <div className="col-md-12 view_project bg-white col-12">
                                <div className="row border-bottom">
                                    <div className="col-md-6 col-8">
                                        <h6 className=" pl-3 pt-3 pb-1">View Requested Project Detail</h6>
                                    </div>  
                                    <div className="col-md-6 col-4 close_button">
                                    <NavLink  to="/requested-project"> <i className="mdi mdi-close"></i></NavLink>
                                    </div>
                                </div>

                                <div className="row mt-2 px-4 req_project_detail">
                                    <div className="col-md-12">
                                        <div class="form-group row">
                                            <label class="col-md-4 col-form-label">Company Name</label>
                                            <div class="col-md-8">
                                                <input disabled type="text" readonly class="form-control-plaintext" value={this.state.companyName} />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-4 col-form-label">Project Name</label>
                                            <div class="col-md-8">
                                                <input disabled type="text" readonly class="form-control-plaintext" value={this.state.name} />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-4 col-form-label">No. Of Professional Needed</label>
                                            <div class="col-md-8">
                                                <input disabled type="text" readonly class="form-control-plaintext" value={this.state.devNeed} />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-4 col-form-label">Project Discription</label>
                                            <div class="col-md-8">
                                                <textarea disabled rows="5" cols="30" readonly class="form-control-plaintext" value={this.state.description} />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-4 col-form-label">Types Of Developers</label>
                                            <div class="col-md-8">
                                                <input disabled type="text" readonly class="form-control-plaintext" value={this.state.devType} />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-md-4 col-form-label">Project Duration</label>
                                            <div class="col-md-8">
                                                <input disabled type="text" readonly class="form-control-plaintext" value={this.state.projectDuration} />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-md-4 col-form-label">Technology Discription</label>
                                            <div class="col-md-8">
                                               <textarea disabled rows="4" cols="30" readonly class="form-control-plaintext" value={this.state.techDescription} />
                                            </div>
                                        </div>

                                        <div class="form-group row" style={{borderBottom:'none'}}>
                                            <label class="col-md-4 col-form-label">Note</label>
                                            <div class="col-md-8">
                                               <textarea disabled rows="4" cols="30" readonly class="form-control-plaintext" value={this.state.note} />
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                        )}
                    </>
                ) : false}
            </div>
        )
    }
}
export default SingleReqProjectView