import React, { Component } from 'react';
import './admin.css';
import { MDBDataTable } from "mdbreact";
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';
import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';

class AdminView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      url: "https://www.digitizedresume.com/api/admin/",
      dataStatus: "false",
      data: [],
      searchBy: '',
      showHide: false,
    };
  }

  componentDidMount() {
    if (!sessionStorage.getItem("aId")) {
      window.location.href = "/login"
    }
    var url = this.state.url + "getAdminData.php";
    Axios.get(url)
      .then((res) => {
        this.setState({
          data: res.data,
          dataStatus: "true"
        });
        // console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  singleView = (e) => {
    e.preventDefault();
    // alert(e.target.value);
    sessionStorage.setItem("adminId", e.target.value);
  }

  delete = (e) => {
    e.preventDefault();
    var id = e.target.value;

    swal({
      title: "Are you sure?",
      text: "Once removed, you won't be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          this.setState({
            dataStatus: "false",
          });
          let formData = new FormData();
          formData.append("id", id);
          var url = this.state.url + "deleteAdminData.php";

          Axios.post(url, formData)
            .then((res) => {
              this.setState({
                dataStatus: "false"
              });
              swal({
                title: "Deleted!",
                text: "Admin deleted successfully!",
                button: "Close",
                icon: "success",
                timer: 3000,
                button: {
                  confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                  }
                }
              });
              var url = this.state.url + "getAdminData.php";
              Axios.get(url)
                .then((res) => {
                  this.setState({
                    dataStatus: "true",
                    data: res.data
                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
            .catch((err) => {
              // console.log(err);
              this.setState({
                res: err,
              })
            });
        }
      });
  }

  handleModalShowHide = (e) => {
    e.preventDefault();
    this.setState({
      showHide: !this.state.showHide
    });
  }

  resetFilter = (e) => {
    this.setState({
      dataStatus: 'false'
    })
    var url = this.state.url + "getAdminData.php";
    Axios.get(url)
      .then((res) => {
        this.setState({
          data: res.data,
          dataStatus: "true"
        });
        // console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  filterSearchData = (e) => {
    e.preventDefault();
    var nameSearchAdmin = document.getElementById("nameSearchAdmin").value;
    var phoneSearchAdmin = document.getElementById("phoneSearchAdmin").value;
    var adminIdSearchAdmin = document.getElementById("adminIdSearchAdmin").value;

    if (nameSearchAdmin !== "" || phoneSearchAdmin !== "" || adminIdSearchAdmin !== "") {


      this.setState({
        dataStatus: "false",
        showHide: !this.state.showHide
      });

      var SQL = "";
      var totalQuery = 0;

      SQL = "SELECT * FROM admin WHERE ";
      if (nameSearchAdmin !== "") {
        if (totalQuery > 0) {
          SQL += " AND name LIKE '%" + nameSearchAdmin + "%'";
          totalQuery += 1;
        }
        else {
          SQL += "name LIKE '%" + nameSearchAdmin + "%'";
          totalQuery += 1;
        }
      }
      if (phoneSearchAdmin !== "") {
        if (totalQuery > 0) {
          SQL += " AND phone = " + phoneSearchAdmin;
          totalQuery += 1;
        }
        else {
          SQL += "phone = " + phoneSearchAdmin;
          totalQuery += 1;
        }
      }
      if (adminIdSearchAdmin !== "") {
        if (totalQuery > 0) {
          SQL += " AND uniqueId LIKE '%" + adminIdSearchAdmin + "%'";
          totalQuery += 1;
        }
        else {
          SQL += "uniqueId LIKE '%" + adminIdSearchAdmin + "%'";
          totalQuery += 1;
        }
      }

      SQL += ";";

      let formData = new FormData();
      formData.append("sql_Query", SQL);

      console.log(SQL);

      var searchURL = this.state.url + "filterData/filterData.php";

      Axios.post(searchURL, formData).then((res) => {
        // alert(res.data);
        this.setState({
          data: res.data,
          dataStatus: "true"
        });
      })

    }
    else {
      swal({
        title: "Error!",
        text: "Please fill at least any one field to filter!",
        button: "Close",
        icon: "error",
        timer: 3000,
        button: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      });
    }
  }

  render() {
    return (
      <>
        {sessionStorage.getItem("aId") ? (
          <>
            <div>
              <div className="page-header">
                <h3 className="page-title">Admin</h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Admin</a></li>
                    <li className="breadcrumb-item active" aria-current="page">All Admins</li>
                  </ol>
                </nav>
              </div>
              <div className="row my-4">
                <div className="col-md-8"></div>
                <div className="col-md-4 text-right sticky">
                  <button className="btn btn-sm btn-info" onClick={this.handleModalShowHide}>Filter <i className="fa fa-filter"></i></button>
                  &nbsp;
                  <button className="btn btn-sm btn-danger" onClick={this.resetFilter}>Rest Filter <i className="fa fa-close"></i></button>
                </div>
              </div>
              <Modal show={this.state.showHide}>
                <Modal.Header>
                  <Modal.Title>Filter Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <div className="row">
                      <div className=" mt-2 col-md-12">
                        <label className="form-label" for="nameSearchAdmin">Enter Admin Name</label>
                        <input type="text" className="form-control" name="nameSearchAdmin" id="nameSearchAdmin" placeholder="Enter Admin Name" ></input>
                      </div>
                      <div className=" mt-2 col-md-6">
                        <label className="form-label" for="phoneSearchAdmin">Enter Phone</label>
                        <input type="number" className="form-control" name="phoneSearchAdmin" id="phoneSearchAdmin" placeholder="Enter Phone" ></input>
                      </div>
                      <div className="mt-2 col-md-6">
                        <label className="form-label" for="adminIdSearchAdmin">Enter Admin Id</label>
                        <input type="text" className="form-control" name="adminIdSearchAdmin" id="adminIdSearchAdmin" placeholder="Enter Admin Id" ></input>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="danger" id="modalSkip" onClick={this.handleModalShowHide}>
                    Cancel
                  </Button>
                  <Button type="submit" onClick={this.filterSearchData} id="modalValidate" variant="success">
                    Filter
                  </Button>
                </Modal.Footer>
              </Modal>
              {this.state.dataStatus === "false" ? (
                <Spinner />
              ) : (
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card" style={{ marginTop: "10px" }}>
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title">Admin Details</h4>
                        <div className="table-responsive">
                          <table className="table" id="adminTable">
                            <thead>
                              <tr>
                                <th>SN.</th>
                                <th>Unique Id</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th colSpan="7">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.data.length === 0 ? (
                                <tr>
                                  <td colSpan="7" className="text-center">
                                    No Records Found
                                  </td>
                                </tr>
                              ) : (
                                this.state.data.map((ele, i) => {
                                  return (
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{ele.uniqueId}</td>
                                      <td>{ele.name}</td>
                                      <td>{ele.email}</td>
                                      <td>{ele.phone}</td>
                                      <td>
                                        <div className="adminview">
                                          <NavLink to="/company-details">
                                            <button
                                              className=""
                                              onClick={this.singleView}
                                              value={ele.id}
                                            >
                                              <NavLink to="/admin-single-view">
                                                <button
                                                  className="mdi mdi-eye"
                                                  value={ele.id}
                                                  id="buttonPadding"
                                                >
                                                </button>
                                              </NavLink>
                                            </button>
                                          </NavLink>
                                        </div>
                                      </td>

                                      {/* <td>
                                        <div className="adminedit">
                                          <NavLink to="/company-details">
                                            <button
                                              className=""
                                              onClick={this.singleView}
                                              value={ele.id}
                                            >
                                              <NavLink to="/view_admin">
                                                <button
                                                  className="mdi mdi-lead-pencil"
                                                  value={ele.id}
                                                  id="buttonPadding"
                                                >
                                                </button>
                                              </NavLink>
                                            </button>
                                          </NavLink>
                                        </div>
                                      </td> */}

                                      <td>
                                        <div className="admindelete">
                                          <button
                                            className=""
                                            onClick={this.delete}
                                            className="mdi mdi-window-close"
                                            value={ele.id}
                                            id="buttonPadding"
                                          >
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : false}
      </>
    )
  }
}
export default AdminView;