import React, { Component } from 'react';
import './singleuserview.css';


class Exprience extends Component{
    componentDidMount() {
        if(!sessionStorage.getItem("aId")){
            window.location.href="/login"
        }
    } 
    render(){
        return(
            <div className="row">
                <div className="col-md-11 education px-0 mx-0 col-12">
                    <p>Current Work Experience</p>
                    
                    <div className="row p-2">
                        <div className="col-md-6 mt-2">
                            <p>Total Experience in Years: </p>
                            <h6>3</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Total Experience in Months: </p>
                            <h6>6 month</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Current Designation: </p>
                            <h6>Web Developer</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Current Company:: </p>
                            <h6>xyx company</h6>
                        </div>

                        <div className="col-md-6 mt-2">
                            <p>Start Date: </p>
                            <h6>02/04/2017</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>End Date: </p>
                            <h6>02/06/2020</h6>
                        </div>

                        <div className="col-md-6 mt-2">
                            <p>Annual Salary: </p>
                            <h6>4L p/a</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Current Job Status: </p>
                            <h6>Full Time</h6>
                        </div>

                        <div className="col-md-6 mt-2">
                            <p>Notice Period: </p>
                            <h6>1 Months</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Industry: </p>
                            <h6>Computers</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Functional Area: </p>
                            <h6>Technology</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Role and Resposibility: </p>
                            <h6>Null</h6>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default Exprience