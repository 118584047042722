import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import AdminAdd from './admin/add';
import AdminEdit from './admin/adminedit';
import SingleView from './admin/singleview';
import AdminView from './admin/view';
import NotificationAdd from './notification/notification';
import NotificationEdit from './notification/notificationedit';
import NotificationView from './notification/view';
import Chat from './chat/chat';
import Conversation from './chat/conversation/conversation';
import CompanyTable from './company/company';
import SingleCompanyView from './company/SingleCompanyView';
import Login from './login/login';
import RequestedProject from './requested-project/req-project';
import SingleReqProjectView from './requested-project/single-req-project-view';
import SingleUserView from './usertable/singleuserview';
import UserTable from './usertable/usertable';
import Leads from './leads/leads';
import ViewLeads from './leads/view-leads'
import { EmailBroadcast } from './email_broadcast/emailbroadcast';
import { Invoice } from './invoice/invoice';
import { noOfHired } from './company/noofhire';
import { Coupan } from './coupan/coupan';
import { CouponView } from './coupan/couponview';
import { EditCoupon } from './coupan/editcoupon';
import Job from './job/Job';
import AppliedJobDetails from './job/AppliedJob';
import ViewSingleJob from './job/ViewSingleJob';

import ScheduleInterview from './interview/ScheduleInterview';
import ViewScheduledInterView from './interview/ViewScheduledInterView';
import ViewSingleInterviewDetails from './interview/ViewSingleInterviewDetails';

import HiredProfile from './hiredProfile/hiredProfile';
import SortlistProfile from './sortlisted/sortlist';

import ExploreProfile from './ExploreProfile/Exploreprofile';
import UserProfile from './ExploreProfile/userprofile';
import Category from './Learning/Category';
import SubCategory from './Learning/SubCategory';
import Video from './Learning/Video';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));

// const Error404 = lazy(() => import('./error-pages/Error404'));
// const Error500 = lazy(() => import('./error-pages/Error500'));

// const Login = lazy(() => import('./user-pages/Login'));
// const Register1 = lazy(() => import('./user-pages/Register'));


class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>

          <Route exact path="/dashboard" component={ Dashboard } />

          <Route exact path="/requested-project" component={ RequestedProject } />
          <Route exact path="/leads" component={ Leads } />
          <Route exact path="/view-requested-project" component={ SingleReqProjectView } />
           <Route exact path="/view-leads" component={ ViewLeads } />
          {/* <Route exact path="/chat" component={ Chat } /> */}
          {/* <Route exact path="/conversation" component={ Conversation } /> */}
          <Route exact path="/emailbroadcast" component={ EmailBroadcast } />
          <Route exact path="/invoice" component={ Invoice } />
          <Route exact path="/coupon" component={ Coupan } />
          <Route exact path="/view-coupon" component={ CouponView } />
          <Route exact path="/edit-coupon" component={ EditCoupon } />
          <Route exact path="/user-table" component={ UserTable } />
          <Route exact path="/user-single-view" component={ SingleUserView } />
          <Route exact path="/company" component={ CompanyTable } />
          <Route exact path="/company-details" component={ SingleCompanyView } />
          <Route exact path="/login" component={ Login } />
          <Route exact path="/add_admin" component={ AdminAdd } />
          <Route exact path="/view_admin" component={ AdminView } />
          <Route exact path="/admin-single-view" component={ SingleView } />
          <Route exact path="/Edit-Admin-Detail" component={ AdminEdit } />
          <Route exact path="/add_notification" component={ NotificationAdd } />
          <Route exact path="/view_notification" component={ NotificationView } />
          <Route exact path="/edit_notification" component={ NotificationEdit } />
          <Route exact path="/jobs" component={Job} />
          <Route exact path="/view-applied-job-details" component={AppliedJobDetails} />
          <Route exact path="/view-single-job" component={ViewSingleJob} />
          <Route path="/No-of-hired" component={ noOfHired } />

          <Route exact path="/exploreprofile" component={ ExploreProfile } />
          <Route exact path="/user_profile" component={ UserProfile } />
          <Route exact path="/hired" component={ HiredProfile } />
          <Route exact path="/sortlisted" component={ SortlistProfile } />
          <Route exact path="/schedule-interview" component={ ScheduleInterview } />
          <Route exact path="/view-scheduled-interview" component={ ViewScheduledInterView } />
          <Route exact path="/view-single-scheduled-interview" component={ ViewSingleInterviewDetails } />
          
          <Route exact path="/category" component={ Category } />
          <Route exact path="/topic" component={ SubCategory } />
          <Route exact path="/video" component={ Video } />

        
           {/* <Route path="/basic-ui/buttons" component={ Buttons } />
          <Route path="/basic-ui/dropdowns" component={ Dropdowns } />  */}

          {/* <Route path="/form-Elements/basic-elements" component={ BasicElements } />
          <Route path="/icons/mdi" component={ Mdi } /> */}


          {/* <Route path="/tables/basic-table" component={ BasicTable } /> */}

        

          {/* <Route path="/charts/chart-js" component={ ChartJs } />


          <Route path="/user-pages/login-1" component={ Login } />
          <Route path="/user-pages/register-1" component={ Register1 } />

          <Route path="/error-pages/error-404" component={ Error404 } />
          <Route path="/error-pages/error-500" component={ Error500 } /> */}


          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;