import React, { Component } from 'react';
import Profile from './profile';
import './singleuserview.css';


class SingleUserView extends Component{

  
componentDidMount() {
        if(!sessionStorage.getItem("aId")){
            window.location.href="/login"
        }
    } 


    render(){
        return(
            <div>
                <div className="page-header">
                    <h3 className="page-title">User</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>User</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Single View</li>
                        </ol>
                    </nav>
                </div>

                <div className="row mt-4">
                    <div className="col-md-12" id="userprofile">
                        <Profile />
                    </div>
                   
                </div>
            </div>
        )
    }
}
export default SingleUserView