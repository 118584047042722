import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';
import Pagination from "@material-ui/lab/Pagination";
import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';
import "./learning.css";

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            data: [],
            dataStatus: "false",
            page: 1,
            totalCol: 0,
            showHide: false,
        };
    }
    handleModalShowHide = (e) => {
        e.preventDefault();
        this.setState({
            showHide: !this.state.showHide
        });
    }
    componentDidMount() {
        var url = this.state.url + "getCategoryData.php";
        Axios.get(url)
            .then((res) => {
                console.log(res.data);
                this.setState({
                    data: res.data,
                    dataStatus: "true",
                });
            })
            .catch(function (error) {
                // alert(error);
                console.log(error);
            });
    }
    handlePagination = (e, val) => {
        if (this.state.page != val) {
            if (val > this.state.page) {
                this.setState({
                    page: val,
                    totalCol: val * 5 - 5,
                });
            } else {
                this.setState({
                    page: val,
                    totalCol: val * 5 - 5,
                });
            }
        }
    }
    addCategory = (e) => {
        e.preventDefault();
        var category = document.getElementById("categoryName").value;
        if (category !== "") {
            this.setState({
                showHide: !this.state.showHide,
                dataStatus: "false",
            });
            var url = this.state.url + "addCategory.php";
            let formData = new FormData();
            formData.append("category", category);
            Axios.post(url, formData).then((res) => {
                if (res.data === 1) {
                    var url = this.state.url + "getCategoryData.php";
                    Axios.get(url)
                        .then((res) => {
                            console.log(res.data);
                            this.setState({
                                data: res.data,
                                dataStatus: "true",
                            });
                        })
                        .catch(function (error) {
                            // alert(error);
                            console.log(error);
                        });
                    swal({
                        title: "Added!",
                        text: "Category added successfully!",
                        button: "Close",
                        icon: "success",
                        timer: 3000,
                        button: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                            }
                        }
                    });
                }
            })
        }
        else {
            swal({
                title: "Error!",
                text: "Please enter category name to add!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
    }
    deleteCategory = (e) => {
        e.preventDefault();
        var id = e.target.value;
        swal({
            title: "Are you sure?",
            text: "Once removed, you will have to add again!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    this.setState({
                        dataStatus: "false"
                    });
                    let formData = new FormData();
                    formData.append("id", id);
                    var url = this.state.url + "removeCategory.php";
                    Axios.post(url, formData)
                        .then((res) => {
                            // alert(res.data);
                            console.log(res.data);
                            var url = this.state.url + "getCategoryData.php";
                            Axios.get(url)
                                .then((res) => {
                                    // alert(res.data);
                                    this.setState({
                                        data: res.data,
                                        dataStatus: "true",
                                    });
                                })
                                .catch(function (error) {
                                    // alert(error);
                                    console.log(error);
                                });
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                }
            });
    }
    render() {
        return (
            <div>{sessionStorage.getItem("aId") ? (
                <div>
                    <div className="page-header">
                        <h3 className="page-title">Category</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="!#" onClick={(event) => event.preventDefault()}>
                                        Category
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    All Categories
                                </li>
                            </ol>
                        </nav>
                    </div>
                    {
                        this.state.dataStatus === "false" ? (<Spinner />) : (
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="row my-4">
                                        <div className="col-md-8"></div>
                                        <div className="col-md-4 text-right sticky">
                                            <button className="btn btn-sm btn-info" onClick={this.handleModalShowHide}><i className="fa fa-plus"></i> Add Category</button>
                                        </div>
                                    </div>
                                    <Modal show={this.state.showHide}>
                                        <Modal.Header>
                                            <Modal.Title>Add Category</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div>
                                                <div className="row">
                                                    <div className=" mt-2 col-md-12">
                                                        <label className="form-label" for="categoryName">Enter Category Name</label>
                                                        <input type="text" className="form-control" name="categoryName" id="categoryName" placeholder="Enter Category Name" ></input>
                                                    </div>
                                                    <small className="ml-3">Category will help the user to filter learning related video.</small>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="danger" id="modalSkip" onClick={this.handleModalShowHide}>
                                                Cancel
                                            </Button>
                                            <Button type="submit" onClick={this.addCategory} id="modalValidate" variant="success">
                                                Add
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>

                                <div className="col-md-12 grid-margin stretch-card" style={{ marginTop: "10px" }} >
                                    {this.state.dataStatus === "false" ? <Spinner /> :
                                        (
                                            <div className="card">

                                                <div className="card-body">

                                                    <h4 className="card-title">Category Details</h4>
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>SN.</th>
                                                                    <th>Category Name</th>
                                                                    <th>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.data.length === 0 ? (
                                                                    <tr>
                                                                        <td colSpan="3" className="text-center">
                                                                            No Records Found
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    this.state.data.map((ele, i) => {
                                                                        if (
                                                                            i < this.state.page * 5 &&
                                                                            this.state.totalCol <= i
                                                                        ) {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{i + 1}</td>
                                                                                    <td>{ele.category}</td>
                                                                                    <td><button className="btn btn-danger btn-sm" value={ele.id} onClick={this.deleteCategory}>Remove</button></td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    })
                                                                )}
                                                            </tbody>
                                                        </table>
                                                        <Pagination
                                                            count={parseInt(this.state.data.length / 5) + 1}
                                                            page={this.state.page}
                                                            onChange={this.handlePagination}
                                                            color="primary"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        )}
                                </div>

                            </div>
                        )
                    }
                </div>
            ) : (<Spinner />)}
            </div>
        );
    }
}

export default Category;