import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';
import Pagination from "@material-ui/lab/Pagination";
import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';
import "./learning.css";

class SubCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            data: [],
            category: [],
            subCategoryForFilter: [],
            dataStatus: "false",
            page: 1,
            totalCol: 0,
            showHide: false,
            filterDataModal: false,
        };
    }
    handleModalShowHide = (e) => {
        e.preventDefault();
        this.setState({
            showHide: !this.state.showHide
        });
    }
    componentDidMount() {
        var categoryURL = this.state.url + "getCategoryData.php";
        Axios.get(categoryURL)
            .then((res) => {
                // console.log(res.data);
                this.setState({
                    category: res.data,
                });
            })
            .catch(function (error) {
                // alert(error);
                console.log(error);
            });

        var url = this.state.url + "getSubCategoryData.php";
        Axios.get(url)
            .then((res) => {
                console.log(res.data);
                this.setState({
                    data: res.data,
                    dataStatus: "true",
                });
            })
            .catch(function (error) {
                // alert(error);
                console.log(error);
            });
    }
    handlePagination = (e, val) => {
        if (this.state.page != val) {
            if (val > this.state.page) {
                this.setState({
                    page: val,
                    totalCol: val * 5 - 5,
                });
            } else {
                this.setState({
                    page: val,
                    totalCol: val * 5 - 5,
                });
            }
        }
    }
    addSubCategory = (e) => {
        e.preventDefault();
        var category = document.getElementById("categoryName").value;
        var subCategory = document.getElementById("subCategoryName").value;
        if (category !== "" && subCategory !== "") {
            this.setState({
                showHide: !this.state.showHide,
                dataStatus: "false",
            });
            var url = this.state.url + "addSubCategory.php";
            let formData = new FormData();
            formData.append("category", category);
            formData.append("subCategory", subCategory);
            Axios.post(url, formData).then((res) => {
                if (res.data === 1) {
                    var url = this.state.url + "getSubCategoryData.php";
                    Axios.get(url)
                        .then((res) => {
                            console.log(res.data);
                            this.setState({
                                data: res.data,
                                dataStatus: "true",
                            });
                        })
                        .catch(function (error) {
                            // alert(error);
                            console.log(error);
                        });
                    swal({
                        title: "Added!",
                        text: "Topic added successfully!",
                        button: "Close",
                        icon: "success",
                        timer: 3000,
                        button: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                            }
                        }
                    });
                }
            })
        }
        else {
            swal({
                title: "Error!",
                text: "Please fill the form to proceed further!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
    }
    deleteCategory = (e) => {
        e.preventDefault();
        var id = e.target.value;
        swal({
            title: "Are you sure?",
            text: "Once removed, you will have to add again!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    this.setState({
                        dataStatus: "false"
                    });
                    let formData = new FormData();
                    formData.append("id", id);
                    var url = this.state.url + "removeSubCategory.php";
                    Axios.post(url, formData)
                        .then((res) => {
                            var url = this.state.url + "getSubCategoryData.php";
                            Axios.get(url)
                                .then((res) => {
                                    console.log(res.data);
                                    this.setState({
                                        data: res.data,
                                        dataStatus: "true",
                                    });
                                })
                                .catch(function (error) {
                                    // alert(error);
                                    console.log(error);
                                });
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                }
            });
    }
    resetFilter = (e) => {
        this.setState({
            dataStatus: 'false'
        })
        var url = this.state.url + "getSubCategoryData.php";
        Axios.get(url)
            .then((res) => {
                console.log(res.data);
                this.setState({
                    data: res.data,
                    dataStatus: "true",
                });
            })
            .catch(function (error) {
                // alert(error);
                console.log(error);
            });
    }
    filterSearchData = (e) => {
        e.preventDefault();
        var categorySearchVideo = document.getElementById("categorySearchVideo").value;
        var videoSubCategoryForFilter = document.getElementById("videoSubCategoryForFilter").value;

        if (categorySearchVideo !== "" || videoSubCategoryForFilter !== "") {
            var id = sessionStorage.getItem("aId");
            this.setState({
                dataStatus: "false",
                filterDataModal: !this.state.filterDataModal
            });

            var SQL = "";
            var query = 0;

            SQL = "SELECT * FROM videosubcategory WHERE ";
            if (categorySearchVideo !== "") {
                if (query > 0) {
                    SQL += " AND category LIKE '%" + categorySearchVideo + "%'";
                    query += 1;
                }
                else {
                    SQL += " category LIKE '%" + categorySearchVideo + "%'";
                    query += 1;
                }
            }
            if (videoSubCategoryForFilter !== "") {
                if (query > 0) {
                    SQL += " AND subCategory LIKE '%" + videoSubCategoryForFilter + "%'";
                    query += 1;
                }
                else {
                    SQL += " subCategory LIKE '%" + videoSubCategoryForFilter + "%'";
                    query += 1;
                }
            }

            SQL += ";";

            let formData = new FormData();
            formData.append("sql_Query", SQL);

            console.log(SQL);

            var searchURL = this.state.url + "filterData/filterData.php";

            Axios.post(searchURL, formData).then((res) => {
                // alert(res.data);
                this.setState({
                    data: res.data,
                    dataStatus: "true"
                });
            })

        }
        else {
            swal({
                title: "Error!",
                text: "Please fill at least any one field to filter!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
    }
    handle = (e) => {
        e.preventDefault();
        var subCategoryURL = this.state.url + "getSubCategoryDataForSingleCategory.php";

        let formData = new FormData();
        formData.append("category", document.getElementById("categorySearchVideo").value);
        Axios.post(subCategoryURL, formData)
            .then((res) => {
                console.log(res.data);
                // alert(res.data);
                this.setState({
                    subCategoryForFilter: res.data,
                    dataStatus: "true",
                });
            })
            .catch(function (error) {
                // alert(error);
                console.log(error);
            });
    }
    filterDataModalHandleShowHide = (e) => {
        e.preventDefault();
        this.setState({
            filterDataModal: !this.state.filterDataModal,
            subCategoryForFilter: [],
        });
    }
    render() {
        return (
            <div>{sessionStorage.getItem("aId") ? (
                <div>
                    <div className="page-header">
                        <h3 className="page-title">Topic</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="!#" onClick={(event) => event.preventDefault()}>
                                        Topic
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    All Topics
                                </li>
                            </ol>
                        </nav>
                    </div>
                    {
                        this.state.dataStatus === "false" ? (<Spinner />) : (
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="row my-4">
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6 text-right sticky">
                                            <button className="btn btn-sm btn-info" onClick={this.handleModalShowHide}><i className="fa fa-plus"></i> Add Topic</button>
                                            &nbsp;
                                            <button className="btn btn-sm btn-info" onClick={this.filterDataModalHandleShowHide}>Filter <i className="fa fa-filter"></i></button>
                                            &nbsp;
                                            <button className="btn btn-sm btn-danger" onClick={this.resetFilter}>Rest Filter <i className="fa fa-close"></i></button>
                                        </div>
                                    </div>
                                    {/* filter data modal start */}
                                    <Modal show={this.state.filterDataModal}>
                                        <Modal.Header>
                                            <Modal.Title>Filter Video</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div>
                                                <div className="row">
                                                    <div className="mt-2 col-md-6">
                                                        <label className="form-label" for="categorySearchVideo">Select Video Category</label>
                                                        <select id="categorySearchVideo" onChange={this.handle} className="form-control" name="categorySearchVideo">
                                                            {this.state.category.length === 0 ? (
                                                                <option value="" disabled selected>Add Category First - No Category Found</option>
                                                            ) : (
                                                                this.state.category.map((ele, i) => {
                                                                    // console.log(i);
                                                                    if (i === 0) {
                                                                        return (
                                                                            <>
                                                                                <option value="" disabled selected>Select Any</option>
                                                                                <option value={ele.category}>{ele.category}</option>
                                                                            </>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <option value={ele.category}>{ele.category}</option>
                                                                    );

                                                                })
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className="mt-2 col-md-6">
                                                        <label className="form-label" for="videoSubCategoryForFilter">Select Video Sub Category</label>
                                                        <select id="videoSubCategoryForFilter" onChange={this.handle} className="form-control" name="videoSubCategoryForFilter">
                                                            {this.state.subCategoryForFilter.length === 0 ? (
                                                                <option value="" disabled selected>Add Sub Category First - No Sub Category Found</option>
                                                            ) : (
                                                                this.state.subCategoryForFilter.map((ele, i) => {
                                                                    // console.log(i);
                                                                    if (i === 0) {
                                                                        return (
                                                                            <>
                                                                                <option value="" disabled selected>Select Any</option>
                                                                                <option value={ele.subCategory}>{ele.subCategory}</option>
                                                                            </>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <option value={ele.subCategory}>{ele.subCategory}</option>
                                                                    );

                                                                })
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="danger" id="modalSkip" onClick={this.filterDataModalHandleShowHide}>
                                                Cancel
                                            </Button>
                                            <Button type="submit" onClick={this.filterSearchData} id="modalValidate" variant="success">
                                                Filter
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    {/* filter data modal end */}
                                    {/* modal to add topic start */}
                                    <Modal show={this.state.showHide}>
                                        <Modal.Header>
                                            <Modal.Title>Add Topic</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div>
                                                <div className="row">
                                                    <div className="mt-2 col-md-12">
                                                        <label className="form-label" for="categoryName">Select Category</label>
                                                        <select id="categoryName" className="form-control" name="categoryName">
                                                            {this.state.category.length === 0 ? (
                                                                <option value="" disabled selected>Add Category First - No Category Found</option>
                                                            ) : (
                                                                this.state.category.map((ele, i) => {
                                                                    // console.log(i);
                                                                    if (i === 0) {
                                                                        return (
                                                                            <>
                                                                                <option value="" disabled selected>Select Any</option>
                                                                                <option value={ele.category}>{ele.category}</option>
                                                                            </>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <option value={ele.category}>{ele.category}</option>
                                                                    );

                                                                })
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className=" mt-2 col-md-12">
                                                        <label className="form-label" for="subCategoryName">Enter Topic Name</label>
                                                        <input type="text" className="form-control" name="subCategoryName" id="subCategoryName" placeholder="Enter Topic Name" ></input>
                                                    </div>
                                                    <small className="ml-3">Topic will help the user to filter learning related video.</small>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="danger" id="modalSkip" onClick={this.handleModalShowHide}>
                                                Cancel
                                            </Button>
                                            <Button type="submit" onClick={this.addSubCategory} id="modalValidate" variant="success">
                                                Add
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    {/* modal to add topic end */}
                                </div>

                                <div className="col-md-12 grid-margin stretch-card" style={{ marginTop: "10px" }} >
                                    {this.state.dataStatus === "false" ? <Spinner /> :
                                        (
                                            <div className="card">

                                                <div className="card-body">

                                                    <h4 className="card-title">Topic Details</h4>
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>SN.</th>
                                                                    <th>Category</th>
                                                                    <th>Topic</th>
                                                                    <th>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.data.length === 0 ? (
                                                                    <tr>
                                                                        <td colSpan="3" className="text-center">
                                                                            No Records Found
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    this.state.data.map((ele, i) => {
                                                                        if (
                                                                            i < this.state.page * 5 &&
                                                                            this.state.totalCol <= i
                                                                        ) {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{i + 1}</td>
                                                                                    <td>{ele.category}</td>
                                                                                    <td>{ele.subCategory}</td>
                                                                                    <td><button className="btn btn-danger btn-sm" value={ele.id} onClick={this.deleteCategory}>Remove</button></td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    })
                                                                )}
                                                            </tbody>
                                                        </table>
                                                        <Pagination
                                                            count={parseInt(this.state.data.length / 5) + 1}
                                                            page={this.state.page}
                                                            onChange={this.handlePagination}
                                                            color="primary"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        )}
                                </div>

                            </div>
                        )
                    }
                </div>
            ) : (<Spinner />)}
            </div>
        );
    }
}

export default SubCategory;