import React, { Component } from 'react';
import './coupan.css';
import { MDBDataTable } from "mdbreact";
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';
import Pagination from "@material-ui/lab/Pagination";
import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';


export class CouponView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            dataStatus: "false",
            data: [],
            searchBy: '',
            page: 1,
            totalCol: 0,
            showHide: false,
        }
    }

    handlePagination = (e, val) => {
        if (this.state.page != val) {
            if (val > this.state.page) {
                this.setState({
                    page: val,
                    totalCol: val * 5 - 5,
                });
            } else {
                this.setState({
                    page: val,
                    totalCol: val * 5 - 5,
                });
            }
        }
    }

    componentDidMount() {
        if (!sessionStorage.getItem("aId")) {
            window.location.href = "/login"
        }
        var url = this.state.url + "getCouponData.php";
        Axios.get(url)
            .then((res) => {
                this.setState({
                    data: res.data,
                    dataStatus: "true"
                });
                // console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    edit = (e) => {
        e.preventDefault();
        // alert(e.target.value);
        sessionStorage.setItem("couponId", e.target.value);
    }

    delete = (e) => {
        e.preventDefault();
        var id = e.target.value;

        swal({
            title: "Are you sure?",
            text: "Once removed, you won't be able to recover!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let formData = new FormData();
                    formData.append("id", id);
                    var url = this.state.url + "deleteCoupon.php";

                    Axios.post(url, formData)
                        .then((res) => {
                            this.setState({
                                dataStatus: "false"
                            });
                            swal({
                                title: "Deleted!",
                                text: "Coupon deleted successfully!",
                                button: "Close",
                                icon: "success",
                                timer: 3000,
                                button: {
                                    confirm: {
                                        text: "OK",
                                        value: true,
                                        visible: true,
                                        className: "",
                                        closeModal: true
                                    }
                                }
                            });
                            var url = this.state.url + "getCouponData.php";
                            Axios.get(url)
                                .then((res) => {
                                    this.setState({
                                        dataStatus: "true",
                                        data: res.data
                                    });
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                        })
                        .catch((err) => {
                            // console.log(err);
                            this.setState({
                                res: err,
                            })
                        });

                }
            });
    }

    handleModalShowHide = (e) => {
        e.preventDefault();
        this.setState({
            showHide: !this.state.showHide
        });
    }

    resetFilter = (e) => {
        this.setState({
            dataStatus: 'false'
        })
        var url = this.state.url + "getCouponData.php";
        Axios.get(url)
            .then((res) => {
                this.setState({
                    data: res.data,
                    dataStatus: "true"
                });
                // console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    filterSearchData = (e) => {
        e.preventDefault();
        var couponNumberSearchCoupon = document.getElementById("couponNumberSearchCoupon").value;
        var amountSearchCoupon = document.getElementById("amountSearchCoupon").value;
        var statusSearchCoupon = document.getElementById("statusSearchCoupon").value;

        if (couponNumberSearchCoupon !== "" || amountSearchCoupon !== "" || statusSearchCoupon !== "") {


            this.setState({
                dataStatus: "false",
                showHide: !this.state.showHide
            });

            var SQL = "";
            var totalQuery = 0;

            SQL = "SELECT * FROM coupon WHERE ";
            if (couponNumberSearchCoupon !== "") {
                if (totalQuery > 0) {
                    SQL += " AND couponId LIKE '" + couponNumberSearchCoupon + "'";
                    totalQuery += 1;
                }
                else {
                    SQL += "couponId LIKE '" + couponNumberSearchCoupon + "'";
                    totalQuery += 1;
                }
            }
            if (amountSearchCoupon !== "") {
                if (totalQuery > 0) {
                    SQL += " AND amount <= " + amountSearchCoupon;
                    totalQuery += 1;
                }
                else {
                    SQL += "amount <= " + amountSearchCoupon;
                    totalQuery += 1;
                }
            }
            if (statusSearchCoupon !== "") {
                if(statusSearchCoupon === "Active"){
                    if (totalQuery > 0) {
                        SQL += " AND status LIKE 'Active'";
                        totalQuery += 1;
                    }
                    else {
                        SQL += "status LIKE 'Active'";
                        totalQuery += 1;
                    }
                }
                else{
                    if (totalQuery > 0) {
                        SQL += " AND status LIKE 'Deactive'";
                        totalQuery += 1;
                    }
                    else {
                        SQL += "status LIKE 'Deactive'";
                        totalQuery += 1;
                    }
                }
            }

            SQL += ";";

            let formData = new FormData();
            formData.append("sql_Query", SQL);

            console.log(SQL);

            var searchURL = this.state.url + "filterData/filterData.php";

            Axios.post(searchURL, formData).then((res) => {
                // alert(res.data);
                this.setState({
                    data: res.data,
                    dataStatus: "true"
                });
            })

        }
        else {
            swal({
                title: "Error!",
                text: "Please fill at least any one field to filter!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
    }

    render() {
        return (
            <div>
                <div className="page-header">
                    <h3 className="page-title">Coupon</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="!#">
                                    Coupon
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                All Coupons
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="row my-4">
                <div className="col-md-8"></div>
                <div className="col-md-4 text-right sticky">
                  <button className="btn btn-sm btn-info" onClick={this.handleModalShowHide}>Filter <i className="fa fa-filter"></i></button>
                  &nbsp;
                  <button className="btn btn-sm btn-danger" onClick={this.resetFilter}>Rest Filter <i className="fa fa-close"></i></button>
                </div>
              </div>
              <Modal show={this.state.showHide}>
                <Modal.Header>
                  <Modal.Title>Filter Coupon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <div className="row">
                      <div className=" mt-2 col-md-12">
                        <label className="form-label" for="couponNumberSearchCoupon">Enter Coupon Number</label>
                        <input type="text" className="form-control" name="couponNumberSearchCoupon" id="couponNumberSearchCoupon" placeholder="Enter Coupon Number" ></input>
                      </div>
                      <div className=" mt-2 col-md-6">
                        <label className="form-label" for="amountSearchCoupon">Enter Amount</label>
                        <input type="number" className="form-control" name="amountSearchCoupon" id="amountSearchCoupon" placeholder="Enter Amount" ></input>
                      </div>
                      <div className="mt-2 col-md-6">
                        <label className="form-label" for="statusSearchCoupon">Select Status</label>
                        <select id="statusSearchCoupon" name="statusSearchCoupon" className="form-control">
                            <option selected value="">Select Any</option>
                            <option value="Active">Active</option>
                            <option value="Deactive">Deactive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="danger" id="modalSkip" onClick={this.handleModalShowHide}>
                    Cancel
                  </Button>
                  <Button type="submit" onClick={this.filterSearchData} id="modalValidate" variant="success">
                    Filter
                  </Button>
                </Modal.Footer>
              </Modal>
                {this.state.dataStatus === "true" ?
                    <div>
                        <div className="col-md-12 grid-margin stretch-card" style={{ marginTop: "10px" }} >
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Coupon Details</h4>
                                    <div className="table-responsive">
                                        <table className="table" id="couponTable">
                                            <thead>
                                                <tr>
                                                    <th>SN.</th>
                                                    <th>Coupon No.</th>
                                                    <th>Current Limit</th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                    <th colSpan="2">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.data.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="7" className="text-center">
                                                            No Records Found
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    this.state.data.map((ele, i) => {
                                                        if (
                                                            i < this.state.page * 5 &&
                                                            this.state.totalCol <= i
                                                        )
                                                            return (
                                                                <tr>
                                                                    <td>{i + 1}</td>
                                                                    <td>{ele.couponId}</td>
                                                                    <td>{ele.limitation}</td>
                                                                    <td>{ele.amount}</td>
                                                                    <td>
                                                                        {
                                                                            ele.status === "Active" ?
                                                                                <span className="text-success">{ele.status}</span> :
                                                                                <span className="text-danger">{ele.status}</span>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <div className="adminview">
                                                                            <NavLink to="/edit-coupon">
                                                                                <button
                                                                                    className=""
                                                                                    onClick={this.edit}
                                                                                    value={ele.id}
                                                                                >
                                                                                    <NavLink to="/edit-coupon">
                                                                                        <button
                                                                                            className="mdi mdi-pencil"
                                                                                            value={ele.id}
                                                                                            id="buttonPadding"
                                                                                        >
                                                                                        </button>
                                                                                    </NavLink>
                                                                                </button>
                                                                            </NavLink>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="admindelete">
                                                                            <button
                                                                                className=""
                                                                                onClick={this.delete}
                                                                                className="mdi mdi-window-close"
                                                                                value={ele.id}
                                                                                id="buttonPadding"
                                                                            >
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                    })
                                                )}
                                            </tbody>
                                        </table>
                                        <Pagination
                                            count={parseInt(this.state.data.length / 5) + 1}
                                            page={this.state.page}
                                            onChange={this.handlePagination}
                                            color="primary"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <Spinner />
                }
            </div>
        )
    }
}