import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './job.css';
import Axios from 'axios';
import face from './pic.png';
import Spinner from '../shared/Spinner';

class AppliedJobDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            dataStatus: "false",
            interviewDescription: '',
            startTime: '',
            duration: '',
            interviewLink: '',
            interviewDate: '',
        };
    }

    componentDidMount() {
        var id = sessionStorage.getItem("jobId");
        let formData = new FormData();
        formData.append("id", id);
        var url = this.state.url + "viewAppliedApplicationDetails.php";
        Axios.post(url, formData)
            .then((res) => {
                this.setState({
                    data: res.data,
                    dataStatus: "true"
                });
                console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    removeInterviewScheduleStatus = (e) => {
        sessionStorage.removeItem("JobStatus");
    }

    setUserId = (e) => {
        e.preventDefault();
        // alert(e.target.value);
        sessionStorage.setItem("userId", e.target.value);
      }


    render() {
        return (
            <>
                {sessionStorage.getItem("JobStatus") ? (
                    <>
                        <div id="messageAlert">
                            <p id="hiredMessage"></p>
                        </div>
                        <div className="page-header">
                            <h3 className="page-title">Applied Candidate Details</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink exact to="/jobs">View Jobs</NavLink></li>
                                </ol>
                            </nav>
                        </div>

                        {this.state.dataStatus === "false" ? (
                            <Spinner />
                        ) : (
                            <div className="row">


                                {this.state.data.length === 0 ? (
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            No Records Found
                                        </td>
                                    </tr>
                                ) : (
                                    this.state.data.map((ele, i) => {
                                        return (
                                            <div className="col-sm-4 mt-5 mb-4 appliedCandidateCard">
                                                {ele.onlineCVStatus === "Active" ?
                                                    <div class="card activeCard">
                                                        <div class="imgBx">
                                                            {(() => {
                                                                if (ele.profileImage === null) {
                                                                    return <img src={face} alt={this.state.name} className="img-circle img-fluid" />;
                                                                } else {
                                                                    return (
                                                                        <img src={"https://www.digitizedresume.com/api/user/assets/profile/" + ele.profileImage} className="img-fluid shadow-sm" alt={ele.name} />
                                                                    );
                                                                }
                                                            })()}
                                                        </div>
                                                        <div class="contentBx">
                                                            <div class="size">
                                                                <h3>{ele.name}</h3>
                                                            </div>
                                                            <div class="color">
                                                                <h3>{"Applied Date: " + ele.appliedDate}</h3>
                                                            </div>
                                                            <button onClick={this.setUserId} value={ele.userId} className="btn btn-primary btn-sm infoButton1 mt-0 pt-0">
                                                                <NavLink className="text-center" to="/user_profile">
                                                                    <button value={ele.userId} className="btn btn-primary btn-sm infoButton2"> &#9432; </button>
                                                                </NavLink>
                                                            </button><br />
                                                            <a target="_blank" href={"https://digitizedresume.com/" + ele.domain}><button className="btn btn-warning btn-sm text-white mt-2">
                                                                View Digital CV
                                                            </button></a>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div class="card">
                                                        <div class="imgBx">
                                                            {(() => {
                                                                if (ele.profileImage === null) {
                                                                    return <img src={face} alt={this.state.name} className="img-circle img-fluid" />;
                                                                } else {
                                                                    return (
                                                                        <img src={"https://www.digitizedresume.com/api/user/assets/profile/" + ele.profileImage} className="img-fluid shadow-sm" alt={ele.name} />
                                                                    );
                                                                }
                                                            })()}
                                                        </div>
                                                        <div class="contentBx">
                                                            <div class="size">
                                                                <h3>{ele.name}</h3>
                                                            </div>
                                                            <div class="color">
                                                                <h3>{"Applied Date: " + ele.appliedDate}</h3>
                                                            </div>
                                                            <button onClick={this.setUserId} value={ele.userId} className="btn btn-primary btn-sm infoButton1">
                                                                <NavLink className="text-center" to="/user_profile">
                                                                    <button value={ele.userId} className="btn btn-primary btn-sm infoButton2"> &#9432; </button>
                                                                </NavLink>
                                                            </button><br />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        );
                                    })
                                )}

                            </div>
                        )
                        }
                    </>
                ) : false}
            </>
        )
    }
}
export default AppliedJobDetails;