import React, { Component } from 'react';
import './notificationedit.css';
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';
import swal from 'sweetalert';

class NotificationEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            notificationType: '',
            notification: '',
            dataStatus: "false",
        }
    }

    componentDidMount() {
        var id = sessionStorage.getItem("notificationId");
        let formData = new FormData();
        formData.append("id", id);
        var url = this.state.url + "getSingleNotificationData.php";
        Axios.post(url, formData).then((res) => {
            // console.log(res.data);
            this.setState({
                notification: res.data[0].notification,
                notificationType: res.data[0].notificationType,
                dataStatus: "true"
            });
        });
    }

    handle = (e) => {

        if (e.target.value.trim() === "") {
            e.target.value = "";
        }

        this.setState({ [e.target.name]: e.target.value });

        if (e.target.value !== "") {
            document.getElementById("error" + e.target.name).innerHTML = "";
        }

        if (e.target.name === "notificationType") {
            if (document.getElementById("notificationType").value !== "") {
                document.getElementById("errornotificationType").innerHTML = "";
            }
            else {
                document.getElementById("errornotificationType").innerHTML = "This field is required. Please select any.";
            }
        }

        if (e.target.name === "notification") {
            if (document.getElementById("notification").value !== "") {
                document.getElementById("errornotification").innerHTML = "";
            }
            else {
                document.getElementById("errornotification").innerHTML = "This field is required. Please enter notification.";
            }
        }
    }

    submit = (e) => {
        e.preventDefault();
        var check = 0;

        if (document.getElementById("notificationType").value === "") {
            check++;
            document.getElementById("errornotificationType").innerHTML = "This field is required. Please select any.";
        }
        else {
            document.getElementById("errornotificationType").innerHTML = "";
        }

        if (document.getElementById("notification").value === "") {
            check++;
            document.getElementById("errornotification").innerHTML = "This field is required. Please enter notification.";
        }
        else {
            document.getElementById("errornotification").innerHTML = "";
        }


        if (check === 0) {
            document.getElementsByClassName("fa-spin")[0].classList.add("fa-refresh");
            document.getElementById("notificationUpdateButton").disabled = true;
            var id = sessionStorage.getItem("notificationId");
            let formData = new FormData();
            formData.append("notificationType", this.state.notificationType);
            formData.append("notification", this.state.notification);
            formData.append("id", id);

            var url = this.state.url + "updateNotification.php";
            Axios.post(url, formData).then((res) => {
                // alert(res.data);
                document.getElementsByClassName("fa-spin")[0].classList.remove("fa-refresh");
                document.getElementById("notificationUpdateButton").disabled = false;
                swal({
                    title: "Updated!",
                    text: "Notification updated successfully!",
                    button: "Close",
                    icon: "success",
                    timer: 3000,
                    button: {
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                        }
                    }
                });
            });

        }

    }

    render() {
        return (
            <>
                {sessionStorage.getItem("aId") ? (
                    <>
                        <div>
                            <div className="page-header">
                                <h3 className="page-title">Notification</h3>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/view_notification">Notification</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Update Notification</li>
                                    </ol>
                                </nav>
                            </div>

                            {this.state.dataStatus === "false" ? (
                                <Spinner />
                            ) : (
                                <div className="row">
                                    <div className="col-md-12 col-12">
                                        <div className="admin__profile bg-white">
                                            <h6 className="pl-3 admin__profile_title">Update Notification</h6>


                                            <form className="mt-5 mb-5 notification_form" id="notification_form" onSubmit={this.submit}>
                                                <div className="row px-5">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label for="notificationType">Select Notification Type</label>

                                                            {(() => {
                                                                if (this.state.notificationType === "user") {
                                                                    return (
                                                                        <select onChange={this.handle} class="form-control form-control-lg" name="notificationType" id="notificationType">
                                                                            <option disabled value="">Select Any</option>
                                                                            <option selected value="user">User</option>
                                                                            <option value="company">Company</option>
                                                                        </select>
                                                                    );
                                                                }
                                                                else if (this.state.notificationType === "company") {
                                                                    return (
                                                                        <select onChange={this.handle} class="form-control form-control-lg" name="notificationType" id="notificationType">
                                                                            <option disabled value="">Select Any</option>
                                                                            <option value="user">User</option>
                                                                            <option selected value="company">Company</option>
                                                                        </select>
                                                                    );
                                                                }
                                                                else {
                                                                    return (
                                                                        <select onChange={this.handle} class="form-control form-control-lg" name="notificationType" id="notificationType">
                                                                            <option disabled selected value="">Select Any</option>
                                                                            <option value="user">User</option>
                                                                            <option value="company">Company</option>
                                                                        </select>
                                                                    );
                                                                }
                                                            })()}

                                                        </div>
                                                        <p className="error" id="errornotificationType"></p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label for="notification">Enter Notification</label>
                                                            <textarea class="form-control" onChange={this.handle} name="notification" id="notification" value={this.state.notification} rows="3"></textarea>
                                                        </div>
                                                        <p className="error" id="errornotification"></p>
                                                    </div>
                                                    <div className="col-md-12 mt-4">
                                                        <button type="submit" className="login_button mt-3 shadow" id="notificationUpdateButton">Update <i class="fa fa-spin"></i></button>
                                                        <NavLink to="/view_notification"><button type="reset" className="reset_button mt-3 shadow">Back</button></NavLink>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </>
                ) : false}
            </>
        )
    }
}
export default NotificationEdit