import React, { Component } from 'react';
import './admin.css';
import Axios from 'axios';
import AdminImg from "./admin.jpg";
import swal from 'sweetalert';

class AdminAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            name: '',
            phone: '',
            email: '',
            password: ''
        };
    }

    handle = (e) => {

        if (e.target.value.trim() === "") {
            e.target.value = "";
        }

        var normalTextRegEx = /^[a-zA-Z0-9]+([ a-zA-Z0-9])+/;
        var mobileNumberRegEx = /^[6789][0-9]{9}$/;
        var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        this.setState({ [e.target.name]: e.target.value });

        if (e.target.value !== "") {
            document.getElementById("error" + e.target.name).innerHTML = "";
        }

        if (e.target.name === "name") {
            if (document.getElementById("name").value !== "") {
                if (document.getElementById("name").value.match(normalTextRegEx)) {
                    document.getElementById("errorname").innerHTML = "";
                }
                else {
                    document.getElementById("errorname").innerHTML = "Please enter a valid name.";
                }
            }
            else {
                document.getElementById("errorname").innerHTML = "This field is required. Please enter name.";
            }
        }

        if (e.target.name === "phone") {
            if (document.getElementById("phone").value !== "") {
                if (document.getElementById("phone").value.match(mobileNumberRegEx)) {
                    document.getElementById("errorphone").innerHTML = "";
                }
                else {
                    document.getElementById("errorphone").innerHTML = "Please enter a valid 10 digit number.";
                }
            }
            else {
                document.getElementById("errorphone").innerHTML = "This field is required. Please enter number.";
            }
        }

        if (e.target.name === "email") {
            if (document.getElementById("email").value !== "") {
                if (document.getElementById("email").value.match(emailRegEx)) {
                    document.getElementById("erroremail").innerHTML = "";
                }
                else {
                    document.getElementById("erroremail").innerHTML = "Please enter a valid email.";
                }
            }
            else {
                document.getElementById("erroremail").innerHTML = "This field is required. Please enter email.";
            }
        }

        if (e.target.name === "password") {
            if (document.getElementById("password").value !== "") {
                if (document.getElementById("password").value.length > 5) {
                    document.getElementById("errorpassword").innerHTML = "";
                }
                else {
                    document.getElementById("errorpassword").innerHTML = "Please enter at least 5 digit password.";
                }
            }
            else {
                document.getElementById("errorpassword").innerHTML = "This field is required. Please enter password.";
            }
        }
    }

    submit = (e) => {
        e.preventDefault();
        var check = 0;

        if (document.getElementById("name").value === "") {
            check++;
            document.getElementById("errorname").innerHTML = "This field is required. Please enter name.";
        }
        else {
            document.getElementById("errorname").innerHTML = "";
        }

        if (document.getElementById("email").value === "") {
            check++;
            document.getElementById("erroremail").innerHTML = "This field is required. Please enter email.";
        }
        else {
            document.getElementById("erroremail").innerHTML = "";
        }

        if (document.getElementById("phone").value === "") {
            check++;
            document.getElementById("errorphone").innerHTML = "This field is required. Please enter mobile number.";
        }
        else {
            document.getElementById("errorphone").innerHTML = "";
        }

        if (document.getElementById("password").value === "") {
            check++;
            document.getElementById("errorpassword").innerHTML = "This field is required. Please enter password.";
        }
        else {
            document.getElementById("errorpassword").innerHTML = "";
        }

        if (check === 0) {
            document.getElementsByClassName("fa-spin")[0].classList.add("fa-refresh");
            document.getElementById("addAdminButton").disabled = true;
            let formData = new FormData();
            formData.append("name", this.state.name);
            formData.append("phone", this.state.phone);
            formData.append("email", this.state.email);
            formData.append("password", this.state.password);

            var url = this.state.url + "addAdmin.php";
            Axios.post(url, formData).then((res) => {
                // alert(res.data);
                document.getElementsByClassName("fa-spin")[0].classList.remove("fa-refresh");
                document.getElementById("addAdminButton").disabled = false;
                document.getElementById("adminAddForm").reset();
                swal({
                    title: "Added!",
                    text: "Admin added successfully!",
                    button: "Close",
                    icon: "success",
                    timer: 3000,
                    button: {
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                        }
                    }
                });
            });

        }

    }

    render() {
        return (
            <>
                {sessionStorage.getItem("aId") ? (
                    <>

                        <div id="messageAddAlert">
                            <p id="message"></p>
                        </div>
                        <div>
                            <div className="page-header">
                                <h3 className="page-title">Admin</h3>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Admin</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Admin</li>
                                    </ol>
                                </nav>
                            </div>

                            <div className="row bg-white">
                                <div className="col-md-7 mt-4 col-12">
                                    <div className="admin__profile">
                                        <h6 className="pl-3 admin__profile_title">Admin add</h6>
                                        <form className="mt-5 mb-5 admin_form" id="adminAddForm" onSubmit={this.submit}>
                                            <div className="row px-4">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label for="name"><i className="zmdi mdi mdi-account material-icons-name"></i></label>
                                                        <input type="text" onChange={this.handle} name="name" id="name" placeholder="Name" />
                                                    </div>
                                                    <p className="error" id="errorname"></p>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label for="email"><i className="zmdi mdi mdi-email-variant material-icons-name"></i></label>
                                                        <input type="email" onChange={this.handle} name="email" id="email" placeholder="Email Id" />
                                                    </div>
                                                    <p className="error" id="erroremail"></p>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label for="phone"><i className="zmdi mdi mdi-phone-plus material-icons-name"></i></label>
                                                        <input type="number" onChange={this.handle} name="phone" id="phone" placeholder="Phone number" />
                                                    </div>
                                                    <p className="error" id="errorphone"></p>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label for="password"><i className="zmdi mdi mdi mdi-lock material-icons-name"></i></label>
                                                        <input type="password" onChange={this.handle} name="password" id="password" placeholder="Password" />
                                                    </div>
                                                    <p className="error" id="errorpassword"></p>
                                                </div>
                                                <div className="col-md-12 mt-4">
                                                    <button type="submit" className="login_button mt-3 shadow" id="addAdminButton">Add <i class="fa fa-spin"></i></button>
                                                    <button type="reset" className="reset_button mt-3 shadow">Reset</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className="col-md-5">
                                    <img src={AdminImg} className="img-fluid" alt="admin" />
                                </div>


                            </div>

                        </div>
                    </>
                ) : false}
            </>
        )
    }
}
export default AdminAdd