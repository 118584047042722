import React, { Component } from 'react';
import './interview.css';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';
import { NavLink } from 'react-router-dom';
import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';

class ViewScheduledInterView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            data: [],
            dataStatus: "false",
            showHide: false,
        };
    }

    setUserId = (e) => {
        e.preventDefault();
        // alert(e.target.value);
        sessionStorage.setItem("userId", e.target.value);
    }

    componentDidMount() {
        var id = sessionStorage.getItem("aId");
        let formData = new FormData();
        formData.append("id", id);
        var url = this.state.url + "viewScheduledInterview.php";
        Axios.post(url, formData)
            .then((res) => {
                this.setState({
                    data: res.data,
                    dataStatus: "true"
                });
                console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    hire = (e) => {
        // alert (e.target.value);
        this.setState({
            dataStatus: "false"
        });
        var aId = sessionStorage.getItem("aId");
        var id = e.target.value;
        let formData = new FormData();
        formData.append("id", id);
        formData.append("aId", aId);
        var url = this.state.url + "updateWishlistedProfile.php";
        Axios.post(url, formData)
            .then((res) => {
                var id = sessionStorage.getItem("aId");
                let formData = new FormData();
                formData.append("id", id);
                var url = this.state.url + "viewScheduledInterview.php";
                Axios.post(url, formData)
                    .then((res) => {
                        this.setState({
                            data: res.data,
                            dataStatus: "true"
                        });
                        swal({
                            title: "Hired!",
                            text: "User hired successfully!",
                            button: "Close",
                            icon: "success",
                            timer: 3000,
                            button: {
                                confirm: {
                                    text: "OK",
                                    value: true,
                                    visible: true,
                                    className: "",
                                    closeModal: true
                                }
                            }
                        });
                        // console.log(res.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    removeFromSort = (e) => {
        var userId = e.target.value;
        swal({
            title: "Are you sure?",
            text: "Once removed, you will have to schedule meeting again!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    this.setState({
                        dataStatus: "false"
                    });
                    var aId = sessionStorage.getItem("aId");
                    let formData = new FormData();
                    formData.append("userId", userId);
                    formData.append("aId", aId);
                    var url = this.state.url + "removeInterview.php";
                    Axios.post(url, formData)
                        .then((res) => {
                            var id = sessionStorage.getItem("aId");
                            let formData = new FormData();
                            formData.append("id", id);
                            var url = this.state.url + "viewScheduledInterview.php";
                            Axios.post(url, formData)
                                .then((res) => {
                                    this.setState({
                                        data: res.data,
                                        dataStatus: "true"
                                    });
                                    swal({
                                        title: "Removed!",
                                        text: "User removed successfully!",
                                        button: "Close",
                                        icon: "success",
                                        timer: 3000,
                                        button: {
                                            confirm: {
                                                text: "OK",
                                                value: true,
                                                visible: true,
                                                className: "",
                                                closeModal: true
                                            }
                                        }
                                    });
                                    // console.log(res.data);
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            });

    }

    updateStatus = (e) => {
        e.preventDefault();
        var id = e.target.name;
        var status = document.getElementById("interviewStatus").value;
        // alert(e.target.name);
        // alert(status);

        let formData = new FormData();
        formData.append("id", id);
        formData.append("status", status);
        var url = this.state.url + "updateApplicationStatus.php";
        Axios.post(url, formData)
            .then((res) => {
                swal({
                    title: "Updated!",
                    text: "Status updated!",
                    button: "Close",
                    icon: "success",
                    timer: 3000,
                    button: {
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                        }
                    }
                });
                // console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    viewInterviewInfo = (e) => {
        e.preventDefault();
        // alert(e.target.id);

        sessionStorage.setItem("viewInterviewInfo", "true");
        sessionStorage.setItem("userId", e.target.id);
        sessionStorage.setItem("userName", e.target.value);
    }

    handleModalShowHide = (e) => {
        e.preventDefault();
        this.setState({
            showHide: !this.state.showHide
        });
    }

    resetFilter = (e) => {
        this.setState({
            dataStatus: 'false'
        })
        var id = sessionStorage.getItem("aId");
        let formData = new FormData();
        formData.append("id", id);
        var url = this.state.url + "viewScheduledInterview.php";
        Axios.post(url, formData)
            .then((res) => {
                this.setState({
                    data: res.data,
                    dataStatus: "true"
                });
                console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    filterSearchData = (e) => {
        e.preventDefault();
        var nameSearchScheduledInterview = document.getElementById("nameSearchScheduledInterview").value;
        var userIdSearchScheduledInterview = document.getElementById("userIdSearchScheduledInterview").value;
        var scheduleStatusSearchScheduledInterview = document.getElementById("scheduleStatusSearchScheduledInterview").value;

        if (nameSearchScheduledInterview !== "" || userIdSearchScheduledInterview !== "" || scheduleStatusSearchScheduledInterview !== "") {
            var id = sessionStorage.getItem("aId");
            this.setState({
                dataStatus: "false",
                showHide: !this.state.showHide
            });

            var SQL = "";

            SQL = "SELECT wishlist.id, wishlist.userId, wishlist.userName, wishlist.adminId, wishlist.adminName, wishlist.sortlistDate, wishlist.hiringStatus, wishlist.hiringDate, wishlist.interviewStatus, user.uniqueId FROM wishlist,user WHERE wishlist.adminId='"+id+"'  AND wishlist.interviewStatus!='Pending'  AND wishlist.hiringStatus='Pending' AND user.id=wishlist.userId";
            if (nameSearchScheduledInterview !== "") {
                SQL += " AND wishlist.userName LIKE '%" + nameSearchScheduledInterview + "%'";
            }
            if (userIdSearchScheduledInterview !== "") {
                SQL += " AND user.uniqueId LIKE '" + userIdSearchScheduledInterview + "'";
            }
            if (scheduleStatusSearchScheduledInterview !== "") {
                if (scheduleStatusSearchScheduledInterview === "Processing") {
                    SQL += " AND wishlist.interviewStatus LIKE '%Processing%'";
                }
                if (scheduleStatusSearchScheduledInterview === "Accepted") {
                    SQL += " AND wishlist.interviewStatus LIKE '%Accepted%'";
                }
                if (scheduleStatusSearchScheduledInterview === "Rejected") {
                    SQL += " AND wishlist.interviewStatus LIKE '%Rejected%'";
                }
                if (scheduleStatusSearchScheduledInterview === "Hold") {
                    SQL += " AND wishlist.interviewStatus LIKE '%Hold%'";
                }
            }

            SQL += ";";

            let formData = new FormData();
            formData.append("sql_Query", SQL);

            console.log(SQL);

            var searchURL = this.state.url + "filterData/filterData.php";

            Axios.post(searchURL, formData).then((res) => {
                // alert(res.data);
                this.setState({
                    data: res.data,
                    dataStatus: "true"
                });
            })

        }
        else {
            swal({
                title: "Error!",
                text: "Please fill at least any one field to filter!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
    }

    render() {
        return (
            <div>
                {sessionStorage.getItem("aId") ? (
                    <>
                        <div className="page-header">
                            <h3 className="page-title">Scheduled Interview</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink exact to="/sortlisted">Schedule Interview</NavLink></li>
                                </ol>
                            </nav>
                        </div>
                        <div className="row my-4">
                            <div className="col-md-8"></div>
                            <div className="col-md-4 text-right sticky">
                                <button className="btn btn-sm btn-info" onClick={this.handleModalShowHide}>Filter <i className="fa fa-filter"></i></button>
                                &nbsp;
                                <button className="btn btn-sm btn-danger" onClick={this.resetFilter}>Rest Filter <i className="fa fa-close"></i></button>
                            </div>
                        </div>
                        <Modal show={this.state.showHide}>
                            <Modal.Header>
                                <Modal.Title>Filter Interview</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <div className="row">
                                        <div className=" mt-2 col-md-6">
                                            <label className="form-label" for="nameSearchScheduledInterview">Enter User Name</label>
                                            <input type="text" className="form-control" name="nameSearchScheduledInterview" id="nameSearchScheduledInterview" placeholder="Enter User Name" ></input>
                                        </div>
                                        <div className="mt-2 col-md-6">
                                            <label className="form-label" for="userIdSearchScheduledInterview">Enter User Id</label>
                                            <input type="text" className="form-control" name="userIdSearchScheduledInterview" id="userIdSearchScheduledInterview" placeholder="Enter User Id" ></input>
                                        </div>
                                        <div className="mt-2 col-md-6">
                                            <label className="form-label" for="scheduleStatusSearchScheduledInterview">Interview Schedule Status</label>
                                            <select id="scheduleStatusSearchScheduledInterview" className="form-control" name="scheduleStatusSearchScheduledInterview">
                                                <option value="" selected>Select Any</option>
                                                <option value="Processing">Processing</option>
                                                <option value="Accepted">Accepted</option>
                                                <option value="Rejected">Rejected</option>
                                                <option value="Hold">Hold</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="danger" id="modalSkip" onClick={this.handleModalShowHide}>
                                    Cancel
                                </Button>
                                <Button type="submit" onClick={this.filterSearchData} id="modalValidate" variant="success">
                                    Filter
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {this.state.dataStatus === "false" ? (
                            <Spinner />
                        ) : (

                            <div className="row">
                                <div className="col-md-12 grid-margin stretch-card bg-white shadow-sm" style={{ marginTop: "10px" }}>
                                    <div className="card shadow-none">
                                        <div className="card-body">
                                            <h4 className="card-title">View Scheduled Interview Details</h4>
                                            <div className="table-responsive">
                                                <table className="table" id="myTable">
                                                    <thead>
                                                        <tr>
                                                            <th>SN.</th>
                                                            <th>User Name</th>
                                                            <th>User ID</th>
                                                            <th>Status</th>
                                                            <th>Explore Profile</th>
                                                            <th>View Information</th>
                                                            <th>Hire</th>
                                                            <th>Remove Interview</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.data.length === 0 ? (
                                                            <tr>
                                                                <td colSpan="8" className="text-center">
                                                                    No Records Found
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            this.state.data.map((ele, i) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{i + 1}</td>
                                                                        <td>{ele.uniqueId}</td>
                                                                        <td>{ele.userName}</td>
                                                                        <td>
                                                                            <select className="form-control text-dark" id="interviewStatus" name={ele.id} onChange={this.updateStatus}>
                                                                                {(() => {
                                                                                    if (ele.interviewStatus === "Processing") {
                                                                                        return (
                                                                                            <>
                                                                                                <option selected value="Processing">Processing</option>
                                                                                                <option value="Accepted">Accepted</option>
                                                                                                <option value="Rejected">Rejected</option>
                                                                                                <option value="Hold">Hold</option>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                    else if (ele.interviewStatus === "Accepted") {
                                                                                        return (
                                                                                            <>
                                                                                                <option value="Processing">Processing</option>
                                                                                                <option selected value="Accepted">Accepted</option>
                                                                                                <option value="Rejected">Rejected</option>
                                                                                                <option value="Hold">Hold</option>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                    else if (ele.interviewStatus === "Rejected") {
                                                                                        return (
                                                                                            <>
                                                                                                <option value="Processing">Processing</option>
                                                                                                <option value="Accepted">Accepted</option>
                                                                                                <option selected value="Rejected">Rejected</option>
                                                                                                <option value="Hold">Hold</option>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                    else if (ele.interviewStatus === "Hold") {
                                                                                        return (
                                                                                            <>
                                                                                                <option value="Processing">Processing</option>
                                                                                                <option value="Accepted">Accepted</option>
                                                                                                <option value="Rejected">Rejected</option>
                                                                                                <option selected value="Hold">Hold</option>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                })()}

                                                                            </select>
                                                                        </td>
                                                                        <td>
                                                                            <button onClick={this.setUserId} value={ele.userId} className="btn btn-primary btn-sm infoButton1">
                                                                                <NavLink className="text-center" to="/user_profile">
                                                                                    <button value={ele.userId} className="btn btn-primary btn-sm infoButton2"> &#9432; </button>
                                                                                </NavLink>
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <button onClick={this.viewInterviewInfo} id={ele.userId} value={ele.userName} className="btn btn-primary btn-sm infoButton1">
                                                                                <NavLink className="text-center" to="/view-single-scheduled-interview">
                                                                                    <button id={ele.userId} value={ele.userName} className="btn btn-primary btn-sm infoButton2">View</button>
                                                                                </NavLink>
                                                                            </button>


                                                                        </td>
                                                                        <td><button className="btn btn-success btn-sm" value={ele.id} onClick={this.hire}>Hire</button></td>
                                                                        <td><button className="btn btn-danger btn-sm" value={ele.userId} onClick={this.removeFromSort}>Remove</button></td>
                                                                    </tr>
                                                                );
                                                            })
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                ) : false}
            </div>
        )
    }
}
export default ViewScheduledInterView;