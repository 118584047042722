import React, { Component } from 'react';
import './company.css';
import { MDBDataTable } from "mdbreact";
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import Spinner from '../../app/shared/Spinner';
import Pagination from "@material-ui/lab/Pagination";
import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';

class CompanyTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "https://www.digitizedresume.com/api/admin/",
      data: [],
      dataStatus: "false",
      page: 1,
      totalCol: 0,
      searchBy: '',
      showHide: false,
    };
  }

  handlePagination = (e, val) => {
    if (this.state.page != val) {
      if (val > this.state.page) {
        this.setState({
          page: val,
          totalCol: val * 5 - 5,
        });
      } else {
        this.setState({
          page: val,
          totalCol: val * 5 - 5,
        });
      }
    }
  }

  componentDidMount() {
    var url = this.state.url + "getCompanyData.php";
    Axios.get(url)
      .then((res) => {
        // alert(res.data);
        this.setState({
          data: res.data,
          dataStatus: "true",
        });
        // console.log(res.data);
      })
      .catch(function (error) {
        alert(error);
        console.log(error);
      });
  }

  singleView = (e) => {
    e.preventDefault();
    // alert(e.target.value);
    sessionStorage.setItem("companyId", e.target.value);
  }

  delete = (e) => {
    e.preventDefault();
    var id = e.target.value;

    swal({
      title: "Are you sure?",
      text: "Once removed, you won't be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          this.setState({ dataStatus: "false" })
          let formData = new FormData();
          formData.append("id", id);
          var url = this.state.url + "deleteCompany.php";

          Axios.post(url, formData)
            .then((res) => {
              this.setState({
                dataStatus: "false"
              });
              swal({
                title: "Deleted!",
                text: "Job deleted successfully!",
                button: "Close",
                icon: "success",
                timer: 3000,
                button: {
                  confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                  }
                }
              });
              var url = this.state.url + "getCompanyData.php";
              Axios.get(url)
                .then((res) => {
                  this.setState({
                    dataStatus: "true",
                    data: res.data
                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
            .catch((err) => {
              // console.log(err);
              this.setState({
                res: err,
              })
            });
        }
      });
  }

  company = () => {
    if (document.getElementById("company").style.display === "none") {
      document.getElementById("company").style.display = "block";
      document.getElementById("searchCompanyName").style.width = "100%";
      document.getElementById("hired").style.display = "none";

    }
  }

  hired = () => {
    if (document.getElementById("hired").style.display === "none") {
      document.getElementById("hired").style.display = "block";
      document.getElementById("searchHired").style.width = "100%";
      document.getElementById("company").style.display = "none";

    }
  }

  handleModalShowHide = (e) => {
    e.preventDefault();
    this.setState({
      showHide: !this.state.showHide
    });
  }

  resetFilter = (e) => {
    this.setState({
      dataStatus: 'false'
    })
    var url = this.state.url + "getCompanyData.php";
    Axios.get(url)
      .then((res) => {
        // alert(res.data);
        this.setState({
          data: res.data,
          dataStatus: "true",
        });
        // console.log(res.data);
      })
      .catch(function (error) {
        alert(error);
        console.log(error);
      });
  }

  filterSearchData = (e) => {
    e.preventDefault();
    var nameSearchCompany = document.getElementById("nameSearchCompany").value;
    var phoneSearchCompany = document.getElementById("phoneSearchCompany").value;
    var companyIdSearchCompany = document.getElementById("companyIdSearchCompany").value;

    if (nameSearchCompany !== "" || phoneSearchCompany !== "" || companyIdSearchCompany !== "") {


      this.setState({
        dataStatus: "false",
        showHide: !this.state.showHide
      });

      var SQL = "";
      var totalQuery = 0;

      SQL = "SELECT * FROM company WHERE ";
      if (nameSearchCompany !== "") {
        if (totalQuery > 0) {
          SQL += " AND name LIKE '%" + nameSearchCompany + "%'";
          totalQuery += 1;
        }
        else {
          SQL += "name LIKE '%" + nameSearchCompany + "%'";
          totalQuery += 1;
        }
      }
      if (phoneSearchCompany !== "") {
        if (totalQuery > 0) {
          SQL += " AND phone = " + phoneSearchCompany;
          totalQuery += 1;
        }
        else {
          SQL += "phone LIKE '" + phoneSearchCompany + "'";
          totalQuery += 1;
        }
      }
      if (companyIdSearchCompany !== "") {
        if (totalQuery > 0) {
          SQL += " AND uniqueId LIKE '%" + companyIdSearchCompany + "%'";
          totalQuery += 1;
        }
        else {
          SQL += "uniqueId LIKE '%" + companyIdSearchCompany + "%'";
          totalQuery += 1;
        }
      }

      SQL += ";";

      let formData = new FormData();
      formData.append("sql_Query", SQL);

      console.log(SQL);

      var searchURL = this.state.url + "filterData/filterData.php";

      Axios.post(searchURL, formData).then((res) => {
        // alert(res.data);
        this.setState({
          data: res.data,
          dataStatus: "true"
        });
      })

    }
    else {
      swal({
        title: "Error!",
        text: "Please fill at least any one field to filter!",
        button: "Close",
        icon: "error",
        timer: 3000,
        button: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      });
    }
  }

  render() {
    return (
      <div>
        {sessionStorage.getItem("aId") ? (
          <>
            <div className="page-header">
              <h3 className="page-title">Company</h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="!#" onClick={(event) => event.preventDefault()}>
                      Company
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    All Companies
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row my-4">
              <div className="col-md-8"></div>
              <div className="col-md-4 text-right sticky">
                <button className="btn btn-sm btn-info" onClick={this.handleModalShowHide}>Filter <i className="fa fa-filter"></i></button>
                &nbsp;
                <button className="btn btn-sm btn-danger" onClick={this.resetFilter}>Rest Filter <i className="fa fa-close"></i></button>
              </div>
            </div>
            <Modal show={this.state.showHide}>
              <Modal.Header>
                <Modal.Title>Filter Company</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <div className="row">
                    <div className=" mt-2 col-md-12">
                      <label className="form-label" for="nameSearchCompany">Enter Company Name</label>
                      <input type="text" className="form-control" name="nameSearchCompany" id="nameSearchCompany" placeholder="Enter Company Name" ></input>
                    </div>
                    <div className=" mt-2 col-md-6">
                      <label className="form-label" for="phoneSearchCompany">Enter Phone</label>
                      <input type="number" className="form-control" name="phoneSearchCompany" id="phoneSearchCompany" placeholder="Enter Phone" ></input>
                    </div>
                    <div className="mt-2 col-md-6">
                      <label className="form-label" for="companyIdSearchCompany">Enter Company Id</label>
                      <input type="text" className="form-control" name="companyIdSearchCompany" id="companyIdSearchCompany" placeholder="Enter Company Id" ></input>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" id="modalSkip" onClick={this.handleModalShowHide}>
                  Cancel
                </Button>
                <Button type="submit" onClick={this.filterSearchData} id="modalValidate" variant="success">
                  Filter
                </Button>
              </Modal.Footer>
            </Modal>

            {this.state.dataStatus === "false" ? (
              <Spinner />
            ) : (
              <div className="row">
                <div className="col-md-12 grid-margin stretch-card" style={{ marginTop: "10px" }} >
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Company Details</h4>
                      <div className="table-responsive">
                        <table className="table" id="companyTable">
                          <thead>
                            <tr>
                              <th>SN.</th>
                              <th>Company Id</th>
                              <th>Company Name</th>
                              <th>Email</th>
                              <th>Mobile</th>
                              <th>No. of Hired Employee</th>
                              <th>View Details</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.length === 0 ? (
                              <tr>
                                <td colSpan="8" className="text-center">
                                  No Records Found
                                </td>
                              </tr>
                            ) : (
                              this.state.data.map((ele, i) => {
                                if (
                                  i < this.state.page * 5 &&
                                  this.state.totalCol <= i
                                ) {
                                  return (
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{ele.uniqueId}</td>
                                      <td>{ele.name}</td>
                                      <td>{ele.email}</td>
                                      <td>{ele.phone}</td>
                                      <td className="text-center">
                                        <div className="reqview">
                                          <NavLink to="/No-of-hired">
                                            <button
                                              className=""
                                              onClick={this.singleView}
                                              value={ele.id}
                                            >
                                              <NavLink to="/No-of-hired">
                                                <button
                                                  value={ele.id}
                                                  id="buttonPadding"
                                                >{ele.noOfHiredEmployee}</button>
                                              </NavLink>
                                            </button>
                                          </NavLink>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="reqview">
                                          <NavLink to="/company-details">
                                            <button
                                              className=""
                                              onClick={this.singleView}
                                              value={ele.id}
                                            >
                                              <NavLink to="/company-details">
                                                <button
                                                  className="mdi mdi-eye"
                                                  value={ele.id}
                                                  id="buttonPadding"
                                                ></button>
                                              </NavLink>
                                            </button>
                                          </NavLink>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="admindelete">
                                          <button
                                            className=""
                                            onClick={this.delete}
                                            className="mdi mdi-window-close"
                                            value={ele.id}
                                            id="buttonPadding"
                                          >
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              })
                            )}
                          </tbody>
                        </table>
                        <Pagination
                          count={parseInt(this.state.data.length / 5) + 1}
                          page={this.state.page}
                          onChange={this.handlePagination}
                          color="primary"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          false
        )}
      </div>
    );
  }
}
export default CompanyTable;