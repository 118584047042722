import React, { Component } from 'react';
import './sortlist.css';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';
import { NavLink } from 'react-router-dom';
import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';

class ShortList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      url: "https://www.digitizedresume.com/api/admin/",
      data: [],
      dataStatus: "false",
      showHide: false,
    };
  }

  setUserId = (e) => {
    e.preventDefault();
    // alert(e.target.value);
    sessionStorage.setItem("userId", e.target.value);
  }

  componentDidMount() {
    var id = sessionStorage.getItem("aId");
    let formData = new FormData();
    formData.append("id", id);
    var url = this.state.url + "singleWishlistData.php";
    Axios.post(url, formData)
      .then((res) => {
        this.setState({
          data: res.data,
          dataStatus: "true"
        });
        // console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  hire = (e) => {
    // alert (e.target.value);
    this.setState({
      dataStatus: "false"
    });
    var aId = sessionStorage.getItem("aId");
    var id = e.target.value;
    let formData = new FormData();
    formData.append("id", id);
    formData.append("aId", aId);
    var url = this.state.url + "updateWishlistedProfile.php";
    Axios.post(url, formData)
      .then((res) => {
        var id = sessionStorage.getItem("aId");
        let formData = new FormData();
        formData.append("id", id);
        var url = this.state.url + "singleWishlistData.php";
        Axios.post(url, formData)
          .then((res) => {
            this.setState({
              data: res.data,
              dataStatus: "true"
            });
            swal({
              title: "Hired!",
              text: "User hired successfully!",
              button: "Close",
              icon: "success",
              timer: 3000,
              button: {
                confirm: {
                  text: "OK",
                  value: true,
                  visible: true,
                  className: "",
                  closeModal: true
                }
              }
            });
            // console.log(res.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  removeFromSort = (e) => {
    var aId = sessionStorage.getItem("aId");
    var id = e.target.value;
    var userId = e.target.id;
    swal({
      title: "Are you sure?",
      text: "Once removed, you will have to shortlist again!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          this.setState({
            dataStatus: "false"
          });
          let formData = new FormData();
          formData.append("id", id);
          formData.append("userId", userId);
          formData.append("aId", aId);
          var url = this.state.url + "removeWishlistedProfile.php";
          Axios.post(url, formData)
            .then((res) => {
              var id = sessionStorage.getItem("aId");
              let formData = new FormData();
              formData.append("id", id);
              var url = this.state.url + "singleWishlistData.php";
              Axios.post(url, formData)
                .then((res) => {
                  this.setState({
                    data: res.data,
                    dataStatus: "true"
                  });
                  swal({
                    title: "Removed!",
                    text: "User removed successfully!",
                    button: "Close",
                    icon: "success",
                    timer: 3000,
                    button: {
                      confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                      }
                    }
                  });
                  // console.log(res.data);
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
  }

  scheduleInterview = (e) => {
    e.preventDefault();
    // alert(e.target.id);

    sessionStorage.setItem("interviewStatus", "true");
    sessionStorage.setItem("userId", e.target.id);
    sessionStorage.setItem("userName", e.target.value);
  }

  handleModalShowHide = (e) => {
    e.preventDefault();
    this.setState({
      showHide: !this.state.showHide
    });
  }

  resetFilter = (e) => {
    this.setState({
      dataStatus: 'false'
    })
    var id = sessionStorage.getItem("aId");
    let formData = new FormData();
    formData.append("id", id);
    var url = this.state.url + "singleWishlistData.php";
    Axios.post(url, formData)
      .then((res) => {
        this.setState({
          data: res.data,
          dataStatus: "true"
        });
        // console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  filterSearchData = (e) => {
    e.preventDefault();
    var nameSearchShortlist = document.getElementById("nameSearchShortlist").value;
    var userIdSearchShortlist = document.getElementById("userIdSearchShortlist").value;
    var scheduleStatusSearchShortlist = document.getElementById("scheduleStatusSearchShortlist").value;

    if (nameSearchShortlist !== "" || userIdSearchShortlist !== "" || scheduleStatusSearchShortlist !== "") {
      var id = sessionStorage.getItem("aId");
      this.setState({
        dataStatus: "false",
        showHide: !this.state.showHide
      });

      var SQL = "";

      SQL = "SELECT wishlist.id, wishlist.userId, wishlist.userName, wishlist.adminId, wishlist.adminName, wishlist.sortlistDate, wishlist.hiringStatus, wishlist.hiringDate, wishlist.interviewStatus, user.uniqueId FROM wishlist,user WHERE wishlist.adminId='" + id + "' AND wishlist.hiringStatus='Pending' AND user.id=wishlist.userId";
      if (nameSearchShortlist !== "") {
        SQL += " AND wishlist.userName LIKE '%" + nameSearchShortlist + "%'";
      }
      if (userIdSearchShortlist !== "") {
        SQL += " AND user.uniqueId LIKE '" + userIdSearchShortlist + "'";
      }
      if (scheduleStatusSearchShortlist !== "") {
        if (scheduleStatusSearchShortlist === "Scheduled") {
          SQL += " AND wishlist.interviewStatus NOT LIKE '%Pending%'";
        }
        else {
          SQL += " AND wishlist.interviewStatus LIKE '%Pending%'";
        }
      }

      SQL += ";";

      let formData = new FormData();
      formData.append("sql_Query", SQL);

      console.log(SQL);

      var searchURL = this.state.url + "filterData/filterData.php";

      Axios.post(searchURL, formData).then((res) => {
        // alert(res.data);
        this.setState({
          data: res.data,
          dataStatus: "true"
        });
      })

    }
    else {
      swal({
        title: "Error!",
        text: "Please fill at least any one field to filter!",
        button: "Close",
        icon: "error",
        timer: 3000,
        button: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      });
    }
  }


  render() {
    return (
      <div>
        {sessionStorage.getItem("aId") ? (
          <>
            <div className="page-header">
              <h3 className="page-title">Shortlisted</h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Shortlisted</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Profile</li>
                </ol>
              </nav>
            </div>
            <div className="row my-4">
              <div className="col-md-8"></div>
              <div className="col-md-4 text-right sticky">
                <button className="btn btn-sm btn-info" onClick={this.handleModalShowHide}>Filter <i className="fa fa-filter"></i></button>
                &nbsp;
                <button className="btn btn-sm btn-danger" onClick={this.resetFilter}>Rest Filter <i className="fa fa-close"></i></button>
              </div>
            </div>
            <Modal show={this.state.showHide}>
              <Modal.Header>
                <Modal.Title>Filter Shortlisted Profile</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <div className="row">
                    <div className=" mt-2 col-md-6">
                      <label className="form-label" for="nameSearchShortlist">Enter User Name</label>
                      <input type="text" className="form-control" name="nameSearchShortlist" id="nameSearchShortlist" placeholder="Enter User Name" ></input>
                    </div>
                    <div className="mt-2 col-md-6">
                      <label className="form-label" for="userIdSearchShortlist">Enter User Id</label>
                      <input type="text" className="form-control" name="userIdSearchShortlist" id="userIdSearchShortlist" placeholder="Enter User Id" ></input>
                    </div>
                    <div className="mt-2 col-md-6">
                      <label className="form-label" for="scheduleStatusSearchShortlist">Interview Schedule Status</label>
                      <select id="scheduleStatusSearchShortlist" className="form-control" name="scheduleStatusSearchShortlist">
                        <option value="" selected>Select Any</option>
                        <option value="Scheduled">Scheduled</option>
                        <option value="Not Scheduled">Not Scheduled</option>
                      </select>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" id="modalSkip" onClick={this.handleModalShowHide}>
                  Cancel
                </Button>
                <Button type="submit" onClick={this.filterSearchData} id="modalValidate" variant="success">
                  Filter
                </Button>
              </Modal.Footer>
            </Modal>

            {this.state.dataStatus === "false" ? (
              <Spinner />
            ) : (
              <div className="row">
                <div className="col-md-12 grid-margin stretch-card bg-white shadow-sm" style={{ marginTop: "10px" }}>
                  <div className="card shadow-none">
                    <div className="card-body">
                      <h4 className="card-title">Shortlisted User Details</h4>
                      <div className="table-responsive">
                        <table className="table" id="myTable">
                          <thead>
                            <tr>
                              <th>SN.</th>
                              <th>User ID</th>
                              <th>User Name</th>
                              <th>Shortlisted Date</th>
                              <th>Explore Profile</th>
                              <th>Schedule Interview</th>
                              <th>Hire</th>
                              <th>Remove</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.length === 0 ? (
                              <tr>
                                <td colSpan="8" className="text-center">
                                  No Records Found
                                </td>
                              </tr>
                            ) : (
                              this.state.data.map((ele, i) => {
                                return (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{ele.uniqueId}</td>
                                    <td>{ele.userName}</td>
                                    <td>{ele.sortlistDate}</td>
                                    <td>
                                      <button onClick={this.setUserId} value={ele.userId} className="btn btn-primary btn-sm infoButton1">
                                        <NavLink className="text-center" to="/user_profile">
                                          <button value={ele.userId} className="btn btn-primary btn-sm infoButton2"> &#9432; </button>
                                        </NavLink>
                                      </button>
                                    </td>
                                    <td>
                                      {ele.interviewStatus === "Pending" ? (
                                        <button onClick={this.scheduleInterview} id={ele.userId} value={ele.userName} className="btn btn-primary btn-sm infoButton1">
                                          <NavLink className="text-center" to="/schedule-interview">
                                            <button id={ele.userId} value={ele.userName} className="btn btn-primary btn-sm infoButton2">Schedule</button>
                                          </NavLink>
                                        </button>
                                      ) : (
                                        <button className="btn btn-primary btn-sm" disabled>
                                          Scheduled
                                        </button>
                                      )
                                      }
                                    </td>
                                    <td><button className="btn btn-success btn-sm" value={ele.id} onClick={this.hire}>Hire</button></td>
                                    <td><button className="btn btn-danger btn-sm" value={ele.id} id={ele.userId} onClick={this.removeFromSort}>Remove</button></td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : false}
      </div>
    )
  }
}
export default ShortList