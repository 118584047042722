import React, { Component } from "react";
import "./usertable.css";
import { MDBDataTable } from "mdbreact";
import { NavLink } from "react-router-dom";
import Axios from "axios";
import Spinner from "../../app/shared/Spinner";
import Pagination from "@material-ui/lab/Pagination";
import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';

class UserTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "https://www.digitizedresume.com/api/admin/",
      data: [],
      dataStatus: "false",
      page: 1,
      totalCol: 0,
      searchBy: '',
      showHide: false,
    };
  }

  handlePagination = (e, val) => {
    if (this.state.page != val) {
      if (val > this.state.page) {
        this.setState({
          page: val,
          totalCol: val * 5 - 5
        });
      } else {
        this.setState({
          page: val,
          totalCol: val * 5 - 5,
        });
      }
    }
  }

  componentDidMount() {
    if (!sessionStorage.getItem("aId")) {
      window.location.href = "/login";
    }
    var url = this.state.url + "getUserData.php";
    Axios.get(url)
      .then((res) => {
        // alert(res.data);
        this.setState({
          data: res.data,
          dataStatus: "true",
        });
        // console.log(res.data);
      })
      .catch(function (error) {
        alert(error);
        console.log(error);
      });
  }

  singleView = (e) => {
    e.preventDefault();
    var domain = e.target.value;
    // alert(domainWithId);
    window.open(domain, "_blank");
    // sessionStorage.setItem("companyId", e.target.value);
  }

  delete = (e) => {
    e.preventDefault();
    var id = e.target.value;

    swal({
      title: "Are you sure?",
      text: "Once removed, you won't be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          let formData = new FormData();
          formData.append("id", id);
          var url = this.state.url + "deleteUser.php";

          Axios.post(url, formData)
            .then((res) => {
              this.setState({
                dataStatus: "false"
              });
              swal({
                title: "Deleted!",
                text: "User deleted successfully!",
                button: "Close",
                icon: "success",
                timer: 3000,
                button: {
                  confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                  }
                }
              });
              var url = this.state.url + "getUserData.php";
              Axios.get(url)
                .then((res) => {
                  this.setState({
                    dataStatus: "true",
                    data: res.data
                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
            .catch((err) => {
              // console.log(err);
              this.setState({
                res: err,
              })
            });

        }
      });

  }

  updateStatus = (e) => {
    e.preventDefault();
    var updatedStatus;
    // this.setState({ dataStatus: "false" })
    if (e.target.value === "Active") {
      updatedStatus = "Deactive";
    }
    else if (e.target.value === "Deactive") {
      updatedStatus = "Active";
    }

    let formData = new FormData();
    formData.append("id", e.target.id);
    formData.append("status", updatedStatus);
    var url = this.state.url + "updateOnlineCVStatus.php";

    Axios.post(url, formData)
      .then((res) => {
        this.setState({
          dataStatus: "false"
        });
        swal({
          title: "Updated!",
          text: "Status updated successfully!",
          button: "Close",
          icon: "success",
          timer: 3000,
          button: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "",
              closeModal: true
            }
          }
        });
        var url = this.state.url + "getUserData.php";
        Axios.get(url)
          .then((res) => {
            this.setState({
              dataStatus: "true",
              data: res.data
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch((err) => {
        // console.log(err);
        this.setState({
          res: err,
        })
      });
  }

  handleModalShowHide = (e) => {
    e.preventDefault();
    this.setState({
      showHide: !this.state.showHide
    });
  }

  resetFilter = (e) => {
    this.setState({
      dataStatus: 'false'
    })
    var url = this.state.url + "getUserData.php";
    Axios.get(url)
      .then((res) => {
        // alert(res.data);
        this.setState({
          data: res.data,
          dataStatus: "true",
        });
        // console.log(res.data);
      })
      .catch(function (error) {
        alert(error);
        console.log(error);
      });
  }

  filterSearchData = (e) => {
    e.preventDefault();
    var nameSearchUser = document.getElementById("nameSearchUser").value;
    var phoneSearchUser = document.getElementById("phoneSearchUser").value;
    var userIdSearchUser = document.getElementById("userIdSearchUser").value;
    var availabilitySearchUser = document.getElementById("availabilitySearchUser").value;
    var CVStatusSearchUser = document.getElementById("CVStatusSearchUser").value;

    if (nameSearchUser !== "" || phoneSearchUser !== "" || userIdSearchUser !== "" || availabilitySearchUser !== "" || CVStatusSearchUser !== "") {


      this.setState({
        dataStatus: "false",
        showHide: !this.state.showHide
      });

      var SQL = "";
      var totalQuery = 0;

      SQL = "SELECT * FROM user WHERE ";
      if (nameSearchUser !== "") {
        if (totalQuery > 0) {
          SQL += " AND name LIKE '%" + nameSearchUser + "%'";
          totalQuery += 1;
        }
        else {
          SQL += "name LIKE '%" + nameSearchUser + "%'";
          totalQuery += 1;
        }
      }
      if (phoneSearchUser !== "") {
        if (totalQuery > 0) {
          SQL += " AND phone = " + phoneSearchUser;
          totalQuery += 1;
        }
        else {
          SQL += "phone LIKE '" + phoneSearchUser + "'";
          totalQuery += 1;
        }
      }
      if (userIdSearchUser !== "") {
        if (totalQuery > 0) {
          SQL += " AND uniqueId LIKE '%" + userIdSearchUser + "%'";
          totalQuery += 1;
        }
        else {
          SQL += "uniqueId LIKE '%" + userIdSearchUser + "%'";
          totalQuery += 1;
        }
      }
      if (availabilitySearchUser !== "") {
        if (availabilitySearchUser === "Full Time") {
          if (totalQuery > 0) {
            SQL += " AND availability LIKE 'Full Time'";
            totalQuery += 1;
          }
          else {
            SQL += "availability LIKE 'Full Time'";
            totalQuery += 1;
          }
        }
        else if (availabilitySearchUser === "Part Time") {
          if (totalQuery > 0) {
            SQL += " AND availability LIKE 'Part Time'";
            totalQuery += 1;
          }
          else {
            SQL += "availability LIKE 'Part Time'";
            totalQuery += 1;
          }
        }
        else {
          if (totalQuery > 0) {
            SQL += " AND availability LIKE 'Not Available'";
            totalQuery += 1;
          }
          else {
            SQL += "availability LIKE 'Not Available'";
            totalQuery += 1;
          }
        }
      }
      if (CVStatusSearchUser !== "") {
        if (CVStatusSearchUser === "Active") {
          if (totalQuery > 0) {
            SQL += " AND onlineCVStatus LIKE 'Active'";
            totalQuery += 1;
          }
          else {
            SQL += "onlineCVStatus LIKE 'Active'";
            totalQuery += 1;
          }
        }
        else {
          if (totalQuery > 0) {
            SQL += " AND onlineCVStatus LIKE 'Deactive'";
            totalQuery += 1;
          }
          else {
            SQL += "onlineCVStatus LIKE 'Deactive'";
            totalQuery += 1;
          }
        }
      }


      SQL += ";";

      let formData = new FormData();
      formData.append("sql_Query", SQL);

      console.log(SQL);

      var searchURL = this.state.url + "filterData/filterData.php";

      Axios.post(searchURL, formData).then((res) => {
        // alert(res.data);
        this.setState({
          data: res.data,
          dataStatus: "true"
        });
      })

    }
    else {
      swal({
        title: "Error!",
        text: "Please fill at least any one field to filter!",
        button: "Close",
        icon: "error",
        timer: 3000,
        button: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      });
    }
  }

  render() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">User</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="!#" onClick={(event) => event.preventDefault()}>
                  User
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                User
              </li>
            </ol>
          </nav>
        </div>
        <div className="row my-4">
          <div className="col-md-8"></div>
          <div className="col-md-4 text-right sticky">
            <button className="btn btn-sm btn-info" onClick={this.handleModalShowHide}>Filter <i className="fa fa-filter"></i></button>
            &nbsp;
            <button className="btn btn-sm btn-danger" onClick={this.resetFilter}>Rest Filter <i className="fa fa-close"></i></button>
          </div>
        </div>
        <Modal show={this.state.showHide}>
          <Modal.Header>
            <Modal.Title>Filter User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="row">
                <div className=" mt-2 col-md-12">
                  <label className="form-label" for="nameSearchUser">Enter Name</label>
                  <input type="text" className="form-control" name="nameSearchUser" id="nameSearchUser" placeholder="Enter Name" ></input>
                </div>
                <div className=" mt-2 col-md-6">
                  <label className="form-label" for="phoneSearchUser">Enter Phone</label>
                  <input type="number" className="form-control" name="phoneSearchUser" id="phoneSearchUser" placeholder="Enter Phone" ></input>
                </div>
                <div className="mt-2 col-md-6">
                  <label className="form-label" for="userIdSearchUser">Enter User Id</label>
                  <input type="text" className="form-control" name="userIdSearchUser" id="userIdSearchUser" placeholder="Enter User Id" ></input>
                </div>
                <div className="mt-2 col-md-6">
                  <label className="form-label" for="availabilitySearchUser">Select Availability</label>
                  <select id="availabilitySearchUser" className="form-control" name="availabilitySearchUser">
                    <option value="" selected>Select Any</option>
                    <option value="Full Time">Full Time</option>
                    <option value="Part Time">Part Time</option>
                    <option value="Nt Available">Nt Available</option>
                  </select>
                </div>
                <div className="mt-2 col-md-6">
                  <label className="form-label" for="CVStatusSearchUser">Select Digital CV Status</label>
                  <select id="CVStatusSearchUser" className="form-control" name="CVStatusSearchUser">
                    <option value="" selected>Select Any</option>
                    <option value="Active">Active</option>
                    <option value="Deactive">Deactive</option>
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" id="modalSkip" onClick={this.handleModalShowHide}>
              Cancel
            </Button>
            <Button type="submit" onClick={this.filterSearchData} id="modalValidate" variant="success">
              Filter
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.dataStatus === "false" ? (
          <Spinner />
        ) : (
          <div className="row">
            <div
              className="col-md-12 grid-margin stretch-card"
              style={{ marginTop: "10px" }}
            >
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">User Details</h4>
                  <div className="table-responsive">
                    <table className="table" id="userTable">
                      <thead>
                        <tr>
                          <th>SN.</th>
                          <th>User Id</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Mobile</th>
                          <th>Availability</th>
                          <th>Digital CV</th>
                          <th>View Details</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data.length === 0 ? (
                          <tr>
                            <td colSpan="9" className="text-center">
                              No Records Found
                            </td>
                          </tr>
                        ) : (
                          this.state.data.map((ele, i) => {
                            if (
                              i < this.state.page * 5 &&
                              this.state.totalCol <= i
                            ) {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{ele.uniqueId}</td>
                                  <td>{ele.name}</td>
                                  <td>{ele.email}</td>
                                  <td>{ele.phone}</td>
                                  <td>{ele.availability}</td>
                                  <td>{ele.onlineCVStatus === "Active" ?
                                    <button className="text-white status" id={ele.id} value={ele.onlineCVStatus} onClick={this.updateStatus}>{ele.onlineCVStatus}</button> : (
                                      <button className="Dstatus text-white" value={ele.onlineCVStatus} id={ele.id} onClick={this.updateStatus}>{ele.onlineCVStatus}</button>
                                    )}</td>
                                  <td>
                                    <div className="reqview">
                                      <button
                                        className="mdi mdi-eye"
                                        value={'https://digitizedresume.com/' + ele.domain}
                                        name={ele.id}
                                        id="buttonPadding"
                                        onClick={this.singleView}
                                      ></button>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="admindelete">
                                      <button
                                        className=""
                                        onClick={this.delete}
                                        className="mdi mdi-window-close"
                                        value={ele.id}
                                        id="buttonPadding"
                                      >
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          })
                        )}
                      </tbody>
                    </table>
                    <Pagination
                      count={(parseInt(this.state.data.length / 5) + 1)}
                      page={this.state.page}
                      onChange={this.handlePagination}
                      color="primary"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default UserTable;
