import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

class Single extends React.Component{
    render(){
        return(
            <div>
            </div>
        );
    }
}

export default Single;