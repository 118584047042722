import React, { Component } from 'react';
import './adminedit.css';
import { NavLink } from 'react-router-dom';

class AdminEdit extends Component{   

    render(){
        return(
            <div>
                {sessionStorage.getItem("aId") ? (
                    <>
                        <div className="page-header">
                            <h3 className="page-title">Admin</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Admin</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Admin Detail</li>
                                </ol>
                            </nav>
                        </div>


                        <div className="row">
                            <div className="col-md-12 Edit_profile bg-white col-12">
                                <div className="row border-bottom">
                                    <div className="col-md-6 col-8">
                                        <h6 className=" pl-3 pt-2">Edit Admin Detail</h6>
                                    </div>  
                                    <div className="col-md-6 col-4 close_button">
                                    <NavLink  to="/view_admin"> <i className="mdi mdi-close"></i></NavLink>
                                    </div>
                                </div>


                                <div className="row mt-2">
                                    <form className="mt-5 mb-5 admin_form">
                                        <div className="row px-5">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="your_name"><i className="zmdi mdi mdi-account material-icons-name"></i></label>
                                                    <input type="text" name="your_name"  placeholder="Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="email"><i className="zmdi mdi mdi-email-variant material-icons-name"></i></label>
                                                    <input type="email" name="email" placeholder="Email Id" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="password"><i className="zmdi mdi mdi mdi-lock material-icons-name"></i></label>
                                                    <input type="password" name="password"  placeholder="Password" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="number"><i className="zmdi mdi mdi-phone-plus material-icons-name"></i></label>
                                                    <input type="number" name="number" placeholder="Phone number" />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-4">
                                                    <button type="submit" className="login_button mt-3 shadow">Save Changes</button>
                                                    <button type="reset" className="reset_button mt-3 shadow">Reset</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>




                            </div>
                        </div>
                    </>
                ) : false}

            </div>
        )
    }
}
export default AdminEdit