import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './job.css';
import Axios from 'axios';
import Spinner from '../shared/Spinner';
import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';

class Job extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            dataStatus: "false",
            data: '',
            showHide: false,
        };
    }

    componentDidMount() {
        var url = this.state.url + "jobData.php";
        Axios.get(url)
            .then((res) => {
                this.setState({
                    data: res.data,
                    dataStatus: "true"
                });
                // console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    updateStatus = (e) => {
        e.preventDefault();
        var updatedStatus;
        this.setState({ dataStatus: "false" })
        if (e.target.value === "Active") {
            updatedStatus = "Deactive";
        }
        else if (e.target.value === "Deactive") {
            updatedStatus = "Active";
        }

        let formData = new FormData();
        formData.append("id", e.target.id);
        formData.append("status", updatedStatus);
        var url = this.state.url + "updateJobStatus.php";

        Axios.post(url, formData)
            .then((res) => {
                swal({
                    title: "Updated!",
                    text: "Status updated successfully!",
                    button: "Close",
                    icon: "success",
                    timer: 3000,
                    button: {
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                        }
                    }
                });
                var id = sessionStorage.getItem("aId");
                let formData = new FormData();
                formData.append("aId", id);
                var url = this.state.url + "jobData.php";
                Axios.post(url, formData)
                    .then((res) => {
                        this.setState({
                            data: res.data,
                            dataStatus: "true"
                        });
                        // console.log(res.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            })
            .catch((err) => {
                this.setState({
                    res: err,
                })
            });
        this.setState({ dataStatus: "true" })
    }

    delete = (e) => {
        e.preventDefault();
        var id = e.target.value;
        swal({
            title: "Are you sure?",
            text: "Once removed, you won't be able to recover!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    this.setState({ dataStatus: "false" })
                    let formData = new FormData();
                    formData.append("id", id);
                    var url = this.state.url + "deleteJob.php";

                    Axios.post(url, formData)
                        .then((res) => {
                            swal({
                                title: "Deleted!",
                                text: "Job deleted successfully!",
                                button: "Close",
                                icon: "success",
                                timer: 3000,
                                button: {
                                    confirm: {
                                        text: "OK",
                                        value: true,
                                        visible: true,
                                        className: "",
                                        closeModal: true
                                    }
                                }
                            });
                            var id = sessionStorage.getItem("aId");
                            let formData = new FormData();
                            formData.append("aId", id);
                            var url = this.state.url + "jobData.php";
                            Axios.post(url, formData)
                                .then((res) => {
                                    this.setState({ data: res.data });
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                        })
                        .catch((err) => {
                            this.setState({
                                res: err,
                            })
                        });
                    this.setState({ dataStatus: "true" })

                }
            });
    }

    viewJobDetails = (e) => {
        e.preventDefault();
        // alert(e.target.id);

        sessionStorage.setItem("viewJobDetails", "true");
        sessionStorage.setItem("jobId", e.target.id);
    }

    viewAppliedJobDetails = (e) => {
        e.preventDefault();
        // alert(e.target.id);
        sessionStorage.setItem("JobStatus", "true");
        sessionStorage.setItem("jobId", e.target.id);
    }

    resetFilter = (e) => {
        this.setState({
            dataStatus: 'false'
        })
        var url = this.state.url + "jobData.php";
        Axios.get(url)
            .then((res) => {
                this.setState({
                    data: res.data,
                    dataStatus: "true"
                });
                // console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleModalShowHide = (e) => {
        e.preventDefault();
        this.setState({
            showHide: !this.state.showHide
        });
    }

    filterSearchData = (e) => {
        e.preventDefault();
        var nameSearchJob = document.getElementById("nameSearchJob").value;
        var locationSearchJob = document.getElementById("locationSearchJob").value;
        var jobTypeSearchJob = document.getElementById("jobTypeSearchJob").value;
        var remoteJobSearchJob = document.getElementById("remoteJobSearchJob").value;
        var experienceSearchJob = document.getElementById("experienceSearchJob").value;
        var statusSearchJob = document.getElementById("statusSearchJob").value;

        if (nameSearchJob !== "" || locationSearchJob !== "" || jobTypeSearchJob !== "" || remoteJobSearchJob !== "" || experienceSearchJob !== "" || statusSearchJob !== "") {

            var id = sessionStorage.getItem("aId");

            this.setState({
                dataStatus: "false",
                showHide: !this.state.showHide
            });

            var SQL = "";
            var totalQuery = 0;

            SQL = "SELECT * FROM createjob user WHERE";
            if (nameSearchJob !== "") {
                if (totalQuery > 0) {
                    SQL += " AND jobTitle LIKE '%" + nameSearchJob + "%'";
                    totalQuery += 1;
                }
                else {
                    SQL += " jobTitle LIKE '%" + nameSearchJob + "%'";
                    totalQuery += 1;
                }
            }
            if (locationSearchJob !== "") {
                if (totalQuery > 0) {
                    SQL += " AND location LIKE '%" + locationSearchJob + "%'";
                    totalQuery += 1;
                }
                else {
                    SQL += " location LIKE '%" + locationSearchJob + "%'";
                    totalQuery += 1;
                }
            }
            if (jobTypeSearchJob !== "") {
                if (totalQuery > 0) {
                    SQL += " AND jobType LIKE '%" + jobTypeSearchJob + "%'";
                    totalQuery += 1;
                }
                else {
                    SQL += " jobType LIKE '%" + jobTypeSearchJob + "%'";
                    totalQuery += 1;
                }
            }
            if (remoteJobSearchJob !== "") {
                if (totalQuery > 0) {
                    SQL += " AND remoteJob LIKE '%" + remoteJobSearchJob + "%'";
                    totalQuery += 1;
                }
                else {
                    SQL += " remoteJob LIKE '%" + remoteJobSearchJob + "%'";
                    totalQuery += 1;
                }
            }
            if (experienceSearchJob !== "") {
                if (totalQuery > 0) {
                    SQL += " AND exp LIKE '%" + experienceSearchJob + "%'";
                    totalQuery += 1;
                }
                else {
                    SQL += " exp LIKE '%" + experienceSearchJob + "%'";
                    totalQuery += 1;
                }
            }
            if (statusSearchJob !== "") {
                if (totalQuery > 0) {
                    SQL += " AND status LIKE '%" + statusSearchJob + "%'";
                    totalQuery += 1;
                }
                else {
                    SQL += " status LIKE '%" + statusSearchJob + "%'";
                    totalQuery += 1;
                }
            }

            SQL += ";";

            let formData = new FormData();
            formData.append("sql_Query", SQL);

            console.log(SQL);

            var searchURL = this.state.url + "filterData/filterData.php";

            Axios.post(searchURL, formData).then((res) => {
                // alert(res.data);
                this.setState({
                    data: res.data,
                    dataStatus: "true"
                });
            })

        }
        else {
            swal({
                title: "Error!",
                text: "Please fill at least any one field to filter!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
    }

    render() {
        return (
            <>
                {sessionStorage.getItem("aId") ?
                    <>
                        <div className="snackbar">Some text some message..</div>
                        <div className="page-header">
                            <h3 className="page-title">Job</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">All Trending Jobs</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="row my-4">
                            <div className="col-md-8"></div>
                            <div className="col-md-4 text-right sticky">
                                <button className="btn btn-sm btn-info" onClick={this.handleModalShowHide}>Filter <i className="fa fa-filter"></i></button>
                                &nbsp;
                                <button className="btn btn-sm btn-danger" onClick={this.resetFilter}>Rest Filter <i className="fa fa-close"></i></button>
                            </div>
                        </div>
                        <Modal show={this.state.showHide}>
                            <Modal.Header>
                                <Modal.Title>Filter Job</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="form-label" for="nameSearchJob">Enter Job Title</label>
                                            <input type="text" className="form-control" name="nameSearchJob" id="nameSearchJob" placeholder="Enter Job Title" ></input>
                                        </div>
                                        <div className=" mt-3 col-md-6">
                                            <label className="form-label" for="locationSearchJob">Enter Location</label>
                                            <input type="text" className="form-control" name="locationSearchJob" id="locationSearchJob" placeholder="Enter Location" ></input>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <label for="jobTypeSearchJob">Job Type</label>
                                            <select className="form-control" name="jobTypeSearchJob" id="jobTypeSearchJob">
                                                <option value="" selected disabled>Select Any</option>
                                                <option value="Full Time">Full Time</option>
                                                <option value="Part Time">Part Time</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <label for="remoteJobSearchJob">Remote Job?</label>
                                            <select className="form-control" name="remoteJobSearchJob" id="remoteJobSearchJob">
                                                <option value="" selected disabled>Select Any</option>
                                                <option value="Yes" >Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <label for="experienceSearchJob">Experience</label>
                                            <select className="form-control" name="experienceSearchJob" id="experienceSearchJob">
                                                <option value="" selected disabled>Select Any</option>
                                                <option value="0-3 Years">0 -3 Years Of Experience</option>
                                                <option value="3-5 Years">3 -5 Years Of Experience</option>
                                                <option value="5+ Years">5+ Years Of Experience</option>
                                            </select>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <label for="statusSearchJob">Status</label>
                                            <select className="form-control" name="statusSearchJob" id="statusSearchJob">
                                                <option value="" selected disabled>Select Any</option>
                                                <option value="Active">Active</option>
                                                <option value="Deactive">Deactive</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="danger" id="modalSkip" onClick={this.handleModalShowHide}>
                                    Cancel
                                </Button>
                                <Button type="submit" onClick={this.filterSearchData} id="modalValidate" variant="success">
                                    Filter
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        {this.state.dataStatus === "false" ? (
                            <Spinner />
                        ) : (
                            <div className="row jobViewSection">
                                {this.state.data.length === 0 ? (
                                    <div>
                                        <p className="text-center">
                                            No records found. Please  create job 1st to view posted jobs!
                                        </p>
                                    </div>
                                ) : (
                                    this.state.data.map((ele, i) => {
                                        return (

                                            <div className="col-md-10 mx-auto mt-3">
                                                <div className="card">
                                                    <div className="py-1 border-bottom bg-white">
                                                        <div className="row px-0 mx-0 job_section">
                                                            <div className="col-md-10 col-8">
                                                                <h5 className="pt-2 text-danger"><b>{ele.jobTitle}</b></h5>
                                                            </div>
                                                            <div className="col-md-1 col-2">
                                                                <button className="float-left delete_btn mdi mdi-delete mt-1" onClick={this.delete} value={ele.id}></button>
                                                            </div>
                                                            <div className="col-md-1 col-2">
                                                                <button onClick={this.viewJobDetails} id={ele.id} className=" float-right mt-1 View_btn">
                                                                    <NavLink className="text-center" to="/view-single-job">
                                                                        <button id={ele.id} className=" mdi mdi-eye"></button>
                                                                    </NavLink>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                        <div className="row">
                                                            <div className="col-md-12 job_detail">
                                                                <label>Location :- {ele.location}</label><br />
                                                                <label>Salary :- {ele.minSalary} - {ele.maxSalary}</label><br />
                                                                <label>Job Posting Date :- {ele.date}</label>
                                                            </div>

                                                            <div className="col-md-12 col-12 mt-3 applied">
                                                                <label className="text-dark">Application Status</label>
                                                                {ele.status === "Active" ? <button className="text-white sactive" id={ele.id} value={ele.status} onClick={this.updateStatus}>{ele.status}</button> : (
                                                                    <button className="text-white sdeactive" value={ele.status} id={ele.id} onClick={this.updateStatus}>{ele.status}</button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="py-2 border-top bg-white">
                                                        <div className="row px-0 mx-0">
                                                            <div className="col-md-8 col-12 applied">
                                                                <span className="text-dark">Total Applied Application</span>
                                                                <p className="totalCount px-2" ><b>{ele.application}</b></p>
                                                            </div>
                                                            <div className="col-md-4 col-12 applied_bottom">
                                                                <button onClick={this.viewAppliedJobDetails} id={ele.id} className="btn float-right btn-primary btn-sm infoButton1 m-0 p-0">
                                                                    <NavLink className="text-center" to="/view-applied-job-details">
                                                                        <button id={ele.id} className="btn btn-primary btn-sm ">View Applied Application</button>
                                                                    </NavLink>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        );
                                    })
                                )}
                            </div>
                        )}
                    </> : false
                }
            </>
        )
    }
}
export default Job;