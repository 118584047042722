import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';
import Pagination from "@material-ui/lab/Pagination";
import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';
import "./learning.css";
import ReactPlayer from 'react-player';
class Video extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            data: [],
            category: [],
            subCategory: [],
            subCategoryForFilter: [],
            dataStatus: "false",
            page: 1,
            totalCol: 0,
            showHide: false,
            videoTitle: '',
            videoURL: '',
            videoDescription: '',
            videoCategory: '',
            videoSubCategory: '',
            showHideUpdateVideoModal: false,
            updateDataStatus: 'false',
            updateVideoId: 0,
            showHideViewVideoModal: false,
            viewDataStatus: 'false',
            filterDataModal: false
        };
    }
    handle = (e) => {
        e.preventDefault();
        var subCategoryURL = this.state.url + "getSubCategoryDataForSingleCategory.php";
        if (e.target.value.trim() === "") {
            e.target.value = "";
        }
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === "videoCategory") {
            let formData = new FormData();
            formData.append("category", document.getElementById("videoCategory").value);
            Axios.post(subCategoryURL, formData)
                .then((res) => {
                    console.log(res.data);
                    // alert(res.data);
                    this.setState({
                        subCategory: res.data,
                        dataStatus: "true",
                    });
                })
                .catch(function (error) {
                    // alert(error);
                    console.log(error);
                });
        }
        if (e.target.name === "categorySearchVideo") {
            let formData = new FormData();
            formData.append("category", document.getElementById("categorySearchVideo").value);
            Axios.post(subCategoryURL, formData)
                .then((res) => {
                    console.log(res.data);
                    // alert(res.data);
                    this.setState({
                        subCategoryForFilter: res.data,
                        dataStatus: "true",
                    });
                })
                .catch(function (error) {
                    // alert(error);
                    console.log(error);
                });
        }
    }
    handleModalShowHide = (e) => {
        e.preventDefault();
        this.setState({
            showHide: !this.state.showHide
        });
    }
    handleUpdateVideoModalShowHide = (e) => {
        e.preventDefault();
        this.setState({
            showHideUpdateVideoModal: !this.state.showHideUpdateVideoModal,
            updateDataStatus: 'false',
            updateVideoId: 0
        });
    }
    handleViewVideoModalShowHide = (e) => {
        e.preventDefault();
        this.setState({
            showHideViewVideoModal: !this.state.showHideViewVideoModal,
            viewDataStatus: 'false',
            updateVideoId: 0
        });
    }
    filterDataModalHandleShowHide = (e) => {
        e.preventDefault();
        this.setState({
            filterDataModal: !this.state.filterDataModal
        });
    }
    componentDidMount() {
        var url = this.state.url + "getVideoData.php";
        Axios.get(url)
            .then((res) => {
                // console.log(res.data);
                this.setState({
                    data: res.data,
                });
            })
            .catch(function (error) {
                // alert(error);
                console.log(error);
            });

        var categoryURL = this.state.url + "getCategoryData.php";
        Axios.get(categoryURL)
            .then((res) => {
                // console.log(res.data);
                this.setState({
                    category: res.data,
                    dataStatus: "true",
                });
            })
            .catch(function (error) {
                // alert(error);
                console.log(error);
            });
    }
    handlePagination = (e, val) => {
        if (this.state.page != val) {
            if (val > this.state.page) {
                this.setState({
                    page: val,
                    totalCol: val * 5 - 5,
                });
            } else {
                this.setState({
                    page: val,
                    totalCol: val * 5 - 5,
                });
            }
        }
    }
    addVideo = (e) => {
        e.preventDefault();
        // alert(this.state.videoCategory);
        if (this.state.videoTitle !== "" && this.state.videoCategory !== "" && this.state.videoSubCategory !== "" && this.state.videoURL !== "" && this.state.videoDescription !== "") {
            this.setState({
                showHide: !this.state.showHide,
                dataStatus: "false",
            });
            var url = this.state.url + "addVideo.php";
            let formData = new FormData();
            formData.append("title", this.state.videoTitle);
            formData.append("url", this.state.videoURL);
            formData.append("category", this.state.videoCategory);
            formData.append("subCategory", this.state.videoSubCategory);
            formData.append("description", this.state.videoDescription);
            Axios.post(url, formData).then((res) => {
                if (res.data === 1) {
                    var url = this.state.url + "getVideoData.php";
                    Axios.get(url)
                        .then((res) => {
                            console.log(res.data);
                            this.setState({
                                data: res.data,
                                dataStatus: "true",
                                videoTitle: '',
                                videoURL: '',
                                videoDescription: '',
                                videoCategory: '',
                            });
                        })
                        .catch(function (error) {
                            // alert(error);
                            console.log(error);
                        });
                    swal({
                        title: "Added!",
                        text: "Video added successfully!",
                        button: "Close",
                        icon: "success",
                        timer: 3000,
                        button: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                            }
                        }
                    });
                }
            })
        }
        else {
            swal({
                title: "Error!",
                text: "Please fill all fields to proceed further!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
    }
    deleteVideo = (e) => {
        e.preventDefault();
        var id = e.target.value;
        swal({
            title: "Are you sure?",
            text: "Once removed, you will have to add again!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    this.setState({
                        dataStatus: "false"
                    });
                    let formData = new FormData();
                    formData.append("id", id);
                    var url = this.state.url + "deleteVideo.php";
                    Axios.post(url, formData)
                        .then((res) => {
                            var url = this.state.url + "getVideoData.php";
                            Axios.get(url)
                                .then((res) => {
                                    console.log(res.data);
                                    this.setState({
                                        data: res.data,
                                        dataStatus: "true",
                                    });
                                })
                                .catch(function (error) {
                                    // alert(error);
                                    console.log(error);
                                });
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                }
            });
    }
    updateVideo = (e) => {
        e.preventDefault();
        // alert(this.state.updateVideoId);
        if (this.state.videoTitle !== "" && this.state.videoURL !== "" && this.state.videoDescription !== "") {
            this.setState({
                showHideUpdateVideoModal: !this.state.showHideUpdateVideoModal,
                updateDataStatus: "false",
                dataStatus: 'false'
            });
            var url = this.state.url + "updateVideo.php";
            let formData = new FormData();
            formData.append("title", this.state.videoTitle);
            formData.append("url", this.state.videoURL);
            formData.append("description", this.state.videoDescription);
            formData.append("id", this.state.updateVideoId);
            Axios.post(url, formData).then((res) => {
                if (res.data === 1) {
                    var url = this.state.url + "getVideoData.php";
                    Axios.get(url)
                        .then((res) => {
                            console.log(res.data);
                            this.setState({
                                data: res.data,
                                dataStatus: "true",
                                videoTitle: '',
                                videoURL: '',
                                videoDescription: '',
                                videoCategory: '',
                                updateVideoId: 0
                            });
                        })
                        .catch(function (error) {
                            // alert(error);
                            console.log(error);
                        });
                    swal({
                        title: "Updated!",
                        text: "Video updated successfully!",
                        button: "Close",
                        icon: "success",
                        timer: 3000,
                        button: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                            }
                        }
                    });
                }
            })
        }
        else {
            swal({
                title: "Error!",
                text: "Please fill all fields to proceed further!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
    }
    editVideo = (e) => {
        e.preventDefault();
        this.setState({
            showHideUpdateVideoModal: !this.state.showHideUpdateVideoModal
        });
        var id = e.target.value;
        // alert(id);
        var url = this.state.url + "getSingleVideoData.php";
        let formData = new FormData();
        formData.append("id", id);
        Axios.post(url, formData)
            .then((res) => {
                // alert(res.data);
                // console.log(res.data);
                this.setState({
                    videoTitle: res.data.title,
                    videoURL: res.data.url,
                    videoDescription: res.data.description,
                    videoCategory: res.data.category,
                    updateDataStatus: 'true',
                    updateVideoId: res.data.id
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    viewVideo = (e) => {
        e.preventDefault();
        this.setState({
            showHideViewVideoModal: !this.state.showHideViewVideoModal
        });
        var id = e.target.value;
        // alert(id);
        var url = this.state.url + "getSingleVideoData.php";
        let formData = new FormData();
        formData.append("id", id);
        Axios.post(url, formData)
            .then((res) => {
                // alert(res.data);
                // console.log(res.data);
                this.setState({
                    videoTitle: res.data.title,
                    videoURL: res.data.url,
                    videoDescription: res.data.description,
                    videoCategory: res.data.category,
                    videoSubCategory: res.data.subCategory,
                    viewDataStatus: 'true',
                    updateVideoId: res.data.id
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    resetFilter = (e) => {
        this.setState({
            dataStatus: 'false'
        })
        var url = this.state.url + "getVideoData.php";
        Axios.get(url)
            .then((res) => {
                // console.log(res.data);
                this.setState({
                    data: res.data,
                });
            })
            .catch(function (error) {
                // alert(error);
                console.log(error);
            });

        var categoryURL = this.state.url + "getCategoryData.php";
        Axios.get(categoryURL)
            .then((res) => {
                // console.log(res.data);
                this.setState({
                    category: res.data,
                    dataStatus: "true",
                });
            })
            .catch(function (error) {
                // alert(error);
                console.log(error);
            });
    }
    filterSearchData = (e) => {
        e.preventDefault();
        var categorySearchVideo = document.getElementById("categorySearchVideo").value;
        var titleSearchVideo = document.getElementById("titleSearchVideo").value;
        var videoSubCategoryForFilter = document.getElementById("videoSubCategoryForFilter").value;

        if (categorySearchVideo !== "" || titleSearchVideo !== "" || videoSubCategoryForFilter !== "") {
            var id = sessionStorage.getItem("aId");
            this.setState({
                dataStatus: "false",
                filterDataModal: !this.state.filterDataModal
            });

            var SQL = "";
            var query = 0;

            SQL = "SELECT * FROM learning WHERE ";
            if (titleSearchVideo !== "") {
                if (query > 0) {
                    SQL += " AND title LIKE '%" + titleSearchVideo + "%'";
                    query += 1;
                }
                else {
                    SQL += " title LIKE '%" + titleSearchVideo + "%'";
                    query += 1;
                }
            }
            if (categorySearchVideo !== "") {
                if (query > 0) {
                    SQL += " AND category LIKE '%" + categorySearchVideo + "%'";
                    query += 1;
                }
                else {
                    SQL += " category LIKE '%" + categorySearchVideo + "%'";
                    query += 1;
                }
            }
            if (videoSubCategoryForFilter !== "") {
                if (query > 0) {
                    SQL += " AND subCategory LIKE '%" + videoSubCategoryForFilter + "%'";
                    query += 1;
                }
                else {
                    SQL += " subCategory LIKE '%" + videoSubCategoryForFilter + "%'";
                    query += 1;
                }
            }

            SQL += ";";

            let formData = new FormData();
            formData.append("sql_Query", SQL);

            console.log(SQL);

            var searchURL = this.state.url + "filterData/filterData.php";

            Axios.post(searchURL, formData).then((res) => {
                // alert(res.data);
                this.setState({
                    data: res.data,
                    dataStatus: "true"
                });
            })

        }
        else {
            swal({
                title: "Error!",
                text: "Please fill at least any one field to filter!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
    }
    render() {
        return (
            <div>{sessionStorage.getItem("aId") ? (
                <div>
                    <div className="page-header">
                        <h3 className="page-title">Video</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="!#" onClick={(event) => event.preventDefault()}>
                                        Video
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    All Videos
                                </li>
                            </ol>
                        </nav>
                    </div>
                    {
                        this.state.dataStatus === "false" ? (<Spinner />) : (
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="row my-4">
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6 text-right sticky">
                                            <button className="btn btn-sm btn-primary" onClick={this.handleModalShowHide}><i className="fa fa-plus"></i> Add Video</button>
                                            &nbsp;
                                            <button className="btn btn-sm btn-info" onClick={this.filterDataModalHandleShowHide}>Filter <i className="fa fa-filter"></i></button>
                                            &nbsp;
                                            <button className="btn btn-sm btn-danger" onClick={this.resetFilter}>Rest Filter <i className="fa fa-close"></i></button>
                                        </div>
                                    </div>
                                    {/* filter data modal start */}
                                    <Modal show={this.state.filterDataModal}>
                                        <Modal.Header>
                                            <Modal.Title>Filter Video</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div>
                                                <div className="row">
                                                    <div className="mt-2 col-md-6">
                                                        <label className="form-label" for="categorySearchVideo">Select Video Category</label>
                                                        <select id="categorySearchVideo" onChange={this.handle} className="form-control" name="categorySearchVideo">
                                                            {this.state.category.length === 0 ? (
                                                                <option value="" disabled selected>Add Category First - No Category Found</option>
                                                            ) : (
                                                                this.state.category.map((ele, i) => {
                                                                    // console.log(i);
                                                                    if (i === 0) {
                                                                        return (
                                                                            <>
                                                                                <option value="" disabled selected>Select Any</option>
                                                                                <option value={ele.category}>{ele.category}</option>
                                                                            </>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <option value={ele.category}>{ele.category}</option>
                                                                    );

                                                                })
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className="mt-2 col-md-6">
                                                        <label className="form-label" for="videoSubCategoryForFilter">Select Video Sub Category</label>
                                                        <select id="videoSubCategoryForFilter" onChange={this.handle} className="form-control" name="videoSubCategoryForFilter">
                                                            {this.state.subCategoryForFilter.length === 0 ? (
                                                                <option value="" disabled selected>Add Sub Category First - No Sub Category Found</option>
                                                            ) : (
                                                                this.state.subCategoryForFilter.map((ele, i) => {
                                                                    // console.log(i);
                                                                    if (i === 0) {
                                                                        return (
                                                                            <>
                                                                                <option value="" disabled selected>Select Any</option>
                                                                                <option value={ele.subCategory}>{ele.subCategory}</option>
                                                                            </>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <option value={ele.subCategory}>{ele.subCategory}</option>
                                                                    );

                                                                })
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className=" mt-2 col-md-12">
                                                        <label className="form-label" for="titleSearchVideo">Enter Video Title</label>
                                                        <input type="text" className="form-control" name="titleSearchVideo" id="titleSearchVideo" placeholder="Enter Video Title" ></input>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="danger" id="modalSkip" onClick={this.filterDataModalHandleShowHide}>
                                                Cancel
                                            </Button>
                                            <Button type="submit" onClick={this.filterSearchData} id="modalValidate" variant="success">
                                                Filter
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    {/* filter data modal end */}
                                    {/* add video modal start */}
                                    <Modal show={this.state.showHide}>
                                        <Modal.Header>
                                            <Modal.Title>Add Video</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div>
                                                <div className="row">
                                                    <div className=" mt-2 col-md-12">
                                                        <label className="form-label" for="videoTitle">Enter Video Title</label>
                                                        <input type="text" className="form-control" name="videoTitle" id="videoTitle" placeholder="Enter Video Title" value={this.state.videoTitle} onChange={this.handle} ></input>
                                                    </div>
                                                    <div className="col-md-12 mt-2">
                                                        <label htmlFor="videoCategory" className="form-label">Select Video Category</label>
                                                        <select name="videoCategory" onChange={this.handle} id="videoCategory" className="form-control">
                                                            {this.state.category.length === 0 ? (
                                                                <option value="" disabled selected>Add Category First - No Category Found</option>
                                                            ) : (
                                                                this.state.category.map((ele, i) => {
                                                                    // console.log(i);
                                                                    if (i === 0) {
                                                                        return (
                                                                            <>
                                                                                <option value="" disabled selected>Select Any</option>
                                                                                <option value={ele.category}>{ele.category}</option>
                                                                            </>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <option value={ele.category}>{ele.category}</option>
                                                                    );

                                                                })
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className="mt-2 col-md-12">
                                                        <label className="form-label" for="videoSubCategory">Select Video Sub Category</label>
                                                        <select id="videoSubCategory" onChange={this.handle} className="form-control" name="videoSubCategory">
                                                            {this.state.subCategory.length === 0 ? (
                                                                <option value="" disabled selected>Add Sub Category First - No Sub Category Found</option>
                                                            ) : (
                                                                this.state.subCategory.map((ele, i) => {
                                                                    // console.log(i);
                                                                    if (i === 0) {
                                                                        return (
                                                                            <>
                                                                                <option value="" disabled selected>Select Any</option>
                                                                                <option value={ele.subCategory}>{ele.subCategory}</option>
                                                                            </>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <option value={ele.subCategory}>{ele.subCategory}</option>
                                                                    );

                                                                })
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className=" mt-2 col-md-12">
                                                        <label className="form-label" for="videoURL">Enter Video URL</label>
                                                        <input type="text" className="form-control" name="videoURL" id="videoURL" placeholder="Enter Video URL" value={this.state.videoURL} onChange={this.handle} ></input>
                                                    </div>
                                                    <div className=" mt-2 col-md-12">
                                                        <label className="form-label" for="videoDescription">Enter Video Description</label>
                                                        <textarea className="form-control" name="videoDescription" id="videoDescription" placeholder="Enter Video Description" value={this.state.videoDescription} onChange={this.handle} cols="30" rows="10"></textarea>
                                                    </div>
                                                    {/* <small className="ml-3">Category will help the user to filter learning related video.</small> */}
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="danger" id="modalSkip" onClick={this.handleModalShowHide}>
                                                Cancel
                                            </Button>
                                            <Button type="submit" onClick={this.addVideo} id="modalValidate" variant="success">
                                                Add
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    {/* add video modal end */}

                                    {/* edit video modal start */}
                                    <Modal show={this.state.showHideUpdateVideoModal}>
                                        <Modal.Header>
                                            <Modal.Title>Update Video</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div>
                                                {this.state.updateDataStatus === "false" ? (<Spinner />) : (
                                                    <div className="row">
                                                        <div className=" mt-2 col-md-12">
                                                            <label className="form-label" for="videoTitle">Enter Video Title</label>
                                                            <input type="text" className="form-control" name="videoTitle" id="videoTitle" placeholder="Enter Video Title" value={this.state.videoTitle} onChange={this.handle} ></input>
                                                        </div>
                                                        <div className=" mt-2 col-md-12">
                                                            <label className="form-label" for="videoURL">Enter Video URL</label>
                                                            <input type="text" className="form-control" name="videoURL" id="videoURL" placeholder="Enter Video URL" value={this.state.videoURL} onChange={this.handle} ></input>
                                                        </div>
                                                        <div className=" mt-2 col-md-12">
                                                            <label className="form-label" for="videoDescription">Enter Video Description</label>
                                                            <textarea className="form-control" name="videoDescription" id="videoDescription" placeholder="Enter Video Description" value={this.state.videoDescription} onChange={this.handle} cols="30" rows="10"></textarea>
                                                        </div>
                                                        {/* <small className="ml-3">Category will help the user to filter learning related video.</small> */}
                                                    </div>
                                                )}
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="danger" id="modalSkip" onClick={this.handleUpdateVideoModalShowHide}>
                                                Cancel
                                            </Button>
                                            <Button type="submit" onClick={this.updateVideo} id="modalValidate" variant="success">
                                                Update
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    {/* edit video modal end */}

                                    {/* view video modal start */}
                                    <Modal show={this.state.showHideViewVideoModal}>
                                        <Modal.Header>
                                            <Modal.Title>View Video Details</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div>
                                                {this.state.viewDataStatus === "false" ? (<Spinner />) : (
                                                    <div className="row">
                                                        <div className=" mt-2 col-md-12">
                                                            <label className="form-label" for="videoTitle">Video Title</label>
                                                            <input readOnly type="text" className="form-control bg-white" name="videoTitle" id="videoTitle" placeholder="Enter Video Title" value={this.state.videoTitle} ></input>
                                                        </div>
                                                        <div className="col-md-12 mt-2">
                                                            <label htmlFor="videoCategory" className="form-label">Video Category</label>
                                                            <input readOnly type="text" className="form-control bg-white" name="videoTitle" id="videoTitle" placeholder="Enter Video Title" value={this.state.videoCategory} ></input>
                                                        </div>
                                                        <div className="col-md-12 mt-2">
                                                            <label htmlFor="videoCategory" className="form-label">Video Sub Category</label>
                                                            <input readOnly type="text" className="form-control bg-white" name="videoTitle" id="videoTitle" placeholder="Enter Video Title" value={this.state.videoSubCategory} ></input>
                                                        </div>
                                                        <div className=" mt-2 col-md-12">
                                                            <label className="form-label" for="videoURL">Video URL</label>
                                                            <input readOnly type="text" className="form-control bg-white" name="videoURL" id="videoURL" placeholder="Enter Video URL" value={this.state.videoURL} ></input>
                                                        </div>
                                                        <div className=" mt-2 col-md-12">
                                                            <label className="form-label" for="videoDescription">Video Description</label>
                                                            <textarea readOnly className="form-control bg-white" name="videoDescription" id="videoDescription" placeholder="Enter Video Description" value={this.state.videoDescription} cols="30" rows="10"></textarea>
                                                        </div>
                                                        <div className="my-2 col-md-12">
                                                            <label htmlFor="" className="form-label">Preview Video</label>
                                                            <ReactPlayer
                                                                width="100%"
                                                                height="100%"
                                                                controls
                                                                allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
                                                                allowfullscreen
                                                                url={this.state.videoURL}
                                                            />
                                                        </div>
                                                        {/* <small className="ml-3">Category will help the user to filter learning related video.</small> */}
                                                    </div>
                                                )}
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="danger" id="modalSkip" onClick={this.handleViewVideoModalShowHide}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    {/* view video modal end */}

                                </div>

                                <div className="col-md-12 grid-margin stretch-card" style={{ marginTop: "10px" }} >
                                    {this.state.dataStatus === "false" ? <Spinner /> :
                                        (
                                            <div className="card">

                                                <div className="card-body">

                                                    <h4 className="card-title">Category Details</h4>
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>SN.</th>
                                                                    <th>Video Title</th>
                                                                    <th>Video Category</th>
                                                                    <th>Edit</th>
                                                                    <th>Video</th>
                                                                    <th>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.data.length === 0 ? (
                                                                    <tr>
                                                                        <td colSpan="6" className="text-center">
                                                                            No Records Found
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    this.state.data.map((ele, i) => {
                                                                        if (
                                                                            i < this.state.page * 5 &&
                                                                            this.state.totalCol <= i
                                                                        ) {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{i + 1}</td>
                                                                                    <td>{ele.title}</td>
                                                                                    <td>{ele.category}</td>
                                                                                    <td><button className="btn btn-primary btn-sm" value={ele.id} onClick={this.editVideo}>Edit</button></td>
                                                                                    <td><button className="btn btn-info btn-sm" value={ele.id} onClick={this.viewVideo}>View</button></td>
                                                                                    <td><button className="btn btn-danger btn-sm" value={ele.id} onClick={this.deleteVideo}>Remove</button></td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    })
                                                                )}
                                                            </tbody>
                                                        </table>
                                                        <Pagination
                                                            count={parseInt(this.state.data.length / 5) + 1}
                                                            page={this.state.page}
                                                            onChange={this.handlePagination}
                                                            color="primary"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        )}
                                </div>

                            </div>
                        )
                    }
                </div>
            ) : (<Spinner />)}
            </div>
        );
    }
}

export default Video;