import React, { Component } from 'react';
import './singleuserview.css';
import certificate from "./certificate.png";


class Education extends Component{
    render(){
        return(
            <div className="row">
                <div className="col-md-11 education px-0 mx-0 col-12">
                    <div className="row border m-1 p-2">
                        <div className="col-md-6 mt-2">
                            <p>Highest Education </p>
                            <h6>MCA</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Highest Qualification: </p>
                            <h6>Masters</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Passing Year: </p>
                            <h6>2019</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Course: </p>
                            <h6>MCA</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Course Type: </p>
                            <h6>Full Time</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Specialization: </p>
                            <h6>Computers</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Pass Percentage: </p>
                            <h6>70%</h6>
                        </div>
                        <div className="col-md-6 certificate mt-2">
                            <p>Certificate: </p>
                            <img src={certificate} className="img-fluid" alt="upload certificate"  />
                        </div>
                    </div>



                    <div className="row border m-1 p-2">
                        <div className="col-md-6 mt-2">
                            <p>University/College </p>
                            <h6>xyz collage</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Qualification: </p>
                            <h6>Graduation</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Passing Year: </p>
                            <h6>2016</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Course: </p>
                            <h6>BCA</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Course Type: </p>
                            <h6>Full Time</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Specialization: </p>
                            <h6>Computers</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Pass Percentage: </p>
                            <h6>80%</h6>
                        </div>
                        <div className="col-md-6 certificate mt-2">
                            <p>Certificate: </p>
                            <img src={certificate} className="img-fluid" alt="upload certificate"  />
                        </div>
                    </div>



                    <div className="row border m-1 p-2">
                        <div className="col-md-6 mt-2">
                            <p>University/College </p>
                            <h6>xyz collage</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Qualification: </p>
                            <h6>Intermidiate</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Passing Year: </p>
                            <h6>2014</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Course: </p>
                            <h6>12</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Course Type: </p>
                            <h6>Full Time</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Specialization: </p>
                            <h6>Computers</h6>
                        </div>
                        <div className="col-md-6 mt-2">
                            <p>Pass Percentage: </p>
                            <h6>80%</h6>
                        </div>
                        <div className="col-md-6 certificate mt-2">
                            <p>Certificate: </p>
                            <img src={certificate} className="img-fluid" alt="upload certificate"  />
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}
export default Education