import React, { Component } from "react";
import "./chat.css";
import user from "./profile.png";
import companyLogo from "./company.jpeg";
import { Link } from "react-router-dom";
import Axios from "axios";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "https://www.digitizedresume.com/api/chat/admin/",
      userType: "company",
      data: [],
      dataStatus: "false",
      userProfile: [],
      userStatus: [],
      uniqueId: [],
    };
  }

  componentDidMount() {
    if (!sessionStorage.getItem("auniqueId")) {
      window.location.href = "/login";
    }
    this.getData(this.state.userType);
  }

  getData = (val) => {
    let formData = new FormData();
    formData.append("userType", val);
    var url = this.state.url + "getAllUser_and_company.php";
    Axios.post(url, formData)
      .then((res) => {
        this.setState({
          data: res.data,
          dataStatus: "true",
        });
        //console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  changeUserType = (e) => {
    if (this.state.userType == "user") {
      this.getData("company");
      this.setState({ userType: "company", dataStatus: "false" });
    } else {
      this.getData("user");
      this.setState({ userType: "user", dataStatus: "false" });
    }
  };

  startChat = (id, name, profile, status, type) => {
    var aId = sessionStorage.getItem("auniqueId"); //company unique id from sessionStorage
    sessionStorage.setItem("outgoing_uniqueId", aId); //setting company unique id as outgoing
    sessionStorage.setItem("incoming_uniqueId", id);
    sessionStorage.setItem("incoming_name", name);
    sessionStorage.setItem("incoming_profile", profile);
    sessionStorage.setItem("incoming_status", status);
    sessionStorage.setItem("incoming_type", type); //setting user unique id as incoming
  };

  render() {
    return (
      <div className="row border">
        <div className="col-md-4 chat_profile border-right">
          <div className="row">
            <div className="col-md-7 p-2 chat_heading">
              <h4>Chats</h4>
            </div>
            <div className="col-md-3 p-2 chat_heading">
              <button
                onClick={this.changeUserType}
                className="btn btn-outline-dark"
                style={{ fontSize: "14px", borderRadius: "5px" }}
              >
                {this.state.userType === "company" ? "Company" : "User"}
              </button>
            </div>
            <div className="col-md-12 profiles">
              {this.state.userType === "company" &&
              this.state.dataStatus === "true" ? (
                this.state.data.map((ele, i) => {
                  return (
                    <Link
                      onClick={() =>
                        this.startChat(
                          ele.uniqueId,
                          ele.name,
                          ele.logo,
                          ele.onlineStatus,
                          "company"
                        )
                      }
                      to="/conversation"
                      className="link"
                    >
                      <div className="row sideBar-body mt-3" id="active">
                        <div className="col-sm-3 col-3 text-center sideBar-avatar">
                          <div className="avatar-icon">
                            <img
                              src={
                                ele.logo != null
                                  ? "https://www.digitizedresume.com/api/company/assets/profile/" +
                                    ele.logo
                                  : companyLogo
                              }
                              alt="pic"
                            />
                          </div>
                        </div>
                        <div className="col-sm-9 col-9 sideBar-main">
                          <div className="row">
                            <div className="col-sm-8 col-8 sideBar-name">
                              <span className="name-meta">
                                {ele.name.split(" ")[0]+' ...'}
                              </span>
                            </div>
                            <div className="col-sm-4 col-4 pull-right sideBar-time">
                              <span className="time-meta pull-right">
                                {ele.onlineStatus == "Online" ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Online
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      color: "grey",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Offline
                                  </span>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })
              ) : this.state.userType === "user" &&
                this.state.dataStatus === "true" ? (
                this.state.data.map((ele, i) => {
                  return (
                    <Link
                      onClick={() =>
                        this.startChat(
                          ele.uniqueId,
                          ele.name,
                          ele.profileImage,
                          ele.onlineStatus,
                          "user"
                        )
                      }
                      to="/conversation"
                      className="link"
                    >
                      <div className="row sideBar-body mt-3" id="active">
                        <div className="col-sm-3 col-3 text-center sideBar-avatar">
                          <div className="avatar-icon">
                            <img
                              src={
                                ele.profileImage != null
                                  ? "https://www.digitizedresume.com/api/user/assets/profile/" +
                                    ele.profileImage
                                  : user
                              }
                              alt="pic"
                            />
                          </div>
                        </div>
                        <div className="col-sm-9 col-9 sideBar-main">
                          <div className="row">
                            <div className="col-sm-8 col-8 sideBar-name">
                              <span className="name-meta">
                                {ele.name}
                              </span>
                            </div>
                            <div className="col-sm-4 col-4 pull-right sideBar-time">
                              <span className="time-meta pull-right">
                                {ele.onlineStatus == "Online" ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Online
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      color: "grey",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Offline
                                  </span>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })
              ) : (
                <p>Loading...</p>
              )}

              {/* profile section start */}
              {/* <Link to="/conversation" className="link">
                   
                    <div className="row sideBar-body mt-3" id="active">
                      <div className="col-sm-3 col-3 text-center sideBar-avatar">
                        <div className="avatar-icon">
                          <img src={user} alt="pic" />
                        </div>
                      </div>
                      <div className="col-sm-9 col-9 sideBar-main">
                        <div className="row">
                          <div className="col-sm-8 col-8 sideBar-name">
                            <span className="name-meta">John Doe</span>
                          </div>
                          <div className="col-sm-4 col-4 pull-right sideBar-time">
                            <span className="time-meta pull-right">18:18</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                   */}
              {/* profile section end */}
            </div>
          </div>
        </div>

        <div className="col-md-8 bg-white main_banner_heading">
          <div className="row">
            <div className="col-md-12 p-3 main_banner_heading border-bottom">
              <h6>Chat</h6>
            </div>

            <div className="col-md-12 main_banner">
              {/* <p> Tap on profile to chat conversation </p> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Chat;
