import React, { Component } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { Trans } from "react-i18next";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/notification", state: "notificationPagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }
  render() {
    return (
      <div>
        {sessionStorage.getItem("aId") ? (
          <>
            <nav className="sidebar sidebar-offcanvas shadow" id="sidebar">
              <div
                className="text-center sidebar-brand-wrapper d-flex align-items-center"
                style={{ backgroundColor: "#284074" }}
              >
                <a
                  className="sidebar-brand brand-logo"
                  href="index.html"
                  style={{ textDecoration: "none" }}
                >
                  {" "}
                  <span style={{ color: "white", fontSize: "20px" }}>
                    Admin Dashboard{" "}
                  </span>
                </a>
                <a
                  className="sidebar-brand brand-logo-mini pt-3"
                  style={{
                    color: "#fafafa",
                    fontWeight: "600px",
                    fontSize: "18px",
                    textDecoration: "none",
                  }}
                  href="index.html"
                >
                  Home
                </a>
              </div>
              <ul className="nav">
                <li
                  className={
                    this.isPathActive("/dashboard")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <NavLink className="nav-link" to="/dashboard">
                    <i className="mdi mdi-television menu-icon"></i>
                    <span className="menu-title">
                      <Trans>Home</Trans>
                    </span>
                  </NavLink>
                </li>

                <li
                  className={
                    this.isPathActive("/exploreprofile") || this.isPathActive("/user_profile")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <NavLink className="nav-link" to="/exploreprofile">
                    <i className="mdi mdi-account-card-details menu-icon"></i>
                    <span className="menu-title">Explore Profile</span>
                  </NavLink>
                </li>

                <li
                  className={
                    this.isPathActive("/sortlisted") ||
                      this.isPathActive("/schedule-interview")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <NavLink className="nav-link" to="/sortlisted">
                    <i className="mdi mdi-sort menu-icon"></i>
                    <span className="menu-title">Shortlisted Profile</span>
                  </NavLink>
                </li>

                <li
                  className={
                    this.isPathActive("/view-scheduled-interview") ||
                      this.isPathActive("/view-single-scheduled-interview")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <NavLink className="nav-link" to="/view-scheduled-interview">
                    <i className="mdi mdi-account-switch menu-icon"></i>
                    <span className="menu-title">Scheduled Interview</span>
                  </NavLink>
                </li>

                <li
                  className={
                    this.isPathActive("/hired") ? "nav-item active" : "nav-item"
                  }
                >
                  <NavLink className="nav-link" to="/hired">
                    <i className="mdi mdi-account-star menu-icon"></i>
                    <span className="menu-title">Hired Profile</span>
                  </NavLink>
                </li>

                <li
                  className={
                    this.isPathActive("/jobs") ||
                      this.isPathActive("/view-applied-job-details") ||
                      this.isPathActive("/view-single-job")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <NavLink className="nav-link" to="/jobs">
                    <i className="mdi mdi-briefcase menu-icon"></i>
                    <span className="menu-title">
                      <Trans>Jobs</Trans>
                    </span>
                  </NavLink>
                </li>

                <li
                  className={
                    this.isPathActive("/requested-project")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <NavLink className="nav-link" to="/requested-project">
                    <i className="mdi mdi-pandora menu-icon"></i>
                    <span className="menu-title">
                      <Trans>Requested Project</Trans>
                    </span>
                  </NavLink>
                </li>
                <li
                  className={
                    this.isPathActive("/leads") ? "nav-item active" : "nav-item"
                  }
                >
                  <NavLink className="nav-link" to="/leads">
                    <i className="mdi mdi-cellphone-basic menu-icon"></i>
                    <span className="menu-title">
                      <Trans>Leads</Trans>
                    </span>
                  </NavLink>
                </li>

                {/* <li
                  className={
                    this.isPathActive("/chat") ? "nav-item active" : "nav-item"
                  }
                >
                  <NavLink className="nav-link" to="/chat">
                    <i className="mdi mdi-comment-processing-outline menu-icon"></i>
                    <span className="menu-title">
                      <Trans>Chat</Trans>
                    </span>
                  </NavLink>
                </li> */}

                <li
                  className={
                    this.isPathActive("/category") ||
                      this.isPathActive("/video") ||
                      this.isPathActive("/topic")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <div
                    className={
                      this.state.videoPagesMenuOpen
                        ? "nav-link menu-expanded"
                        : "nav-link"
                    }
                    onClick={() => this.toggleMenuState("videoPagesMenuOpen")}
                    data-toggle="collapse"
                  >
                    <i className="mdi mdi-play-circle menu-icon"></i>
                    <span className="menu-title">
                      <Trans>Career Guidelines</Trans>
                    </span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.videoPagesMenuOpen}>
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        {" "}
                        <NavLink
                          className={
                            this.isPathActive("/category")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/category"
                        >
                          <Trans>Category</Trans>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <NavLink
                          className={
                            this.isPathActive("/topic")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/topic"
                        >
                          <Trans>Topic</Trans>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <NavLink
                          className={
                            this.isPathActive("/video")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/video"
                        >
                          <Trans>Video</Trans>
                        </NavLink>
                      </li>
                    </ul>
                  </Collapse>
                </li>

                <li
                  className={
                    this.isPathActive("/emailbroadcast")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <NavLink className="nav-link" to="/emailbroadcast">
                    <i className="mdi mdi-email menu-icon"></i>
                    <span className="menu-title">
                      <Trans>Email Broadcast</Trans>
                    </span>
                  </NavLink>
                </li>

                <li
                  className={
                    this.isPathActive("/user") ? "nav-item active" : "nav-item"
                  }
                >
                  <div
                    className={
                      this.state.basicUiMenuOpen
                        ? "nav-link menu-expanded"
                        : "nav-link"
                    }
                    onClick={() => this.toggleMenuState("basicUiMenuOpen")}
                    data-toggle="collapse"
                  >
                    <i className="mdi mdi-human-male-female menu-icon"></i>
                    <span className="menu-title">
                      <Trans>User</Trans>
                    </span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.basicUiMenuOpen}>
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        {" "}
                        <NavLink
                          className={
                            this.isPathActive("/user-table")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/user-table"
                        >
                          <Trans>View</Trans>
                        </NavLink>
                      </li>
                      {/* <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link' } to="/basic-ui/dropdowns"><Trans>Update</Trans></Link></li> */}
                    </ul>
                  </Collapse>
                </li>

                <li
                  className={
                    this.isPathActive("/company")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <div
                    className={
                      this.state.iconsMenuOpen
                        ? "nav-link menu-expanded"
                        : "nav-link"
                    }
                    onClick={() => this.toggleMenuState("iconsMenuOpen")}
                    data-toggle="collapse"
                  >
                    <i className="mdi mdi-castle menu-icon"></i>
                    <span className="menu-title">
                      <Trans>Company</Trans>
                    </span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.iconsMenuOpen}>
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        {" "}
                        <NavLink
                          className={
                            this.isPathActive("/company")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/company"
                        >
                          View
                        </NavLink>
                      </li>
                    </ul>
                  </Collapse>
                </li>

                <li
                  className={
                    this.isPathActive("/add_notification") ||
                      this.isPathActive("/view_notification")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <div
                    className={
                      this.state.notificationPagesMenuOpen
                        ? "nav-link menu-expanded"
                        : "nav-link"
                    }
                    onClick={() =>
                      this.toggleMenuState("notificationPagesMenuOpen")
                    }
                    data-toggle="collapse"
                  >
                    <i className="mdi mdi-bell menu-icon"></i>
                    <span className="menu-title">
                      <Trans>Notification</Trans>
                    </span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.notificationPagesMenuOpen}>
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        {" "}
                        <NavLink
                          className={
                            this.isPathActive("/add_notification")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/add_notification"
                        >
                          <Trans>Add</Trans>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <NavLink
                          className={
                            this.isPathActive("/view_notification")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/view_notification"
                        >
                          <Trans>View</Trans>
                        </NavLink>
                      </li>
                    </ul>
                  </Collapse>
                </li>

                <li
                  className={
                    this.isPathActive("/invoice")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <NavLink className="nav-link" to="/invoice">
                    <i className="mdi mdi-cash-multiple menu-icon"></i>
                    <span className="menu-title">
                      <Trans>Invoice</Trans>
                    </span>
                  </NavLink>
                </li>

                <li
                  className={
                    this.isPathActive("/coupon") ||
                      this.isPathActive("/view-coupon") ||
                      this.isPathActive("/edit-coupon")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <div
                    className={
                      this.state.couponPagesMenuOpen
                        ? "nav-link menu-expanded"
                        : "nav-link"
                    }
                    onClick={() => this.toggleMenuState("couponPagesMenuOpen")}
                    data-toggle="collapse"
                  >
                    <i className="mdi mdi-gift menu-icon menu-icon"></i>
                    <span className="menu-title">
                      <Trans>Coupon</Trans>
                    </span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.couponPagesMenuOpen}>
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        {" "}
                        <NavLink
                          className={
                            this.isPathActive("/coupon")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/coupon"
                        >
                          <Trans>Create Coupon</Trans>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <NavLink
                          className={
                            this.isPathActive("/view-coupon")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/view-coupon"
                        >
                          <Trans>View Coupon</Trans>
                        </NavLink>
                      </li>
                    </ul>
                  </Collapse>
                </li>
                <li
                  className={
                    this.isPathActive("/add_admin") ||
                      this.isPathActive("/view_admin") ||
                      this.isPathActive("/admin-single-view")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <div
                    className={
                      this.state.userPagesMenuOpen
                        ? "nav-link menu-expanded"
                        : "nav-link"
                    }
                    onClick={() => this.toggleMenuState("userPagesMenuOpen")}
                    data-toggle="collapse"
                  >
                    <i className="mdi mdi-account-circle menu-icon"></i>
                    <span className="menu-title">
                      <Trans>Admin</Trans>
                    </span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.userPagesMenuOpen}>
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        {" "}
                        <NavLink
                          className={
                            this.isPathActive("/add_admin")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/add_admin"
                        >
                          <Trans>Add</Trans>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <NavLink
                          className={
                            this.isPathActive("/view_admin")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/view_admin"
                        >
                          <Trans>View</Trans>
                        </NavLink>
                      </li>
                    </ul>
                  </Collapse>
                </li>

                <li className="nav-item">
                  <p className="nav-link">
                    {/* this is for cover below space of nav */}
                  </p>
                </li>

                {/* <li className={ this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item' }>
                    <div className={ this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">
                      <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                      <span className="menu-title"><Trans>User</Trans></span>
                      <i className="menu-arrow"></i>
                    </div>
                    <Collapse in={ this.state.basicUiMenuOpen }>
                      <ul className="nav flex-column sub-menu">
                        <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link' } to="/basic-ui/buttons"><Trans>View</Trans></Link></li>
                        <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link' } to="/basic-ui/dropdowns"><Trans>Update</Trans></Link></li>
                      </ul>
                    </Collapse>
                  </li> */}

                {/* <li className={ this.isPathActive('/icons') ? 'nav-item active' : 'nav-item' }>
                    <div className={ this.state.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('iconsMenuOpen') } data-toggle="collapse">
                      <i className="mdi mdi-account-box-outline menu-icon"></i>
                      <span className="menu-title"><Trans>Icons</Trans></span>
                      <i className="menu-arrow"></i>
                    </div>
                    <Collapse in={ this.state.iconsMenuOpen }>
                      <ul className="nav flex-column sub-menu">
                        <li className="nav-item"> <Link className={ this.isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link' } to="/icons/mdi">Material</Link></li>
                      </ul>
                    </Collapse>
                  </li>
                   */}
                {/* <li className={ this.isPathActive('/form-elements') ? 'nav-item active' : 'nav-item' }>
                    <div className={ this.state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('formElementsMenuOpen') } data-toggle="collapse">
                      <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                      <span className="menu-title"><Trans>Form Elements</Trans></span>
                      <i className="menu-arrow"></i>
                    </div>
                    <Collapse in={ this.state.formElementsMenuOpen }>
                      <ul className="nav flex-column sub-menu">
                        <li className="nav-item"> <Link className={ this.isPathActive('/form-elements/basic-elements') ? 'nav-link active' : 'nav-link' } to="/form-elements/basic-elements"><Trans>View</Trans></Link></li>
                      </ul>
                    </Collapse>
                  </li> */}
                {/* <li className={ this.isPathActive('/tables') ? 'nav-item active' : 'nav-item' }>
                    <div className={ this.state.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('tablesMenuOpen') } data-toggle="collapse">
                      <i className="mdi mdi-table-large menu-icon"></i>
                      <span className="menu-title"><Trans>Tables</Trans></span>
                      <i className="menu-arrow"></i>
                    </div>
                    <Collapse in={ this.state.tablesMenuOpen }>
                      <ul className="nav flex-column sub-menu">
                        <li className="nav-item"> <Link className={ this.isPathActive('/tables/basic-table') ? 'nav-link active' : 'nav-link' } to="/tables/basic-table"><Trans>Basic Table</Trans></Link></li>
                      </ul>
                    </Collapse>
                  </li> */}

                {/* <li className={ this.isPathActive('/charts') ? 'nav-item active' : 'nav-item' }>
                    <div className={ this.state.chartsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('chartsMenuOpen') } data-toggle="collapse">
                      <i className="mdi mdi-chart-line menu-icon"></i>
                      <span className="menu-title"><Trans>Charts</Trans></span>
                      <i className="menu-arrow"></i>
                    </div>
                    <Collapse in={ this.state.chartsMenuOpen }>
                      <ul className="nav flex-column sub-menu">
                        <li className="nav-item"> <Link className={ this.isPathActive('/charts/chart-js') ? 'nav-link active' : 'nav-link' } to="/charts/chart-js">Chart Js</Link></li>
                      </ul>
                    </Collapse>
                  </li> */}
                {/* <li className={ this.isPathActive('/user-pages') ? 'nav-item active' : 'nav-item' }>
                    <div className={ this.state.userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('userPagesMenuOpen') } data-toggle="collapse">
                      <i className="mdi mdi-lock-outline menu-icon"></i>
                      <span className="menu-title"><Trans>User Pages</Trans></span>
                      <i className="menu-arrow"></i>
                    </div>
                    <Collapse in={ this.state.userPagesMenuOpen }>
                      <ul className="nav flex-column sub-menu">
                        <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/login-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/login-1"><Trans>Login</Trans></Link></li>
                        <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/register-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/register-1"><Trans>Register</Trans></Link></li>
                      </ul>
                    </Collapse>
                  </li> */}
                {/* <li className={ this.isPathActive('/error-pages') ? 'nav-item active' : 'nav-item' }>
                    <div className={ this.state.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('errorPagesMenuOpen') } data-toggle="collapse">
                      <i className="mdi mdi-information-outline menu-icon"></i>
                      <span className="menu-title"><Trans>Error Pages</Trans></span>
                      <i className="menu-arrow"></i>
                    </div>
                    <Collapse in={ this.state.errorPagesMenuOpen }>
                      <ul className="nav flex-column sub-menu">
                        <li className="nav-item"> <Link className={ this.isPathActive('/error-pages/error-404') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-404">404</Link></li>
                        <li className="nav-item"> <Link className={ this.isPathActive('/error-pages/error-500') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-500">500</Link></li>
                      </ul>
                    </Collapse>
                  </li> */}
              </ul>
            </nav>
          </>
        ) : (
          false
        )}
      </div>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
