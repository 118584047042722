import React, { Component } from 'react';
import './notification.css';
import { MDBDataTable } from "mdbreact";
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';
import Pagination from "@material-ui/lab/Pagination";
import swal from 'sweetalert';

class NotificationView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "https://www.digitizedresume.com/api/admin/",
      dataStatus: "false",
      data: [],
      page: 1,
      totalCol: 0,
    };
  }

  handlePagination = (e, val) => {
    if (this.state.page != val) {
      if (val > this.state.page) {
        this.setState({
          page: val,
          totalCol: val * 5 - 5,
        });
      } else {
        this.setState({
          page: val,
          totalCol: val * 5 - 5,
        });
      }
    }
  };

  componentDidMount() {
    if (!sessionStorage.getItem("aId")) {
      window.location.href = "/login";
    }
    var url = this.state.url + "getNotificationData.php";
    Axios.get(url)
      .then((res) => {
        this.setState({
          data: res.data,
          dataStatus: "true",
        });
        // console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  edit = (e) => {
    e.preventDefault();
    // alert(e.target.value);
    sessionStorage.setItem("notificationId", e.target.value);
  };

  delete = (e) => {
    e.preventDefault();
    var id = e.target.value;

    swal({
      title: "Are you sure?",
      text: "Once removed, you won't be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          let formData = new FormData();
          formData.append("id", id);
          var url = this.state.url + "deleteNotificationData.php";

          Axios.post(url, formData)
            .then((res) => {
              this.setState({
                dataStatus: "false",
              });
              swal({
                title: "Deleted!",
                text: "Notification deleted successfully!",
                button: "Close",
                icon: "success",
                timer: 3000,
                button: {
                  confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                  }
                }
              });
              var url = this.state.url + "getNotificationData.php";
              Axios.get(url)
                .then((res) => {
                  this.setState({
                    dataStatus: "true",
                    data: res.data,
                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
            .catch((err) => {
              // console.log(err);
              this.setState({
                res: err,
              });
            });
        }
      });
  };

  render() {
    return (
      <>
        <div>
          <div className="page-header">
            <h3 className="page-title">Notification</h3>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="!#" onClick={(event) => event.preventDefault()}>
                    Notification
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  All Notifications
                </li>
              </ol>
            </nav>
          </div>
          {this.state.dataStatus === "false" ? (
            <Spinner />
          ) : (
            <div className="row">
              <div
                className="col-md-12 grid-margin stretch-card bg-white shadow-sm"
                style={{ marginTop: "10px" }}
              >
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Notification Details</h4>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>SN.</th>
                            <th>Type</th>
                            <th>Notification</th>
                            <th>Added / Updated Date</th>
                            <th colSpan="2">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.length === 0 ? (
                            <tr>
                              <td colSpan="6" className="text-center">
                                No Records Found
                              </td>
                            </tr>
                          ) : (
                            this.state.data.map((ele, i) => {
                              if (
                                i < this.state.page * 5 &&
                                this.state.totalCol <= i
                              ) {
                                return (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td style={{ textTransform: "capitalize" }}>
                                      {ele.notificationType}
                                    </td>
                                    <td>{ele.notification}</td>
                                    <td>{ele.date}</td>

                                    <td>
                                      <div className="adminedit">
                                        <NavLink to="/edit_notification">
                                          <button
                                            className=""
                                            onClick={this.edit}
                                            value={ele.id}
                                          >
                                            <NavLink to="/edit_notification">
                                              <button
                                                className="mdi mdi-lead-pencil"
                                                value={ele.id}
                                                id="buttonPadding"
                                              ></button>
                                            </NavLink>
                                          </button>
                                        </NavLink>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="admindelete">
                                        <button
                                          className=""
                                          onClick={this.delete}
                                          className="mdi mdi-window-close"
                                          value={ele.id}
                                          id="buttonPadding"
                                        ></button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              }
                            })
                          )}
                        </tbody>
                      </table>
                      <Pagination
                        count={parseInt(this.state.data.length / 5) + 1}
                        page={this.state.page}
                        onChange={this.handlePagination}
                        color="primary"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
export default NotificationView;