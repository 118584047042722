import React, { Component } from "react";
import "./Exploreprofile.css";
import face from "./pic.png";
import { NavLink } from "react-router-dom";
import Axios from "axios";
import Spinner from "../../app/shared/Spinner";
import swal from 'sweetalert';
import ReactPlayer from 'react-player';

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: "https://www.digitizedresume.com/api/user/",
      url2: "https://www.digitizedresume.com/api/admin/",
      domain: "",
      name: "",
      phone: "",
      email: "",
      password: "",
      description: "",
      designation: "",
      availability: "",
      location: "",
      workInfo: "",
      profileImage: "",
      currentProfileImage: "",
      facebook: "",
      instagram: "",
      twitter: "",
      linkedIn: "",
      onlineCVStatus: "",
      userUniqueId: "",

      highestEducation: "",
      highestQualification: "",
      passingYear1: "",
      course1: "",
      courseType1: "",
      specialization1: "",
      percentage1: "",

      universityCollege2: "",
      qualification2: "",
      passingYear2: "",
      course2: "",
      courseType2: "",
      specialization2: "",
      percentage2: "",

      universityCollege3: "",
      qualification3: "",
      passingYear3: "",
      course3: "",
      courseType3: "",
      specialization3: "",
      percentage3: "",

      skill: [],
      portfolio: [],
      project: [],
      certificate: [],
      video: [],
      interest: [],
      language: [],
      pastExp: [],

      shortListedStatus: '',
      hiringStatus: '',

      dataStatus: "false",
    };
  }

  componentDidMount() {
    var adminId = sessionStorage.getItem("aId");
    var id = sessionStorage.getItem("userId");
    if (!id) {
      this.props.history.push('/exploreprofile');
    }
    else {
      let shortlistedFormData = new FormData();
      shortlistedFormData.append("id", id);
      shortlistedFormData.append("adminId", adminId);
      // console.log(obj)

      var shortlistedStatusUrl = this.state.url2 + "wishlistData.php";
      Axios.post(shortlistedStatusUrl, shortlistedFormData).then((res) => {
        // alert(res.data);
        if (res.data === 1) {
          this.setState({
            shortListedStatus: "status",
          });
        }
        else if (res.data === 0) {
          this.setState({
            shortListedStatus: "false",
          });
        }
      });

      var hiringStatusUrl = this.state.url2 + "hiringData.php";
      Axios.post(hiringStatusUrl, shortlistedFormData).then((res) => {
        // alert(res.data);
        if (res.data === 1) {
          this.setState({
            hiringStatus: "false",
          });
        }
        else if (res.data === 0) {
          this.setState({
            hiringStatus: "true",
          });
        }
      });

      var id = sessionStorage.getItem("userId");
      let formData = new FormData();
      formData.append("id", id);

      var skillUrl = this.state.url + "getSingleSkillsData.php";
      Axios.post(skillUrl, formData).then((res) => {
        // console.log(res.data);
        this.setState({
          skill: res.data,
        });
      });

      var languageUrl = this.state.url + "getLanguageData.php";
      Axios.post(languageUrl, formData).then((res) => {
        // console.log(res.data);
        this.setState({
          language: res.data,
        });
      });

      var pastExpUrl = this.state.url + "getPastWorkExperienceData.php";
      Axios.post(pastExpUrl, formData).then((res) => {
        // console.log(res.data);
        this.setState({
          pastExp: res.data,
        });
      });

      var interestUrl = this.state.url + "getInterestData.php";
      Axios.post(interestUrl, formData).then((res) => {
        // console.log(res.data);
        this.setState({
          interest: res.data,
        });
      });

      var portfolioUrl = this.state.url + "getPortfolioData.php";
      Axios.post(portfolioUrl, formData).then((res) => {
        // console.log(res.data);
        this.setState({
          portfolio: res.data,
        });
      });

      var projectUrl = this.state.url + "getProjectData.php";
      Axios.post(projectUrl, formData).then((res) => {
        // console.log(res.data);
        this.setState({
          project: res.data,
        });
      });

      var videoUrl = this.state.url + "getVideoData.php";
      Axios.post(videoUrl, formData).then((res) => {
        // console.log(res.data);
        this.setState({
          video: res.data,
        });
      });

      var certificateUrl = this.state.url + "getCertificateData.php";
      Axios.post(certificateUrl, formData).then((res) => {
        // console.log(res.data);
        this.setState({
          certificate: res.data,
        });
      });

      var url = this.state.url + "getData.php";
      Axios.post(url, formData).then((res) => {
        // console.log(res.data);
        this.setState({
          name: res.data[0].name,
          dob: res.data[0].dob,
          phone: res.data[0].phone,
          email: res.data[0].email,
          domain: res.data[0].domain,
          designation: res.data[0].designation,
          location: res.data[0].location,
          address: res.data[0].address,
          cv: res.data[0].cv,
          availability: res.data[0].availability,
          profileImage: res.data[0].profileImage,
          facebook: res.data[0].facebook,
          instagram: res.data[0].instagram,
          twitter: res.data[0].twitter,
          linkedIn: res.data[0].linkedIn,
          description: res.data[0].description,
          workInfo: res.data[0].workInfo,
          onlineCVStatus: res.data[0].onlineCVStatus,
          userUniqueId: res.data[0].uniqueId,

          currentDesignation: res.data[0].currentDesignation,
          currentadmin: res.data[0].currentadmin,
          startDate: res.data[0].startDate,
          endDate: res.data[0].endDate,
          role: res.data[0].role,

          highestEducation: res.data[0].highestEducation,
          highestQualification: res.data[0].highestQualification,
          passingYear1: res.data[0].passingYear1,
          course1: res.data[0].course1,
          courseType1: res.data[0].courseType1,
          specialization1: res.data[0].specialization1,
          percentage1: res.data[0].passPercentage1,

          universityCollege2: res.data[0].universityCollege2,
          qualification2: res.data[0].qualification2,
          passingYear2: res.data[0].passingYear2,
          course2: res.data[0].course2,
          courseType2: res.data[0].courseType2,
          specialization2: res.data[0].specialization2,
          percentage2: res.data[0].passPercentage2,

          universityCollege3: res.data[0].universityCollege3,
          qualification3: res.data[0].qualification3,
          passingYear3: res.data[0].passingYear3,
          course3: res.data[0].course3,
          courseType3: res.data[0].courseType3,
          specialization3: res.data[0].specialization3,
          percentage3: res.data[0].passPercentage3,
          dataStatus: "true",
        });
      });
    }
  }

  shortListedActive = (e) => {
    e.preventDefault();
    this.setState({
      dataStatus: "false"
    });
    var url = this.state.url2 + "wishlist.php";

    var userId = sessionStorage.getItem("userId");
    var userName = this.state.name;
    var adminId = sessionStorage.getItem("aId");
    var adminName = sessionStorage.getItem("adminName");

    let formData = new FormData();
    formData.append('userId', userId);
    formData.append('userName', userName);
    formData.append('adminId', adminId);
    formData.append('adminName', adminName);

    Axios.post(url, formData).then((res) => {
      // alert(res.data);
      this.setState({
        dataStatus: "true"
      });
      if (res.data === "Wishlisted Successfully") {
        swal({
          title: "Sortlisted!",
          text: "Profile Sortlisted!",
          button: "Close",
          icon: "success",
          timer: 3000,
          button: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "",
              closeModal: true
            }
          }
        });

        var adminId = sessionStorage.getItem("aId");
        var id = sessionStorage.getItem("userId");
        let shortlistedFormData = new FormData();
        shortlistedFormData.append("id", id);
        shortlistedFormData.append("adminId", adminId);
        // console.log(obj)

        var shortlistedStatusUrl = this.state.url2 + "wishlistData.php";
        Axios.post(shortlistedStatusUrl, shortlistedFormData).then((res) => {
          // alert(res.data);
          if (res.data === 1) {
            this.setState({
              shortListedStatus: "status",
            });
          }
          else if (res.data === 0) {
            this.setState({
              shortListedStatus: "false",
            });
          }
        });

        var hiringStatusUrl = this.state.url2 + "hiringData.php";
        Axios.post(hiringStatusUrl, shortlistedFormData).then((res) => {
          // alert(res.data);
          if (res.data === 1) {
            this.setState({
              hiringStatus: "false",
            });
          }
          else if (res.data === 0) {
            this.setState({
              hiringStatus: "true",
            });
          }
        });
      }
    });

  }

  hire = (e) => {


    e.preventDefault();
    this.setState({
      dataStatus: "false"
    });
    var aId = sessionStorage.getItem("aId");
    var id = sessionStorage.getItem("userId");
    let formData = new FormData();
    formData.append("id", id);
    formData.append("aId", aId);
    var hireUrl = this.state.url2 + "updateWishlistedProfile.php";

    Axios.post(hireUrl, formData).then((res) => {
      // alert(res.data);
      this.setState({
        dataStatus: "true"
      });
      if (res.data === "Updated") {
        swal({
          title: "Hired!",
          text: "User Hired!",
          button: "Close",
          icon: "success",
          timer: 3000,
          button: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "",
              closeModal: true
            }
          }
        });

        var adminId = sessionStorage.getItem("aId");
        var id = sessionStorage.getItem("userId");
        let shortlistedFormData = new FormData();
        shortlistedFormData.append("id", id);
        shortlistedFormData.append("adminId", adminId);
        // console.log(obj)

        var shortlistedStatusUrl = this.state.url2 + "wishlistData.php";
        Axios.post(shortlistedStatusUrl, shortlistedFormData).then((res) => {
          // alert(res.data);
          if (res.data === 1) {
            this.setState({
              shortListedStatus: "status",
            });
          }
          else if (res.data === 0) {
            this.setState({
              shortListedStatus: "false",
            });
          }
        });

        var hiringStatusUrl = this.state.url2 + "hiringData.php";
        Axios.post(hiringStatusUrl, shortlistedFormData).then((res) => {
          // alert(res.data);
          if (res.data === 1) {
            this.setState({
              hiringStatus: "false",
            });
          }
          else if (res.data === 0) {
            this.setState({
              hiringStatus: "true",
            });
          }
        });
      }
    });

  }

  goBack = (e) => {
    this.props.history.goBack();
  }


  render() {
    return (
      <div>
        {sessionStorage.getItem("aId") ? (
          <>
            <div className="page-header">
              <h3 className="page-title">Explore Profile</h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink to="/exploreprofile">Explore Profile</NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    User Details
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <a href="#" onClick={this.goBack}>Go Back</a>
                  </li>
                </ol>
              </nav>
            </div>

            {this.state.dataStatus === "false" ? (
              <Spinner />
            ) : (
              <>
                <div class="row User_profile ">
                  {this.state.shortListedStatus === "false" ? (
                    <div className="col-md-12 mb-2">
                      <div className="cardButtonRow float-right">
                        <button className="cardButton2" onClick={this.shortListedActive}>Shortlist</button>
                      </div>
                    </div>
                  ) : (
                    <>
                      {this.state.hiringStatus === "false" ? (
                        <div className="col-md-12 mb-2">
                          <NavLink className="text-center mx-auto" to="/view-applied-job-details"><button className="btn btn-sm btn-danger"><b>Go Back</b></button></ NavLink>
                          <div className="cardButtonRow float-right">
                            <button className="cardButton2" onClick={this.hire}>Hire</button>
                            <span className="text-success">&nbsp; Already Shortlisted!</span>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-12 mb-2">
                          <div className="cardButtonRow float-right">
                            <span className="text-success">&nbsp; Already Hired!</span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <div className="col-md-3 side_profile text-center">
                    <div className="user_image">
                      {(() => {
                        if (this.state.profileImage === null) {
                          return (
                            <img
                              src={face}
                              alt={this.state.name}
                              className="img-fluid"
                            />
                          );
                        } else {
                          return (
                            <img
                              className="img-fluid"
                              src={
                                "https://www.digitizedresume.com/api/user/assets/profile/" +
                                this.state.profileImage
                              }
                              className="img-fluid"
                              alt={this.state.name}
                            />
                          );
                        }
                      })()}
                      <h5>{this.state.name}</h5>
                      <h6>{this.state.designation}</h6>
                      <h6>{"ID: " + this.state.userUniqueId}</h6>
                      <p className="text-white">D.O.B: {this.state.dob}</p>
                      <p className="text-white">
                        Address: {this.state.address}
                      </p>

                      {this.state.onlineCVStatus === "Active" ? (<a target="_blank" className="text-danger" href={"https://www.digitizedresume.com/" + this.state.domain} ><h5 className="text-danger bg-white py-2">View Digital CV</h5></a>) : (false)}

                      {this.state.cv ? (<a target="_blank" className="text-danger" href={"https://www.digitizedresume.com/api/user/assets/cv/" + this.state.cv} download><h5 className="text-danger bg-white py-2">Download CV</h5></a>) : (false)}

                    </div>

                    <div className="side_details pt-4 text-left">
                      <i className="mdi mdi-certificate text-danger"></i>{" "}
                      <span className="text-danger">Skills</span>
                      <div className="exprience_details">
                        <ul>
                          {this.state.skill.map((ele, i) => {
                            return (
                              <>
                                <div class="col-md-12 skill_sec">
                                  <li
                                    className="text-white"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {ele.name} — {ele.rating}
                                  </li>
                                </div>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                      <br />
                      <i className="mdi mdi-certificate text-danger mt-3"></i>{" "}
                      <span className="text-danger">Interest</span>
                      <div className="exprience_details">
                        <ul>
                          {this.state.interest.map((ele, i) => {
                            return (
                              <>
                                <div class="col-md-12 skill_sec">
                                  <li
                                    className="text-white"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {ele.interest}
                                  </li>
                                </div>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                      <br />
                      <i className="mdi mdi-certificate text-danger mt-3"></i>{" "}
                      <span className="text-danger">Known Languages</span>
                      <div className="exprience_details">
                        <ul>
                          {this.state.language.map((ele, i) => {
                            return (
                              <>
                                <div class="col-md-12 skill_sec">
                                  <li
                                    className="text-white"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {ele.language}
                                  </li>
                                </div>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-9 pt-5 pl-5 pb-4 profile___details">
                    <div className="right_profile">
                      <i className="mdi mdi-account"></i> <span>Profile</span>
                      <p className="mt-2"> {this.state.description}</p>
                    </div>

                    <div className="right_exprience mt-4">
                      <i className="mdi mdi-certificate"></i>{" "}
                      <span>Latest Exprience</span>
                      <div className="exprience_details">
                        {this.state.currentadmin !== "" || this.state.currentadmin !== null ? (
                          <>
                            <h6>{this.state.currentDesignation} - {this.state.currentadmin}</h6>
                            <span>{this.state.startDate} - {this.state.endDate === "" || this.state.endDate === null ? ("Present") : (this.state.endDate)}</span>
                            <br></br>
                            <br></br>
                            <p>{this.state.role}</p>
                          </>
                        ) : (false)}
                        <br />
                        <i className="mdi mdi-certificate"></i>{" "}
                        <span>Past Exprience</span>
                        <ul>
                          {this.state.pastExp.map((ele, i) => {
                            return (
                              <>
                                <div className="col-md-12 skill_sec">
                                  <li>
                                    <h6>
                                      {ele.pastDesignation} - {ele.pastadmin}
                                    </h6>
                                    <span>
                                      {ele.pastStartDate} - {ele.pastEndDate}
                                    </span>
                                    <br />
                                    <p>{ele.pastRole}</p>
                                  </li>
                                </div>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </div>

                    <div className="right_education mt-5">
                      <i className="mdi mdi-book-open-page-variant"></i>{" "}
                      <span>Education</span>
                      <div className="education_details border-bottom mt-3">
                        {(() => {
                          if (
                            this.state.highestEducation !== "null" &&
                            this.state.highestEducation !== null &&
                            this.state.highestEducation !== ""
                          ) {
                            return (
                              <>
                                <h4>{this.state.highestEducation}</h4>
                                <h6>Qualification : {this.state.highestQualification}</h6>
                                <span>Passing Year : {this.state.passingYear1}</span><br />
                                <p>Specialization : {this.state.specialization1}</p>
                              </>
                            );
                          }
                        })()}
                      </div>
                      <div className="education_details border-bottom mt-4">
                        {(() => {
                          if (
                            this.state.course2 !== "null" &&
                            this.state.course2 !== null &&
                            this.state.course2 !== ""
                          ) {
                            return (
                              <>
                                <h4>{this.state.course2}</h4>
                                <h6>Qualification : {this.state.qualification2}</h6>
                                <span>Passing Year : {this.state.passingYear2}</span><br />
                                <p>Specialization : {this.state.specialization2}</p>
                              </>
                            );
                          }
                        })()}
                      </div>
                      <div className="education_details border-bottom mt-4">
                        {(() => {
                          if (
                            this.state.qualification3 !== "null" &&
                            this.state.qualification3 !== null &&
                            this.state.qualification3 !== ""
                          ) {
                            return (
                              <>
                                <h4>{this.state.qualification3}</h4>
                                <span>Passing Year : {this.state.passingYear3}</span><br />
                                <p>Specialization : {this.state.specialization3}</p>
                              </>
                            );
                          }
                        })()}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-4">
                    <h4 className="text-danger">
                      {this.state.portfolio.length !== 0 ? "Portfolios" : ""}
                    </h4>
                  </div>
                  {this.state.portfolio.map((ele, i) => {
                    return (
                      <>
                        <div class="col-md-4 mt-3 resume_card Shortlisted">
                          <div class="card-body bg-white shadow-sm">
                            <a target="_blank" href={"https://www.digitizedresume.com/api/user/assets/portfolio/" + ele.file}>
                              <img class="img-fluid cardImage" src={"https://www.digitizedresume.com/api/user/assets/portfolio/" + ele.file} alt={ele.title} />
                            </a>
                          </div>
                          <div class="card-footer bg-white pt-0">
                            <h5>
                              <br />
                              {ele.title}
                              <br />
                            </h5>
                            <p>{ele.description}</p>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>

                <div className="row">
                  <div className="col-md-12 mt-4">
                    <h4 className="text-danger">
                      {this.state.certificate.length != 0
                        ? " Certificates / Awards"
                        : ""}
                    </h4>
                  </div>
                  {this.state.certificate.map((ele, i) => {
                    return (
                      <>
                        <div class="col-md-4 mt-3 resume_card Shortlisted">
                          <div class="card-body bg-white shadow-sm">
                            <a
                              target="_blank"
                              href={
                                "https://www.digitizedresume.com/api/user/assets/certificate/" +
                                ele.file
                              }
                            >
                              <img
                                class="img-fluid cardImage"
                                src={
                                  "https://www.digitizedresume.com/api/user/assets/certificate/" +
                                  ele.file
                                }
                                alt={ele.certificateName}
                              />
                            </a>
                          </div>
                          <div class="card-footer bg-white pt-0">
                            <h5>
                              <br />
                              {ele.certificateName}
                              <br />
                            </h5>
                            <p>{ele.description}</p>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>

                <div className="row">
                  <div className="col-md-12 mt-4">
                    <h4 className="text-danger">
                      {this.state.video.length != 0
                        ? " Videos"
                        : ""}
                    </h4>
                  </div>
                  {this.state.video.map((ele, i) => {
                    return (
                      <>

                        {ele.fileType === "URL" ? (
                          <div class="col-md-4 mt-3 resume_card Shortlisted">
                            <div class="card-body bg-white shadow-sm">
                              <ReactPlayer
                                width="100%"
                                height="100%"
                                controls
                                allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
                                allowfullscreen
                                url={ele.file}
                              />
                            </div>
                            <div class="card-footer bg-white pt-0">
                              <h5>
                                <br />
                                {ele.title}
                                <br />
                              </h5>
                              <p>{ele.description}</p>
                            </div>
                          </div>) :
                          (
                            <div class="col-md-4 mt-3 resume_card Shortlisted">
                              <div class="card-body bg-white shadow-sm">
                                <ReactPlayer
                                  width="100%"
                                  height="100%"
                                  controls
                                  allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
                                  allowfullscreen
                                  url={"https://www.digitizedresume.com/api/user/assets/video/" + ele.file}
                                />
                              </div>
                              <div class="card-footer bg-white pt-0">
                                <h5>
                                  <br />
                                  {ele.title}
                                  <br />
                                </h5>
                                <p>{ele.description}</p>
                              </div>
                            </div>
                          )
                        }
                      </>
                    );
                  })}
                </div>
              </>
            )}
          </>
        ) : (
          false
        )}
      </div>
    );
  }
}
export default UserProfile;
