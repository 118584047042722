import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './interview.css';
import Axios from 'axios';
import Spinner from '../shared/Spinner';
import swal from 'sweetalert';

class ScheduleInterview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            dataStatus: "false",
            interviewDescription: '',
            startTime: '',
            duration: '',
            interviewLink: '',
            interviewDate: '',

        };
    }

    handle = (e) => {
        e.preventDefault();
        if (e.target.value.trim() === "") {
            e.target.value = "";
        }

        this.setState({ [e.target.name]: e.target.value });
    }
    submit = (e) => {
        e.preventDefault();
        if (this.state.interviewDescription === "" || this.state.startTime === "" || this.state.duration === "" || this.state.interviewLink === "" || this.state.interviewDate === "") {
            swal({
                title: "Error!",
                text: "Please fill all the fields of form before submitting it!",
                button: "Close",
                icon: "error",
                timer: 3000,
                 button: {
                   confirm: {
                     text: "OK",
                     value: true,
                     visible: true,
                     className: "",
                     closeModal: true
                   }
                 }
                });
        }
        else {
            document.getElementsByClassName("fa-spin")[0].classList.add("fa-refresh");
            document.getElementById("scheduleInterviewSubmitButton").disabled = true;
            var aId = sessionStorage.getItem("aId");
            var adminName = sessionStorage.getItem("adminName");
            let formData = new FormData();
            formData.append("adminId", sessionStorage.getItem("aId"));
            formData.append("userId", sessionStorage.getItem("userId"));
            formData.append("userName", sessionStorage.getItem("userName"));
            formData.append("interviewDescription", this.state.interviewDescription);
            formData.append("startTime", this.state.startTime);
            formData.append("duration", this.state.duration);
            formData.append("interviewLink", this.state.interviewLink);
            formData.append("interviewDate", this.state.interviewDate);
            var url = this.state.url + "scheduleInterview.php";
            Axios.post(url, formData).then((res) => {
                // alert(res.data);
                document.getElementById("scheduleInterviewForm").reset();
                document.getElementsByClassName("fa-spin")[0].classList.remove("fa-refresh");
                document.getElementById("scheduleInterviewSubmitButton").disabled = false;
                swal({
                    title: "Scheduled!",
                    text: "Interview Scheduled!",
                    button: "Close",
                    icon: "success",
                    timer: 3000,
                     button: {
                       confirm: {
                         text: "OK",
                         value: true,
                         visible: true,
                         className: "",
                         closeModal: true
                       }
                     }
                    }).then(() => {
                        sessionStorage.removeItem("interviewStatus");
                        this.props.history.goBack();
                      });
            });
        }
    }

    removeInterviewScheduleStatus = (e) => {
        sessionStorage.removeItem("interviewStatus");
    }

    render() {
        return (
            <>
                <div className="page-header">
                    <h3 className="page-title">Schedule Interview</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Interview</a></li>

                        </ol>
                    </nav>
                </div>

                {sessionStorage.getItem("interviewStatus") ? 
                    <div className="row">
                        <form onSubmit={this.submit} id="scheduleInterviewForm">
                            <div className="col-md-10 mt-3 mx-auto">

                                <div className="card">
                                    <div className="card-header bg-white">
                                        <h5 className="pt-2"><b>Schedule Interview</b></h5>
                                    </div>
                                    <div className="card-body m-0 pt-1">
                                        <div className="row">
                                        <div className="col-md-12 mt-3">
                                                <label>Interview Description</label>
                                                <textarea
                                                    className="form-control form-control-sm"
                                                    placeholder="Enter Interview Description"
                                                    style={{ height: "80px" }}
                                                    onChange={this.handle}
                                                    name="interviewDescription"
                                                    id="interviewDescription"
                                                ></textarea>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label>Start Time</label>
                                                <input type="time" onChange={this.handle} className="form-control" id="startTime" name="startTime" placeholder="Select Start Time" />
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label>Duration</label>
                                                <input type="text" onChange={this.handle} className="form-control" id="duration" name="duration" placeholder="Enter Duration (Ex :- 1 Hours)" />
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <label>Interview Link</label>
                                                <input type="text" onChange={this.handle} className="form-control" id="interviewLink" name="interviewLink" placeholder="Enter Interview Link" />
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label>Interview Date</label>
                                                <input type="date" onChange={this.handle} className="form-control" id="interviewDate" name="interviewDate" placeholder="Select Interview Date" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer bg-white text-right">
                                        <button className="btn btn-sm btn-success" type="submit" id="scheduleInterviewSubmitButton"><b>Schedule Interview <i class="fa fa-spin"></i></b></button> <NavLink className="" to="/sortlisted"><button onClick={this.removeInterviewScheduleStatus} className="btn btn-sm btn-danger"><b>Back</ b></button></ NavLink>
                                    </div>

                                </div>

                            </div>
                        </form>
                    </div>
                : <NavLink className="text-center mx-auto" to="/sortlisted"><button className="btn btn-sm btn-danger"><b>Go Back & Schedule Interview</b></button></ NavLink> }    
            </>
        )
    }
}
export default ScheduleInterview;