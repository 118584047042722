import React, { Component } from 'react';
import './singleview.css';
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';

class SingleView extends Component{

    constructor(props) {
        super(props);
        this.state = {
          url: "https://www.digitizedresume.com/api/admin/",
          name: '',
          phone: '',
          email: '',
          password: '',
          dataStatus: "false"
        };
    }

    componentDidMount() {
        var id = sessionStorage.getItem("adminId");
        let formData = new FormData();
        formData.append("id", id);
        // console.log(obj)
        var url = this.state.url + "getSingleAdminData.php";
        Axios.post(url, formData).then((res) => {
          // console.log(res.data);
          this.setState({
            name: res.data[0].name,
            phone: res.data[0].phone,
            email: res.data[0].email,
            password: res.data[0].password,
            image: res.data[0].image,
            dataStatus: "true"
            });
        });
    }

    render(){
        return(
            <div>
                {sessionStorage.getItem("aId") ? (
                    <>
                        <div className="page-header">
                            <h3 className="page-title">Admin</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Admin</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">View Detail</li>
                                </ol>
                            </nav>
                        </div>
                        {this.state.dataStatus === "false" ? (
                              <Spinner />
                        ) : (
                        <div className="row mt-5">
                            <div className="col-md-12 view_profile bg-white col-12">
                                <div className="row border-bottom">
                                    <div className="col-md-6 col-8">
                                        <h6 className=" pl-3 pt-2">View Admin Detail</h6>
                                    </div>  
                                    <div className="col-md-6 col-4 close_button">
                                    <NavLink  to="/view_admin"> <i className="mdi mdi-close"></i></NavLink>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <form className="mt-5 mb-5 admin_form">
                                        <div className="row px-5">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="your_name"><i className="zmdi mdi mdi-account material-icons-name"></i></label>
                                                    <input type="text" value={this.state.name} name="your_name"  placeholder="Name" disabled  style={{ textTransform: 'capitalize' }}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="email"><i className="zmdi mdi mdi-email-variant material-icons-name"></i></label>
                                                    <input type="email" value={this.state.email} name="email" placeholder="Email Id" disabled />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="number"><i className="zmdi mdi mdi-phone-plus material-icons-name"></i></label>
                                                    <input type="number" value={this.state.phone} name="number" placeholder="Phone number" disabled />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="password"><i className="zmdi mdi mdi mdi-lock material-icons-name"></i></label>
                                                    <input type="text" value={this.state.password+" (Encrypted)"} name="password"  placeholder="Password" disabled />
                                                </div>
                                            </div>
                                            {(() => {
                                                if (this.state.image !== null && this.state.image !== "null" && this.state.image !== "") {
                                                  return (
                                                    <div class="form-group row" id="companyLogo" style={{borderBottom:'none'}}>
                                                        <div className="col-md-4"></div>
                                                        <div className="col-md-4">
                                                            <img className="img-fluid" src={"https://www.digitizedresume.com/api/admin/assets/profile/"+this.state.image} alt={this.state.name}/>
                                                        </div>
                                                        <div className="col-md-4"></div>
                                                    </div>
                                                  );
                                                }  
                                            })()}
                                        </div>
                                    </form>
                                </div>




                            </div>
                        </div>
                        )}
                    </>
                ) : false}

            </div>

        )
    }
}
export default SingleView