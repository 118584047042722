import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './Exploreprofile.css';
import face from './pic.png';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';
import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';

class ExploreProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "https://www.digitizedresume.com/api/admin/",
      data: [],
      skill: [],
      dataStatus: "false",
      text: '',
      searchBy: 'Availability',
      searchBy: '',
      show: false,
      showHide: false,
    };
  }

  componentDidMount() {
    var id = sessionStorage.getItem("aId");
    // console.log(obj)
    var skillUrl = this.state.url + "getSkillsData.php";
    Axios.get(skillUrl)
      .then(res => {
        // console.log(res.data[0]);
        this.setState({
          skill: res.data
        });
      })
      .catch(function (error) {
        console.log(error);
      })


    var url = this.state.url + "getUserData.php";
    Axios.get(url)
      .then(res => {
        // console.log(res.data[0]);
        this.setState({
          data: res.data,
          dataStatus: "true"
        });
      })
      .catch(function (error) {
        console.log(error);
      })

  }

  setUserId = (e) => {
    e.preventDefault();
    // alert(e.target.value);
    sessionStorage.setItem("userId", e.target.value);
  }

  resetFilter = (e) => {
    e.preventDefault();
    this.setState({
      dataStatus: "false"
    });

    var url = this.state.url + "getUserData.php";
    Axios.get(url)
      .then(res => {
        // console.log(res.data[0]);
        this.setState({
          data: res.data,
          dataStatus: "true"
        });
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  shortListedActive = (e) => {
    e.preventDefault();
    this.setState({
      dataStatus: "false"
    });
    var url = this.state.url + "wishlist.php";

    var userId = e.target.value;
    var userName = e.target.name;
    var adminId = sessionStorage.getItem("aId");
    var adminName = sessionStorage.getItem("adminName");

    let formData = new FormData();
    formData.append('userId', userId);
    formData.append('userName', userName);
    formData.append('adminId', adminId);
    formData.append('adminName', adminName);

    Axios.post(url, formData).then((res) => {
      // alert(res.data);
      this.setState({
        dataStatus: "true"
      });
      if (res.data === "Wishlisted Successfully") {
        swal({
          title: "Shortlisted!",
          text: "Profile Shortlisted!",
          button: "Close",
          icon: "success",
          timer: 3000,
          button: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "",
              closeModal: true
            }
          }
        });
      }
      else if (res.data === "This user is already hired") {
        swal({
          title: "Already Hired!",
          text: "This user is already hired!",
          button: "Close",
          icon: "info",
          timer: 3000,
          button: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "",
              closeModal: true
            }
          }
        });
      }
      else {
        swal({
          title: "Already Shortlisted!",
          text: "This profile is already Shortlisted!",
          button: "Close",
          icon: "info",
          timer: 3000,
          button: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "",
              closeModal: true
            }
          }
        });
      }
    });

  }

  handleModalShowHide = (e) => {
    e.preventDefault();
    this.setState({
      showHide: !this.state.showHide
    });
  }

  filterSearchData = (e) => {
    e.preventDefault();
    var name = document.getElementById("nameSearchExploreProfile").value;
    var uniqueId = document.getElementById("uniqueIdSearchExploreProfile").value;
    var skills = document.getElementById("skillsSearchExploreProfile").value;
    var domain = document.getElementById("domainSearchExploreProfile").value;
    var ctc = document.getElementById("ctcSearchExploreProfile").value;
    var location = document.getElementById("locationSearchExploreProfile").value;
    var availability = document.getElementById("availabilitySearchExploreProfile").value;

    var expYear = document.getElementById("expYearSearchExploreProfile").value;
    var expMonth = document.getElementById("expMonthSearchExploreProfile").value;

    var totalExperience = 0;
    if (expYear !== "") {
      totalExperience = totalExperience + parseInt(expYear) * 12;
    }
    if (expMonth !== "") {
      totalExperience = totalExperience + parseInt(expMonth);
    }

    if (name !== "" || uniqueId !== "" || skills !== "" || domain !== "" || ctc !== "" || location !== "" || totalExperience !== 0 || availability !== "") {


      this.setState({
        dataStatus: "false",
        showHide: !this.state.showHide
      });

      var SQL = "";
      var totalQuery = 0;

      if (skills === "") {
        SQL = "SELECT * FROM user WHERE ";
        if (name !== "") {
          if (totalQuery > 0) {
            SQL += " AND name LIKE '%" + name + "%'";
            totalQuery += 1;
          }
          else {
            SQL += "name LIKE '%" + name + "%'";
            totalQuery += 1;
          }
        }
        if (uniqueId !== "") {
          if (totalQuery > 0) {
            SQL += " AND uniqueId = " + uniqueId;
            totalQuery += 1;
          }
          else {
            SQL += "uniqueId LIKE '" + uniqueId + "'";
            totalQuery += 1;
          }
        }
        if (domain !== "") {
          if (totalQuery > 0) {
            SQL += " AND domain LIKE '%" + domain + "%'";
            totalQuery += 1;
          }
          else {
            SQL += "domain LIKE '%" + domain + "%'";
            totalQuery += 1;
          }
        }
        if (ctc !== "") {
          if (totalQuery > 0) {
            SQL += " AND annualSalary <= " + ctc;
            totalQuery += 1;
          }
          else {
            SQL += "annualSalary <= " + ctc;
            totalQuery += 1;
          }
        }
        if (location !== "") {
          if (totalQuery > 0) {
            SQL += " AND address LIKE '%" + location + "%'";
            totalQuery += 1;
          }
          else {
            SQL += "address LIKE '%" + location + "%'";
            totalQuery += 1;
          }
        }
        if (totalExperience !== 0) {
          if (totalQuery > 0) {
            SQL += " AND totalExperience <= " + totalExperience;
            totalQuery += 1;
          }
          else {
            SQL += "totalExperience <= " + totalExperience;
            totalQuery += 1;
          }
        }
        if (availability !== "") {
          if (totalQuery > 0) {
            SQL += " AND availability LIKE '%" + availability + "%'";
            totalQuery += 1;
          }
          else {
            SQL += "availability LIKE '%" + availability + "%'";
            totalQuery += 1;
          }
        }
      }
      else if (skills !== "" && name === "" && uniqueId === "" && domain === "" && ctc === "" && location === "" && totalExperience === 0 && availability === "") {
        // SQL = "SELECT DISTINCT * FROM userSkills WHERE ";
        SQL = "SELECT DISTINCT user.id, user.uniqueId, user.domain, user.name, user.profileImage, user.designation, user.availability, user.workInfo, user.totalExperience, user.annualSalary FROM user, userskills WHERE userskills.name LIKE '%" + skills + "%' AND userskills.userId=user.id";
      }
      else {
        SQL = "SELECT DISTINCT user.id, user.uniqueId, user.domain, user.name, user.profileImage, user.designation, user.availability, user.workInfo, user.totalExperience, user.annualSalary FROM user, userskills WHERE ";
        if (name !== "") {
          if (totalQuery > 0) {
            SQL += " AND user.name LIKE '%" + name + "%'";
            totalQuery += 1;
          }
          else {
            SQL += "user.name LIKE '%" + name + "%'";
            totalQuery += 1;
          }
        }
        if (uniqueId !== "") {
          if (totalQuery > 0) {
            SQL += " AND user.uniqueId LIKE '" + uniqueId + "'";
            totalQuery += 1;
          }
          else {
            SQL += "user.uniqueId = " + uniqueId;
            totalQuery += 1;
          }
        }
        if (skills !== "") {
          if (totalQuery > 0) {
            SQL += " AND userskills.name LIKE '%" + skills + "%' AND userskills.userId=user.id";
            totalQuery += 1;
          }
          else {
            SQL += "userskills.name LIKE '%" + skills + "%' AND userskills.userId=user.id";
            totalQuery += 1;
          }
        }
        if (domain !== "") {
          if (totalQuery > 0) {
            SQL += " AND user.domain LIKE '%" + domain + "%'";
            totalQuery += 1;
          }
          else {
            SQL += "user.domain LIKE '%" + domain + "%'";
            totalQuery += 1;
          }
        }
        if (ctc !== "") {
          if (totalQuery > 0) {
            SQL += " AND user.annualSalary <= " + ctc;
            totalQuery += 1;
          }
          else {
            SQL += "user.annualSalary <= " + ctc;
            totalQuery += 1;
          }
        }
        if (location !== "") {
          if (totalQuery > 0) {
            SQL += " AND user.address LIKE '%" + location + "%'";
            totalQuery += 1;
          }
          else {
            SQL += "user.address LIKE '%" + location + "%'";
            totalQuery += 1;
          }
        }
        if (totalExperience !== 0) {
          if (totalQuery > 0) {
            SQL += " AND user.totalExperience <= " + totalExperience;
            totalQuery += 1;
          }
          else {
            SQL += "user.totalExperience <= " + totalExperience;
            totalQuery += 1;
          }
        }
        if (availability !== "") {
          if (totalQuery > 0) {
            SQL += " AND user.availability LIKE '%" + availability + "%'";
            totalQuery += 1;
          }
          else {
            SQL += "user.availability LIKE '%" + availability + "%'";
            totalQuery += 1;
          }
        }
      }

      SQL += ";";

      let formData = new FormData();
      formData.append("sql_Query", SQL);

      var searchURL = this.state.url + "filterData/filterData.php";

      Axios.post(searchURL, formData).then((res) => {
        // alert(res.data);
        this.setState({
          data: res.data,
          dataStatus: "true"
        });
      })

    }
    else {
      swal({
        title: "Error!",
        text: "Please fill at least any one field to filter!",
        button: "Close",
        icon: "error",
        timer: 3000,
        button: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      });
    }
  }

  render() {
    return (
      <div>
        {sessionStorage.getItem("aId") ? (
          <>
            <div className="page-header">
              <h3 className="page-title">Explore Profile</h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Explore Profile</a></li>
                </ol>
              </nav>
            </div>
            <div className="row my-4">
              <div className="col-md-8"></div>
              <div className="col-md-4 text-right sticky">
                <button className="btn btn-sm btn-info" onClick={this.handleModalShowHide}>Filter <i className="fa fa-filter"></i></button>
                &nbsp;
                <button className="btn btn-sm btn-danger" onClick={this.resetFilter}>Rest Filter <i className="fa fa-close"></i></button>
              </div>
            </div>
            <Modal show={this.state.showHide}>
              <Modal.Header>
                <Modal.Title>Filter Profile</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <div className="row filter---model">
                    <div className=" mt-2 col-md-6">
                      <label className="form-label" for="nameSearchExploreProfile">Enter Name</label>
                      <input type="text" className="form-control" name="nameSearchExploreProfile" id="nameSearchExploreProfile" placeholder="Enter Name" ></input>
                    </div>
                    <div className=" mt-2 col-md-6">
                      <label className="form-label" for="uniqueIdSearchExploreProfile">Enter Unique Id</label>
                      <input type="text" className="form-control" name="uniqueIdSearchExploreProfile" id="uniqueIdSearchExploreProfile" placeholder="Enter Unique Id" ></input>
                    </div>
                    <div className="mt-2 col-md-6">
                      <label className="form-label" for="skillsSearchExploreProfile">Enter Skills</label>
                      <input type="text" className="form-control" name="skillsSearchExploreProfile" id="skillsSearchExploreProfile" placeholder="Enter Skills" ></input>
                    </div>
                    <div className="mt-2 col-md-6">
                      <label className="form-label" for="domainSearchExploreProfile">Enter Domain</label>
                      <input type="text" className="form-control" name="domainSearchExploreProfile" id="domainSearchExploreProfile" placeholder="Enter Domain" ></input>
                    </div>
                    <div className="mt-2 col-md-6">
                      <label className="form-label" for="ctcSearchExploreProfile">Enter CTC</label>
                      <input type="number" className="form-control" name="ctcSearchExploreProfile" id="ctcSearchExploreProfile" placeholder="Enter CTC" ></input>
                    </div>
                    <div className="mt-2 col-md-6">
                      <label className="form-label" for="locationSearchExploreProfile">Enter Location</label>
                      <input type="text" className="form-control" name="locationSearchExploreProfile" id="locationSearchExploreProfile" placeholder="Enter Location" ></input>
                    </div>
                    <div className="mt-2 col-md-6">
                      <label className="form-label" for="yearOfExpSearchExploreProfile">Enter Year of Experience / Month </label>
                      {/* <input type="number" className="form-control" name="yearOfExpSearchExploreProfile" id="yearOfExpSearchExploreProfile" placeholder="Enter Year of Experience" ></input> */}
                      <div className="exprience-input">
                      <input type="number" name="expYearSearchExploreProfile" id="expYearSearchExploreProfile" placeholder="Enter Year" />
                        <input type="number" name="expMonthSearchExploreProfile" id="expMonthSearchExploreProfile" placeholder="Enter Month" />
                      </div>
                    </div>
                    <div className="mt-2 col-md-6">
                      <label className="form-label" for="availabilitySearchExploreProfile">Select Availability</label>
                      <select id="availabilitySearchExploreProfile" className="form-control" name="availabilitySearchExploreProfile">
                        <option value="" selected>All</option>
                        <option value="Full Time">Full Time</option>
                        <option value="Part Time">Part Time</option>
                        <option value="Not Available">Not Available</option>
                      </select>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" id="modalSkip" onClick={this.handleModalShowHide}>
                  Cancel
                </Button>
                <Button type="submit" onClick={this.filterSearchData} id="modalValidate" variant="success">
                  Filter
                </Button>
              </Modal.Footer>
            </Modal>
            {this.state.dataStatus === "false" ? (
              <Spinner />
            ) : (
              <div className="row">
                {this.state.data.length === 0 ? (
                  <p className="text-center">No Records Found</p>
                ) : (


                  this.state.data.map((ele, i) => {
                    return (
                      <>
                        <div className="col-sm-4 mt-5 mb-4 exploreProfileCard">
                          <div className="card profile_card">
                            <div className="card-body">
                              <div className="cardFirstRow">
                                <div className="column1 text-center">
                                  {(() => {
                                    if (ele.profileImage === null) {
                                      return <img src={face} alt={this.state.name} className="img-circle img-fluid" />;
                                    } else {
                                      return (
                                        <img src={"https://www.digitizedresume.com/api/user/assets/profile/" + ele.profileImage} className="img-fluid shadow-sm" alt={ele.name} />
                                      );
                                    }
                                  })()}
                                </div>
                                <div className="column2">
                                  <h5 className="" style={{ textTransform: 'capitalize' }}>{ele.name}</h5>
                                  {(() => {
                                    if (ele.availability === "Full Time") {
                                      return (
                                        <>
                                          <p className="" style={{ textTransform: 'capitalize' }}>{ele.designation + " ("}
                                            <span className="text-success" style={{ textTransform: 'capitalize' }}> {ele.availability}</span> <span> )</span>
                                          </p>
                                        </>
                                      );
                                    }
                                    else if (ele.availability === "Part Time") {
                                      return (
                                        <>
                                          <p className="" style={{ textTransform: 'capitalize' }}>{ele.designation + " ("}
                                            <span className="text-success" style={{ textTransform: 'capitalize' }}> {ele.availability}</span> <span> )</span>
                                          </p>
                                        </>
                                      );
                                    }
                                    else if (ele.availability === "Not Available") {
                                      return (
                                        <>
                                          <p className="" style={{ textTransform: 'capitalize' }}>{ele.designation + " ("}
                                            <span className="text-danger" style={{ textTransform: 'capitalize' }}> {ele.availability}</span> <span> )</span>
                                          </p>
                                        </>
                                      );
                                    }
                                  })()}
                                </div>
                              </div>
                              {ele.workInfo !== null && ele.workInfo !== "null" ? <p class="card-text workInfo">{ele.workInfo}</p> : false}
                              {ele.annualSalary !== null && ele.annualSalary !== "null" ? <p class="card-text workInfo">{"CTC : " + ele.annualSalary}</p> : false}
                            </div>

                            <div class="card-footer bg-white">
                              <div className="cardFirstRow cardButtonRow">
                                <div className="column4">
                                  <button onClick={this.setUserId} value={ele.id} className="cardButton1">
                                    <NavLink className="text-center" to="/user_profile">
                                      <button value={ele.id} className="cardButton2"> Read More </button>
                                    </NavLink>
                                  </button>
                                </div>
                                <div className="column5">
                                  <button className="text-center cardButton2" id="shortListed" name={ele.name} value={ele.id} onClick={this.shortListedActive}>
                                    Shortlist
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="digitalCVButton">
                              <a target="_blank" href={"https://digitizedresume.com/" + ele.domain}><button className="text-center btn btn-sm btn-warning">
                                View CV
                              </button></a>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                )}
              </div>
            )}

          </>
        ) : false}
      </div>
    )
  }
}
export default ExploreProfile