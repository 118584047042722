import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Axios from 'axios';
import swal from 'sweetalert';

export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: 'https://digitizedresume.com/api/admin/',
            email: '',
            password: ''
        }
    }

    componentDidMount() {
        if (sessionStorage.getItem("aId")) {
            window.location.href = "/"
        }
    }
    handle = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        // alert(e.target.value);
        if (e.target.value !== "") {
            document.getElementById("error" + e.target.name).innerHTML = "";
        }
        var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (e.target.name === "email") {
            if (document.getElementById("email").value !== "") {
                if (document.getElementById("email").value.match(emailRegEx)) {
                    document.getElementById("erroremail").innerHTML = "";
                }
                else {
                    document.getElementById("erroremail").innerHTML = "Please enter a valid email.";
                }
            }
            else {
                document.getElementById("erroremail").innerHTML = "This field is required. Please enter email.";
            }
        }

        if (e.target.name === "password") {
            if (document.getElementById("password").value !== "") {
                if (document.getElementById("password").value.length > 5) {
                    document.getElementById("errorpassword").innerHTML = "";
                }
                else {
                    document.getElementById("errorpassword").innerHTML = "Please enter at least 5 digit password.";
                }
            }
            else {
                document.getElementById("errorpassword").innerHTML = "This field is required. Please enter password.";
            }
        }

    }

    submit = (e) => {

        e.preventDefault();

        var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        var check = 0;

        if (document.getElementById("email").value !== "") {
            if (document.getElementById("email").value.match(emailRegEx)) {
                document.getElementById("erroremail").innerHTML = "";
            }
            else {
                check++;
                document.getElementById("erroremail").innerHTML = "Please enter a valid email.";
            }
        }
        else {
            check++;
            document.getElementById("erroremail").innerHTML = "This field is required. Please enter email.";
        }


        if (document.getElementById("password").value !== "") {
            if (document.getElementById("password").value.length > 5) {
                document.getElementById("errorpassword").innerHTML = "";
            }
            else {
                check++;
                document.getElementById("errorpassword").innerHTML = "Please enter at least 5 digit password.";
            }
        }
        else {
            check++;
            document.getElementById("errorpassword").innerHTML = "This field is required. Please enter password.";
        }

        if (check === 0) {
            document.getElementsByClassName("fa-spin")[0].classList.add("fa-refresh");
            document.getElementsByClassName("auth-form-btn")[0].disabled = true;
            let formData = new FormData();
            formData.append('email', this.state.email);
            formData.append('password', this.state.password);
            var url = this.state.url + 'login.php';
            // console.log(obj)
            Axios.post(url, formData)
                .then((res) => {
                    // alert(res.data);
                    if (res.data === 0) {
                        document.getElementsByClassName("fa-spin")[0].classList.remove("fa-refresh");
                        document.getElementsByClassName("auth-form-btn")[0].disabled = false;
                        swal({
                            title: "Error!",
                            text: "Email or password is incorrect!",
                            button: "Close",
                            icon: "error",
                            timer: 3000,
                            button: {
                                confirm: {
                                    text: "OK",
                                    value: true,
                                    visible: true,
                                    className: "",
                                    closeModal: true
                                }
                            }
                        });
                    }
                    else {
                        // alert(res.data[0].id);
                        sessionStorage.setItem("aId", res.data[0].id);
                        sessionStorage.setItem("adminName", res.data[0].name);
                        sessionStorage.setItem("auniqueId", res.data[0].uniqueId);
                        setTimeout(function () {
                            window.location.href = "/";
                        }, 200);
                    }
                });
        }
    }

    render() {
        return (
            <div>
                <div className="d-flex align-items-center auth px-0">
                    <div className="row w-100 mx-0">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                <div className="brand-logo">
                                    <img src="https://www.digitizedresume.com/static/media/logo.42d37883.svg" alt="logo" />
                                </div>
                                <h4>Hello! let's get started</h4>
                                <h6 className="font-weight-light">Sign in to continue.</h6>
                                <Form className="pt-3" onSubmit={this.submit}>
                                    <Form.Group className="d-flex search-field">
                                        <Form.Control type="email" name="email" onChange={this.handle} id="email" placeholder="Email" size="lg" className="h-auto" />
                                    </Form.Group>
                                    <p className="error" id="erroremail"></p>
                                    <Form.Group className="d-flex search-field">
                                        <Form.Control type="password" name="password" onChange={this.handle} id="password" placeholder="Password" size="lg" className="h-auto" />
                                    </Form.Group>
                                    <p className="error" id="errorpassword"></p>
                                    <div className="mt-3">
                                        <button type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                                            SIGN IN <i class="fa fa-spin"></i>
                                        </button>
                                    </div>
                                    {/* <div className="my-2 d-flex justify-content-between align-items-center">
                                        <div className="form-check">
                                            <label className="form-check-label text-muted">
                                                <input type="checkbox" className="form-check-input" />
                                                <i className="input-helper"></i>
                                                Keep me signed in
                                            </label>
                                        </div>
                                        <a href="!#" onClick={event => event.preventDefault()} className="auth-link text-black">Forgot password?</a>
                                    </div> */}
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login
