import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './job.css';
import Axios from 'axios';
import Spinner from '../shared/Spinner';

class ViewSingleJob extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            dataStatus: "false",
            jobTitle: '',
            department: '',
            jobType: 'Full Time',
            location: '',
            remoteJob: 'No',
            minSalary: '',
            maxSalary: '',
            exp: '',
            jobDescription: '',
            skills: '',
            status: '',
            application: '',

        };
    }

    componentDidMount() {
        var jobId = sessionStorage.getItem("jobId");
        let formData = new FormData();
        formData.append("id", jobId);
        var url = this.state.url + "singleJobData.php";
        Axios.post(url, formData)
            .then((res) => {
                this.setState({
                    // data: res.data,
                    jobTitle: res.data[0].jobTitle,
                    department: res.data[0].department,
                    jobType: res.data[0].jobType,
                    location: res.data[0].location,
                    remoteJob: res.data[0].remoteJob,
                    minSalary: res.data[0].minSalary,
                    maxSalary: res.data[0].maxSalary,
                    exp: res.data[0].exp,
                    jobDescription: res.data[0].jobDescription,
                    skills: res.data[0].skills,
                    status: res.data[0].status,
                    application: res.data[0].application,
                    dataStatus: "true"
                });
                // console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    removeInterviewScheduleStatus = (e) => {
        sessionStorage.removeItem("viewInterviewInfo");
    }


    render() {
        return (
            <>
                <div id="createJobAlert">
                    <p id="createJobMessage"></p>
                </div>
                <div className="page-header">
                    <h3 className="page-title">View Job</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><NavLink exact to="/jobs">Job</NavLink> / <a href="!#" onClick={event => event.preventDefault()}>View Job</a></li>

                        </ol>
                    </nav>
                </div>

                {sessionStorage.getItem("viewJobDetails") ? 
                    (   
                        <>
                            {this.state.dataStatus === "true" ?
                                (<div className="row">

                                    <div className="col-md-10 mt-3 mx-auto">
                                        <div className="card">
                                            <div className="card-header bg-white">
                                                <h5 className="pt-2"><b>View job details</b></h5>
                                            </div>
                                            <div className="card-body m-0 pt-1">
                                                <div className="row">
                                                    <div className="col-md-6 mt-3">
                                                        <label>Job Title</label>
                                                        <input type="text" value={this.state.jobTitle} readOnly className="form-control bg-white" />
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <label>Department</label>
                                                        <input type="text" value={this.state.department} readOnly className="form-control bg-white" />
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <label>Job Type</label>
                                                        <input type="text" value={this.state.jobType} readOnly className="form-control bg-white" />
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <label>Location</label>
                                                        <input type="text" value={this.state.location} readOnly className="form-control bg-white" />
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <label>Remote Job?</label>
                                                        <input type="text" value={this.state.remoteJob} readOnly className="form-control bg-white" />
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <label>Minimum Salary</label>
                                                        <input type="text" value={this.state.minSalary} readOnly className="form-control bg-white" />
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <label>Maximum Salary</label>
                                                        <input type="text" value={this.state.maxSalary} readOnly className="form-control bg-white" />
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <label>Experience</label>
                                                        <input type="text" value={this.state.exp} readOnly className="form-control bg-white"/>
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <label>Application Status</label>
                                                        <input type="text" value={this.state.status} readOnly className="form-control bg-white" />
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <label>Total Applied Application</label>
                                                        <input type="text" value={this.state.application} readOnly className="form-control bg-white"/>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label>Job Description</label>
                                                        <textarea
                                                            className="form-control form-control-sm bg-white"
                                                            style={{ height: "130px" }}
                                                            readOnly
                                                            value={this.state.jobDescription}
                                                        ></textarea>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label>Skills</label>
                                                        <textarea
                                                            className="form-control form-control-sm bg-white"
                                                            style={{ height: "80px" }}
                                                            readOnly
                                                            value={this.state.skills}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer bg-white text-right">
                                                <NavLink exact to="/jobs"><button className="btn btn-sm btn-danger" type="reset"><b>Back</b></button></NavLink>
                                            </div>

                                        </div>

                                    </div>

                                </div>) : (<Spinner />)
                            }    
                        </>
                    ) : (
                        <NavLink className="text-center mx-auto" to="/jobs"><button className="btn btn-sm btn-danger"><b>Go Back</b></button></ NavLink>
                    )
                }
            </>
        )
    }
}
export default ViewSingleJob;