import React, { Component, PropTypes } from 'react';
import './emailbroadcast.css';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';
import attach from "./attach.png";
import RichTextEditor from 'react-rte';
import swal from 'sweetalert';


export class EmailBroadcast extends Component {

    // static propTypes = {
    //     onChange: PropTypes.func
    // };

    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            dataStatus: 'false',
            user: "",
            userShortlisted: '',
            userList: [],
            userListShortlisted: [],
            data: [],
            shortlistedUserData: [],
            value: RichTextEditor.createEmptyValue(),
            valueShortlisted: RichTextEditor.createEmptyValue(),
        }
    }

    onChange = (value) => {
        this.setState({ value });
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChange(
                value.toString('html')
            );
        }
    }
    onChangeShortlisted = (valueShortlisted) => {
        this.setState({ valueShortlisted });
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChangeShortlisted(
                valueShortlisted.toString('html')
            );
        }
    };

    componentDidMount() {
        if (!sessionStorage.getItem("aId")) {
            window.location.href = "/login";
        }
        var userURL = this.state.url + "getUserDataForEmailBroadCast.php";
        Axios.get(userURL)
            .then((res) => {
                // console.log(res.data);
                this.setState({
                    data: res.data,
                });
            })
            .catch(function (error) {
                alert(error);
                // console.log(error);
            });

        var shortlistedUserDataURL = this.state.url + "getCompanyDataForEmailBroadCast.php";
        var id = sessionStorage.getItem("aId");
        let formData = new FormData();
        formData.append("id", id);
        Axios.post(shortlistedUserDataURL, formData)
            .then((res) => {
                // console.log(res.data);
                this.setState({
                    shortlistedUserData: res.data,
                    dataStatus: "true",
                });
            })
            .catch(function (error) {
                alert(error);
                // console.log(error);
            });
    }

    addUser = (e) => {
        e.preventDefault();
        const Value = document.querySelector('#SelectUser').value;
        if (!Value) return;
        // alert(Value);
        var currentData = this.state.user;
        var userList = currentData.split(',');

        for (var i = 0; i < userList.length; i++) {
            var name = userList[i];
            if (name === Value) {
                document.getElementById("SelectUser").value = "";
                return;
            }
            else if (name === "All") {
                document.getElementById("SelectUser").value = "";
                return;
            }
        }
        if (Value === "All") {
            var data = Value + ",";
            this.setState({ user: data })
            document.getElementById("SelectUser").value = "";
            return;
        }
        var data = currentData + Value + ",";
        this.setState({ user: data })
        document.getElementById("SelectUser").value = "";

    }

    clearUser = (e) => {
        e.preventDefault();
        this.setState({ user: "" })
        document.getElementById("SelectUser").value = "";
    }

    sendEmail = (e) => {
        e.preventDefault();
        var userList = this.state.user;
        var subject = document.getElementById("subject").value;
        var message = this.state.value.toString('html');
        if (userList !== "" && subject !== "" && message !== "") {
            document.getElementsByClassName("fa-spin")[0].classList.add("fa-refresh");
            document.getElementById("sendEmailButton").disabled = true;
            let formData = new FormData();
            formData.append("userList", userList);
            formData.append("subject", subject)
            formData.append("message", message)
            var url = this.state.url + "sendMail.php";
            Axios.post(url, formData).then((res) => {
                // alert(res.data);
                document.getElementsByClassName("fa-spin")[0].classList.remove("fa-refresh");
                document.getElementById("sendEmailButton").disabled = false;
                swal({
                    title: "Sent!",
                    text: "Mail sent successfully!",
                    button: "Close",
                    icon: "success",
                    timer: 3000,
                    button: {
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                        }
                    }
                });
                document.getElementById("subject").value = "";
                this.setState({
                    value: RichTextEditor.createEmptyValue(),
                    user: "",
                })
            })
        }
        else {
            swal({
                title: "Error!",
                text: "Please fill all fields to proceed further!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
    }

    addUserShortlisted = (e) => {
        e.preventDefault();
        const Value = document.querySelector('#SelectUserShortlisted').value;
        if (!Value) return;
        // alert(Value);
        var currentData = this.state.userShortlisted;
        var userListShortlisted = currentData.split(',');

        for (var i = 0; i < userListShortlisted.length; i++) {
            var name = userListShortlisted[i];
            if (name === Value) {
                document.getElementById("SelectUserShortlisted").value = "";
                return;
            }
            else if (name === "All") {
                document.getElementById("SelectUserShortlisted").value = "";
                return;
            }
        }
        if (Value === "All") {
            var data = Value + ",";
            this.setState({ userShortlisted: data })
            document.getElementById("SelectUserShortlisted").value = "";
            return;
        }
        var data = currentData + Value + ",";
        this.setState({ userShortlisted: data })
        document.getElementById("SelectUserShortlisted").value = "";

    }

    clearUserShortlisted = (e) => {
        e.preventDefault();
        this.setState({ userShortlisted: "" })
        document.getElementById("SelectUserShortlisted").value = "";
    }

    sendEmailShortlisted = (e) => {
        e.preventDefault();
        var userList = this.state.userShortlisted;
        var subject = document.getElementById("subjectShortlisted").value;
        var message = this.state.value.toString('html');
        if (userList !== "" && subject !== "" && message !== "") {
            document.getElementsByClassName("Shortlisted")[0].classList.add("fa-refresh");
            document.getElementById("sendEmailButtonShortlisted").disabled = true;
            let formData = new FormData();
            formData.append("userList", userList);
            formData.append("subject", subject)
            formData.append("message", message)
            var url = this.state.url + "sendMail.php";
            Axios.post(url, formData).then((res) => {
                // alert(res.data);
                document.getElementsByClassName("Shortlisted")[0].classList.remove("fa-refresh");
                document.getElementById("sendEmailButtonShortlisted").disabled = false;
                swal({
                    title: "Sent!",
                    text: "Mail sent successfully!",
                    button: "Close",
                    icon: "success",
                    timer: 3000,
                    button: {
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                        }
                    }
                });
                document.getElementById("subjectShortlisted").value = "";
                this.setState({
                    valueShortlisted: RichTextEditor.createEmptyValue(),
                    userShortlisted: "",
                })
            })
        }
        else {
            swal({
                title: "Error!",
                text: "Please fill all fields to proceed further!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
    }

    render() {
        return (
            <div className="row">
                <div id="mailAddAlert">
                    <p id="mail"></p>
                </div>
                {this.state.dataStatus === "true" ?
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-8 mx-auto email_broadcast">
                                <div class="card">
                                    <div class="card-header email_broadcast_header">
                                        <i className="mdi mdi-email"></i    >   User Email Broadcast
                                    </div>
                                    <div class="card-body email_broadcast_input">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <input id="SelectUser" placeholder="Select User" className="datalist" type="text" list="AllUser" />
                                                        <datalist id="AllUser">
                                                            <option label="Send Mail To All Users" value="All" />
                                                            {this.state.data.length === 0 ? (
                                                                <tr>
                                                                    <td colSpan="7" className="text-center">
                                                                        No Records Found
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                this.state.data.map((ele, i) => {
                                                                    return (
                                                                        <option label={ele.name} value={ele.email} />
                                                                    );
                                                                })
                                                            )}
                                                        </datalist>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <button className="btn btn-sm bg-primary text-white px-3x" onClick={this.addUser}>Add</button>
                                                        &nbsp;
                                                        <button className="btn btn-sm bg-danger text-white px-3x" onClick={this.clearUser}>Clear</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <textarea className="px-2 py-2" style={{ height: 'auto', width: '100%' }} value={this.state.user} placeholder="To" />
                                        <textarea className="px-2 py-2" id="subject" name="subject" placeholder="Subject" />
                                        {/* <textarea className="px-2 py-2" id="message" name="message" style={{ height: 'auto', width: '100%' }} placeholder="Message" /> */}
                                        <RichTextEditor
                                            value={this.state.value}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div class="card-footer bg-white py-1 text-muted">
                                        <button className="send_email" id="sendEmailButton" onClick={this.sendEmail}>Send Email <i className="mdi mdi-arrow-right"></i> <i class="fa fa-spin"></i> </button>
                                        {/* <label id="files" className="btn btn-link text-decoration-none font-size-16 waves-effect">
                                    <img src={attach}  className="image-icon" alt="upload image" />
                                    <input name="fileInput" size="10" type="file" className="form-control-file" />
                                </label> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 mx-auto mt-5 email_broadcast">
                                <div class="card">
                                    <div class="card-header email_broadcast_header">
                                        <i className="mdi mdi-email"></i    >   Shortlisted User Email Broadcast
                                    </div>
                                    <div class="card-body email_broadcast_input">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <input id="SelectUserShortlisted" placeholder="Select User" className="datalist" type="text" list="ShortlistedUsers" />
                                                        <datalist id="ShortlistedUsers">
                                                            <option label="Send Mail To All Users" value="All" />
                                                            {this.state.shortlistedUserData.length === 0 ? (
                                                                <tr>
                                                                    <td colSpan="7" className="text-center">
                                                                        No Records Found
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                this.state.shortlistedUserData.map((ele, i) => {
                                                                    return (
                                                                        <option label={ele.name} value={ele.email} />
                                                                    );
                                                                })
                                                            )}
                                                        </datalist>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <button className="btn btn-sm bg-primary text-white px-3x" onClick={this.addUserShortlisted}>Add</button>
                                                        &nbsp;
                                                        <button className="btn btn-sm bg-danger text-white px-3x" onClick={this.clearUserShortlisted}>Clear</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <textarea className="px-2 py-2" style={{ height: 'auto', width: '100%' }} value={this.state.userShortlisted} placeholder="To" />
                                        <textarea className="px-2 py-2" id="subjectShortlisted" name="subjectShortlisted" placeholder="Subject" />
                                        {/* <textarea className="px-2 py-2" id="message" name="message" style={{ height: 'auto', width: '100%' }} placeholder="Message" /> */}
                                        <RichTextEditor
                                            value={this.state.valueShortlisted}
                                            onChange={this.onChangeShortlisted}
                                        />
                                    </div>
                                    <div class="card-footer bg-white py-1 text-muted">
                                        <button className="send_email" id="sendEmailButtonShortlisted" onClick={this.sendEmailShortlisted}>Send Email <i className="mdi mdi-arrow-right"></i> <i class="Shortlisted fa fa-spin"></i> </button>
                                        {/* <label id="files" className="btn btn-link text-decoration-none font-size-16 waves-effect">
                                    <img src={attach}  className="image-icon" alt="upload image" />
                                    <input name="fileInput" size="10" type="file" className="form-control-file" />
                                </label> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <Spinner />
                }
            </div >
        )
    }



}