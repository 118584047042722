import React, { Component } from 'react';
import './invoice.css';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';

export class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            dataStatus: "false",
            data: [],
            todayIncome: 0,
            totalIncome: 0,
            monthlyIncome: 0,
            yearlyIncome: 0,
        }
    }

    componentDidMount() {
        if (!sessionStorage.getItem("aId")) {
            window.location.href = "/login"
        }
        var url = this.state.url + "getPayment.php";
        Axios.get(url)
            .then((res) => {
                this.setState({
                    data: res.data,
                });
                // console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });

        var incomeStatus = this.state.url + "incomeStatus.php";
        Axios.get(incomeStatus)
            .then((res) => {
                this.setState({
                    // data: res.data,
                    todayIncome: res.data.todayIncome,
                    totalIncome: res.data.totalIncome,
                    monthlyIncome: res.data.monthlyIncome,
                    yearlyIncome: res.data.yearlyIncome,
                    dataStatus: "true"
                });
                // console.log(res.data);
                // console.log(res.data.monthlyIncome);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <div>
                {this.state.dataStatus === "true" ?
                    <div>
                        <div className="row page-title-header">
                            <div className="col-12">
                                <div className="page-header">
                                    <h4 className="page-title">Invoice</h4>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 mt-2 invoice_card">
                                <div class="card-body bg-white shadow-sm">
                                    <div className="row">
                                        <div className="col-md-12 text-center col-12">
                                            <h5 className="shadow Today"> <i className="mdi mdi-cash-multiple menu-icon"></i>  Today Income </h5><br></br>
                                            <span>{this.state.todayIncome}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3 mt-2 invoice_card">
                                <div class="card-body bg-white shadow-sm">
                                    <div className="row">
                                        <div className="col-md-12 text-center col-12">
                                            <h5 className="shadow Monthly">
                                                <i className="mdi mdi-cash-multiple menu-icon"></i>  This Month
                                            </h5><br></br>
                                            <span>{this.state.monthlyIncome}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3 invoice_card mt-2">
                                <div class="card-body bg-white shadow-sm">
                                    <div className="row">
                                        <div className="col-md-12 text-center col-12">
                                            <h5 className="shadow yearly"> <i className="mdi mdi-cash-multiple menu-icon"></i>  This Year
                                            </h5><br></br>
                                            <span>{this.state.yearlyIncome}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3 mt-2 invoice_card">
                                <div class="card-body bg-white shadow-sm">
                                    <div className="row">
                                        <div className="col-md-12 text-center col-12">
                                            <h5 className="shadow Weekly"> <i className="mdi mdi-cash-multiple menu-icon"></i>  Total Income

                                            </h5><br></br>
                                            <span>{this.state.totalIncome}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row mt-4">
                            <div className="col-md-12 grid-margin stretch-card" style={{ marginTop: "10px" }}>
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">Invoice Details</h4>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>SN.</th>
                                                        <th>Date</th>
                                                        <th>Name</th>
                                                        <th>Phone</th>
                                                        <th>Email</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.data.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="6" className="text-center">
                                                                No Records Found
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        this.state.data.map((ele, i) => {
                                                            return (
                                                                <tr>
                                                                    <td>{i + 1}</td>
                                                                    <td>{ele.paymentDate}</td>
                                                                    <td>{ele.name}</td>
                                                                    <td>{ele.phone}</td>
                                                                    <td>{ele.email}</td>
                                                                    <td>{ele.amount}</td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <Spinner />
                }
            </div>
        )
    }
}