import React, { Component } from 'react';
import './noofhire.css';
import { MDBDataTable } from "mdbreact";
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';
import Pagination from "@material-ui/lab/Pagination";

export class noOfHired extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            data: [],
            dataStatus: "false",
            page: 1,
            totalCol: 0,
        };
    }
    removeId = (e) => {
        e.preventDefault();
        sessionStorage.removeItem("companyId");
        this.props.history.goBack();
    }
    componentDidMount() {
        var url = this.state.url + "getHiredUserData.php";
        var id = sessionStorage.getItem("companyId");
        let formData = new FormData();
        formData.append("companyId", id);
        Axios.post(url, formData)
            .then((res) => {
                console.log(res.data);
                this.setState({
                    data: res.data,
                    dataStatus: "true",
                });
            })
            .catch(function (error) {
                alert(error);
                console.log(error);
            });
    }

    handlePagination = (e, val) => {
        if (this.state.page != val) {
            if (val > this.state.page) {
                this.setState({
                    page: val,
                    totalCol: val * 5 - 5,
                });
            } else {
                this.setState({
                    page: val,
                    totalCol: val * 5 - 5,
                });
            }
        }
    }

    render() {
        return (
            <div className="row">

                <div className="col-md-12">
                    <Link onClick={this.removeId} className="text-white text-decoration-none" >
                        <i className="mdi back_btn mdi-arrow-left-thick"></i>
                    </Link>
                </div>

                <div className="col-md-12 grid-margin stretch-card" style={{ marginTop: "10px" }} >
                    {this.state.dataStatus === "false" ? <Spinner /> :
                        (
                            <div className="card">

                                <div className="card-body">

                                    <h4 className="card-title">Company Details</h4>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>SN.</th>
                                                    <th>User Name</th>
                                                    <th>Hired Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.data.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="3" className="text-center">
                                                            No Records Found
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    this.state.data.map((ele, i) => {
                                                        if (
                                                            i < this.state.page * 5 &&
                                                            this.state.totalCol <= i
                                                        ) {
                                                            return (
                                                                <tr>
                                                                    <td>{i + 1}</td>
                                                                    <td>{ele.userName}</td>
                                                                    <td>{ele.hiringDate}</td>
                                                                </tr>
                                                            );
                                                        }
                                                    })
                                                )}
                                            </tbody>
                                        </table>
                                        <Pagination
                                            count={parseInt(this.state.data.length / 5) + 1}
                                            page={this.state.page}
                                            onChange={this.handlePagination}
                                            color="primary"
                                        />
                                    </div>
                                </div>
                            </div>

                        )}
                </div>
            </div>
        )
    }
}
