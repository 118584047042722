import React, { Component } from 'react';
import './req-project.css';
import { MDBDataTable } from "mdbreact";
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';
import Pagination from "@material-ui/lab/Pagination";
import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';


class RequestedProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "https://www.digitizedresume.com/api/admin/",
      data: [],
      dataStatus: "false",
      page: 1,
      totalCol: 0,
      searchBy: '',
      showHide: false,
    };
  }

  searchType = (e) => {
    this.setState(
      {
        searchBy: e.target.value
      }
    )
  }

  handlePagination = (e, val) => {
    if (this.state.page != val) {
      if (val > this.state.page) {
        this.setState({
          page: val,
          totalCol: val * 5 - 5,
        });
      } else {
        this.setState({
          page: val,
          totalCol: val * 5 - 5,
        });
      }
    }
  };

  componentDidMount() {
    var url = this.state.url + "getProjectData.php";
    Axios.get(url)
      .then((res) => {
        this.setState({
          data: res.data,
          dataStatus: "true",
        });
        // console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  singleView = (e) => {
    e.preventDefault();
    // alert(e.target.value);
    sessionStorage.setItem("companyProjectId", e.target.value);
  }

  handleModalShowHide = (e) => {
    e.preventDefault();
    this.setState({
      showHide: !this.state.showHide
    });
  }

  resetFilter = (e) => {
    this.setState({
      dataStatus: 'false'
    })
    var url = this.state.url + "getProjectData.php";
    Axios.get(url)
      .then((res) => {
        this.setState({
          data: res.data,
          dataStatus: "true",
        });
        // console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  filterSearchData = (e) => {
    e.preventDefault();
    var nameSearchProject = document.getElementById("nameSearchProject").value;
    var noOfProfessionalsSearchProject = document.getElementById("noOfProfessionalsSearchProject").value;
    var typeOfDeveloperSearchProject = document.getElementById("typeOfDeveloperSearchProject").value;
    var statusSearchProject = document.getElementById("statusSearchProject").value;

    if (nameSearchProject !== "" || noOfProfessionalsSearchProject !== "" || typeOfDeveloperSearchProject !== "" || statusSearchProject !== "") {
      this.setState({
        dataStatus: "false",
        showHide: !this.state.showHide
      });

      var SQL = "";
      var totalQuery = 0;

      SQL = "SELECT * FROM companyproject WHERE";
      if (nameSearchProject !== "") {
        if (totalQuery > 0) {
          SQL += " AND name LIKE '%" + nameSearchProject + "%'";
          totalQuery += 1;
        }
        else {
          SQL += " name LIKE '%" + nameSearchProject + "%'";
          totalQuery += 1;
        }
      }
      if (noOfProfessionalsSearchProject !== "") {
        if (totalQuery > 0) {
          SQL += " AND devNeed <= " + noOfProfessionalsSearchProject;
          totalQuery += 1;
        }
        else {
          SQL += " devNeed <= " + noOfProfessionalsSearchProject;
          totalQuery += 1;
        }
      }
      if (typeOfDeveloperSearchProject !== "") {
        if (totalQuery > 0) {
          SQL += " AND devType LIKE '%" + typeOfDeveloperSearchProject + "%'";
          totalQuery += 1;
        }
        else {
          SQL += " devType LIKE '%" + typeOfDeveloperSearchProject + "%'";
          totalQuery += 1;
        }
      }
      if (statusSearchProject !== "") {
        if (totalQuery > 0) {
          SQL += " AND status LIKE '%" + statusSearchProject + "%'";
          totalQuery += 1;
        }
        else {
          SQL += " status LIKE '%" + statusSearchProject + "%'";
          totalQuery += 1;
        }
      }

      SQL += ";";

      let formData = new FormData();
      formData.append("sql_Query", SQL);

      console.log(SQL);

      var searchURL = this.state.url + "filterData/filterData.php";

      Axios.post(searchURL, formData).then((res) => {
        // alert(res.data);
        this.setState({
          data: res.data,
          dataStatus: "true"
        });
      })

    }
    else {
      swal({
        title: "Error!",
        text: "Please fill at least any one field to filter!",
        button: "Close",
        icon: "error",
        timer: 3000,
        button: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      });
    }
  }

  render() {
    return (
      <>
        {sessionStorage.getItem("aId") ? (
          <>
            <div id="messageAlertProject">
              <p id="messageProject"></p>
            </div>
            <div>
              <div className="page-header">
                <h3 className="page-title">Project</h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="!#" onClick={(event) => event.preventDefault()}>
                        Project
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      All Project
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row my-4">
                <div className="col-md-8"></div>
                <div className="col-md-4 text-right sticky">
                  <button className="btn btn-sm btn-info" onClick={this.handleModalShowHide}>Filter <i className="fa fa-filter"></i></button>
                  &nbsp;
                  <button className="btn btn-sm btn-danger" onClick={this.resetFilter}>Rest Filter <i className="fa fa-close"></i></button>
                </div>
              </div>
              <Modal show={this.state.showHide}>
                <Modal.Header>
                  <Modal.Title>Filter Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <div className="row">
                      <div className=" mt-2 col-md-6">
                        <label className="form-label" for="nameSearchProject">Enter Project Name</label>
                        <input type="text" className="form-control" name="nameSearchProject" id="nameSearchProject" placeholder="Enter Project Name" ></input>
                      </div>
                      <div className=" mt-2 col-md-6">
                        <label className="form-label" for="noOfProfessionalsSearchProject">Enter No. of Professionals</label>
                        <input type="number" className="form-control" name="noOfProfessionalsSearchProject" id="noOfProfessionalsSearchProject" placeholder="Enter No. of Professionals" ></input>
                      </div>
                      <div className="mt-2 col-md-6">
                        <label className="form-label" for="typeOfDeveloperSearchProject">Enter Types of Developers</label>
                        <input type="text" className="form-control" name="typeOfDeveloperSearchProject" id="typeOfDeveloperSearchProject" placeholder="Enter Types of Developers" ></input>
                      </div>
                      <div className="mt-2 col-md-6">
                        <label className="form-label" for="statusSearchProject">Select Status</label>
                        <select id="statusSearchProject" className="form-control" name="statusSearchProject">
                          <option value="" selected>Select Any</option>
                          <option value="Active">Active</option>
                          <option value="Deactive">Deactive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="danger" id="modalSkip" onClick={this.handleModalShowHide}>
                    Cancel
                  </Button>
                  <Button type="submit" onClick={this.filterSearchData} id="modalValidate" variant="success">
                    Filter
                  </Button>
                </Modal.Footer>
              </Modal>
              {this.state.dataStatus === "false" ? (
                <Spinner />
              ) : (
                <div className="row">
                  <div
                    className="col-md-12 grid-margin stretch-card"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title">Project Details</h4>
                        <div className="table-responsive">
                          <table className="table" id="requestedProject">
                            <thead>
                              <tr>
                                <th>SN.</th>
                                <th>Project Name</th>
                                <th>Company Name</th>
                                <th>No. of Professionals</th>
                                <th>Types of Developers</th>
                                <th>Status</th>
                                <th>View Details</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.data.length === 0 ? (
                                <tr>
                                  <td colSpan="6" className="text-center">
                                    No Records Found
                                  </td>
                                </tr>
                              ) : (
                                this.state.data.map((ele, i) => {
                                  if (
                                    i < this.state.page * 5 &&
                                    this.state.totalCol <= i
                                  ) {
                                    return (
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td>{ele.name}</td>
                                        <td>{ele.companyName}</td>
                                        <td>{ele.devNeed}</td>
                                        <td>{ele.devType}</td>

                                        {ele.status === "Active" ? (
                                          <td className="text-success">
                                            {ele.status}
                                          </td>
                                        ) : (
                                          <>
                                            <td className="text-danger">
                                              {ele.status}
                                            </td>
                                          </>
                                        )}

                                        <td>
                                          <div className="reqview">
                                            <NavLink to="/view-requested-project">
                                              <button
                                                className=""
                                                onClick={this.singleView}
                                                value={ele.id}
                                              >
                                                <NavLink to="/view-requested-project">
                                                  <button
                                                    className="mdi mdi-eye"
                                                    value={ele.id}
                                                    id="buttonPadding"
                                                  ></button>
                                                </NavLink>
                                              </button>
                                            </NavLink>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                })
                              )}
                            </tbody>
                          </table>
                          <Pagination
                            count={parseInt(this.state.data.length / 5) + 1}
                            page={this.state.page}
                            onChange={this.handlePagination}
                            color="primary"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          false
        )}
      </>
    );
  }
}

export default RequestedProject;