import React, { Component } from 'react';
import './hiredProfile.css';
import { MDBDataTable } from "mdbreact";
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';
import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';


class ShortListedProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "https://www.digitizedresume.com/api/admin/",
      data: [],
      dataStatus: "false",
      showHide: false,
    };
  }

  setUserId = (e) => {
    e.preventDefault();
    // alert(e.target.value);
    sessionStorage.setItem("userId", e.target.value);
  }

  componentDidMount() {
    var id = sessionStorage.getItem("aId");
    let formData = new FormData();
    formData.append("id", id);
    var url = this.state.url + "singleHiredUserData.php";
    Axios.post(url, formData)
      .then((res) => {
        this.setState({
          data: res.data,
          dataStatus: "true"
        });
        // console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleModalShowHide = (e) => {
    e.preventDefault();
    this.setState({
      showHide: !this.state.showHide
    });
  }

  resetFilter = (e) => {
    this.setState({
      dataStatus: "true"
    });
    var id = sessionStorage.getItem("aId");
    let formData = new FormData();
    formData.append("id", id);
    var url = this.state.url + "singleHiredUserData.php";
    Axios.post(url, formData)
      .then((res) => {
        this.setState({
          data: res.data,
          dataStatus: "true"
        });
        // console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  filterSearchData = (e) => {
    e.preventDefault();
    var nameSearchHireProfile = document.getElementById("nameSearchHireProfile").value;
    var userIdSearchHireProfile = document.getElementById("userIdSearchHireProfile").value;

    if (nameSearchHireProfile !== "" || userIdSearchHireProfile !== "") {
      var id = sessionStorage.getItem("aId");
      this.setState({
        dataStatus: "false",
        showHide: !this.state.showHide
      });

      var SQL = "";

      SQL = "SELECT wishlist.id, wishlist.userId, wishlist.userName, wishlist.adminId, wishlist.adminName, wishlist.sortlistDate, wishlist.hiringStatus, wishlist.hiringDate, wishlist.interviewStatus, user.uniqueId FROM wishlist,user WHERE wishlist.adminId='" + id + "' AND wishlist.hiringStatus='Hired' AND user.id=wishlist.userId";
      if (nameSearchHireProfile !== "") {
        SQL += " AND wishlist.userName LIKE '%" + nameSearchHireProfile + "%'";
      }
      if (userIdSearchHireProfile !== "") {
        SQL += " AND user.uniqueId LIKE '" + userIdSearchHireProfile + "'";
      }

      SQL += ";";

      let formData = new FormData();
      formData.append("sql_Query", SQL);

      console.log(SQL);

      var searchURL = this.state.url + "filterData/filterData.php";

      Axios.post(searchURL, formData).then((res) => {
        // alert(res.data);
        this.setState({
          data: res.data,
          dataStatus: "true"
        });
      })

    }
    else {
      swal({
        title: "Error!",
        text: "Please fill at least any one field to filter!",
        button: "Close",
        icon: "error",
        timer: 3000,
        button: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      });
    }
  }

  removeFromSort = (e) => {
    var id = e.target.value;
    var userId = e.target.id;
    swal({
      title: "Are you sure?",
      text: "Once removed, you will have to hire again!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          // alert (e.target.value);
          this.setState({
            dataStatus: "false"
          });

          var aId = sessionStorage.getItem("aId");
          let formData = new FormData();
          formData.append("id", id);
          formData.append("userId", userId);
          formData.append("aId", aId);
          var url = this.state.url + "removeWishlistedProfile.php";
          Axios.post(url, formData)
            .then((res) => {
              var id = sessionStorage.getItem("aId");
              let formData = new FormData();
              formData.append("id", id);
              var url = this.state.url + "singleHiredUserData.php";
              Axios.post(url, formData)
                .then((res) => {
                  this.setState({
                    data: res.data,
                    dataStatus: "true"
                  });
                  swal({
                    title: "Removed!",
                    text: "Successfully removed!",
                    button: "Close",
                    icon: "success",
                    timer: 3000,
                    button: {
                      confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                      }
                    }
                  });
                  // console.log(res.data);
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
            .catch(function (error) {
              console.log(error);
            });

        }
      });
  }

  render() {
    return (
      <div>
        {sessionStorage.getItem("aId") ? (
          <>
            <div className="page-header">
              <h3 className="page-title">Hired Profile</h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  {/* <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Hired profile</a></li> */}
                  <li className="breadcrumb-item active" aria-current="page">Hired profile</li>
                </ol>
              </nav>
            </div>
            <div className="row my-4">
              <div className="col-md-8"></div>
              <div className="col-md-4 text-right sticky">
                <button className="btn btn-sm btn-info" onClick={this.handleModalShowHide}>Filter <i className="fa fa-filter"></i></button>
                &nbsp;
                <button className="btn btn-sm btn-danger" onClick={this.resetFilter}>Rest Filter <i className="fa fa-close"></i></button>
              </div>
            </div>
            <Modal show={this.state.showHide}>
              <Modal.Header>
                <Modal.Title>Filter Hired Interview</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <div className="row">
                    <div className=" mt-2 col-md-6">
                      <label className="form-label" for="nameSearchHireProfile">Enter User Name</label>
                      <input type="text" className="form-control" name="nameSearchHireProfile" id="nameSearchHireProfile" placeholder="Enter User Name" ></input>
                    </div>
                    <div className="mt-2 col-md-6">
                      <label className="form-label" for="userIdSearchHireProfile">Enter User Id</label>
                      <input type="text" className="form-control" name="userIdSearchHireProfile" id="userIdSearchHireProfile" placeholder="Enter User Id" ></input>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" id="modalSkip" onClick={this.handleModalShowHide}>
                  Cancel
                </Button>
                <Button type="submit" onClick={this.filterSearchData} id="modalValidate" variant="success">
                  Filter
                </Button>
              </Modal.Footer>
            </Modal>

            {this.state.dataStatus === "false" ? (
              <Spinner />
            ) : (
              <div className="row">
                <div className="col-md-12 grid-margin stretch-card bg-white shadow-sm" style={{ marginTop: "10px" }}>
                  <div className="card shadow-none">
                    <div className="card-body">
                      <h4 className="card-title">Team Details</h4>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>SN.</th>
                              <th>User ID</th>
                              <th>User Name</th>
                              <th>Hired Date</th>
                              <th>Explore Profile</th>
                              {/* <th>Chat</th> */}
                              <th>Remove Profile</th>
                            </tr>
                          </thead>
                          <tbody id="myTable">
                            {this.state.data.length === 0 ? (
                              <tr>
                                <td colSpan="6" className="text-center">
                                  No Records Found
                                </td>
                              </tr>
                            ) : (
                              this.state.data.map((ele, i) => {
                                return (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{ele.uniqueId}</td>
                                    <td>{ele.userName}</td>
                                    <td>{ele.hiringDate}</td>
                                    <td>
                                      <button onClick={this.setUserId} value={ele.userId} className="btn btn-primary btn-sm infoButton1">
                                        <NavLink className="text-center" to="/user_profile">
                                          <button value={ele.userId} className="btn btn-primary btn-sm infoButton2"> &#9432; </button>
                                        </NavLink>
                                      </button>
                                    </td>
                                    {/* <td>
                                      <button onClick={this.setUserId} value={ele.userId} className="btn btn-success btn-sm infoButton1">
                                        <NavLink className="text-center" to="/chat">
                                          <button value={ele.userId} className="btn btn-success btn-sm infoButton2">Chat</button>
                                        </NavLink>
                                      </button>
                                    </td> */}
                                    <td><button className="btn btn-danger btn-sm" value={ele.id} id={ele.userId} onClick={this.removeFromSort}>Remove</button></td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : false}
      </div>

    )
  }
}
export default ShortListedProfile