import React, { Component } from 'react';
import './notification.css';
import Axios from 'axios';
import Notification from "./notification.jpg";
import swal from 'sweetalert';

class NotificationAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            notificationType: 'user',
            notification: ''
        };
    }

    handle = (e) => {

        if (e.target.value.trim() === "") {
            e.target.value = "";
        }

        this.setState({ [e.target.name]: e.target.value });

        if (e.target.value !== "") {
            document.getElementById("error" + e.target.name).innerHTML = "";
        }

        if (e.target.name === "notificationType") {
            if (document.getElementById("notificationType").value !== "") {
                document.getElementById("errornotificationType").innerHTML = "";
            }
            else {
                document.getElementById("errornotificationType").innerHTML = "This field is required. Please select any.";
            }
        }

        if (e.target.name === "notification") {
            if (document.getElementById("notification").value !== "") {
                document.getElementById("errornotification").innerHTML = "";
            }
            else {
                document.getElementById("errornotification").innerHTML = "This field is required. Please enter notification.";
            }
        }
    }

    submit = (e) => {
        e.preventDefault();
        var check = 0;

        if (document.getElementById("notificationType").value === "") {
            check++;
            document.getElementById("errornotificationType").innerHTML = "This field is required. Please select any.";
        }
        else {
            document.getElementById("errornotificationType").innerHTML = "";
        }

        if (document.getElementById("notification").value === "") {
            check++;
            document.getElementById("errornotification").innerHTML = "This field is required. Please enter notification.";
        }
        else {
            document.getElementById("errornotification").innerHTML = "";
        }


        if (check === 0) {
            document.getElementsByClassName("fa-spin")[0].classList.add("fa-refresh");
            document.getElementById("notificationAddButton").disabled = true;
            let formData = new FormData();
            formData.append("notificationType", this.state.notificationType);
            formData.append("notification", this.state.notification);

            var url = this.state.url + "addNotification.php";
            Axios.post(url, formData).then((res) => {
                // alert(res.data);
                document.getElementsByClassName("fa-spin")[0].classList.remove("fa-refresh");
                document.getElementById("notificationAddButton").disabled = false;
                this.setState({ notificationType: "user" });
                document.getElementById("notification_form").reset();
                swal({
                    title: "Added!",
                    text: "Notification added successfully!",
                    button: "Close",
                    icon: "success",
                    timer: 3000,
                    button: {
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                        }
                    }
                });
            });

        }

    }

    render() {
        return (
            <>
                {sessionStorage.getItem("aId") ? (
                    <>
                        <div>
                            <div className="page-header">
                                <h3 className="page-title">Notification</h3>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Notification</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Notification</li>
                                    </ol>
                                </nav>
                            </div>

                            <div className="row bg-white">
                                <div className="col-md-7 col-12 my-3">
                                    <div className="admin__profile">
                                        <h6 className="pl-3 admin__profile_title">Add Notification</h6>
                                        <form className="mt-4 mb-5 notification_form" id="notification_form" onSubmit={this.submit}>
                                            <div className="row px-4">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="notificationType">Select Notification Type</label>

                                                        <select onChange={this.handle} class="form-control form-control-lg" name="notificationType" id="notificationType">
                                                            <option value="user" selected>User</option>
                                                            <option value="company">Company</option>
                                                        </select>

                                                    </div>
                                                    <p className="error" id="errornotificationType"></p>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <div className="form-group">
                                                        <label for="notification">Enter Notification</label>
                                                        <textarea class="form-control" onChange={this.handle} name="notification" id="notification" rows="3"></textarea>
                                                    </div>
                                                    <p className="error" id="errornotification"></p>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <button type="submit" className="login_button mt-3 shadow" id="notificationAddButton">Add <i class="fa fa-spin"></i></button>
                                                    <button type="reset" className="reset_button mt-3 shadow">Reset</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className="col-md-5">
                                    <img src={Notification} className="img-fluid" />
                                </div>

                            </div>

                        </div>
                    </>
                ) : false}
            </>
        )
    }
}
export default NotificationAdd