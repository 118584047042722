import React, { Component } from 'react';
import './req-project.css';
import { MDBDataTable } from "mdbreact";
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';
import Pagination from "@material-ui/lab/Pagination";
import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';


class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "https://www.digitizedresume.com/api/admin/",
      data: [],
      dataStatus: "false",
      page: 1,
      totalCol: 0,
      searchBy: '',
      showHide: false,
    };
  }

  handlePagination = (e, val) => {
    if (this.state.page != val) {
      if (val > this.state.page) {
        this.setState({
          page: val,
          totalCol: val * 5 - 5,
        });
      } else {
        this.setState({
          page: val,
          totalCol: val * 5 - 5,
        });
      }
    }
  }

  componentDidMount() {
    var url = this.state.url + "getLeadsData.php";
    Axios.get(url)
      .then((res) => {
        this.setState({
          data: res.data,
          dataStatus: "true",
        });
        // console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  singleView = (e) => {
    e.preventDefault();
    // alert(e.target.value);
    sessionStorage.setItem("leadsViewID", e.target.value);
  }

  delete = (e) => {
    e.preventDefault();
    var id = e.target.value;

    swal({
      title: "Are you sure?",
      text: "Once removed, you won't be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          let formData = new FormData();
          formData.append("id", id);
          var url = this.state.url + "deleteLeads.php";

          Axios.post(url, formData)
            .then((res) => {
              this.setState({
                dataStatus: "false"
              });
              swal({
                title: "Deleted!",
                text: "Job deleted successfully!",
                button: "Close",
                icon: "success",
                timer: 3000,
                button: {
                  confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                  }
                }
              });
              var url = this.state.url + "getLeadsData.php";
              Axios.get(url)
                .then((res) => {
                  this.setState({
                    dataStatus: "true",
                    data: res.data
                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
            .catch((err) => {
              // console.log(err);
              this.setState({
                res: err,
              })
            });
        }
      });
  }

  handleModalShowHide = (e) => {
    e.preventDefault();
    this.setState({
      showHide: !this.state.showHide
    });
  }

  resetFilter = (e) => {
    this.setState({
      dataStatus: 'false'
    })
    var url = this.state.url + "getLeadsData.php";
    Axios.get(url)
      .then((res) => {
        this.setState({
          data: res.data,
          dataStatus: "true",
        });
        // console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  filterSearchData = (e) => {
    e.preventDefault();
    var nameSearchLead = document.getElementById("nameSearchLead").value;
    var refBySearchLead = document.getElementById("refBySearchLead").value;
    var subjectSearchLead = document.getElementById("subjectSearchLead").value;

    if (nameSearchLead !== "" || refBySearchLead !== "" || subjectSearchLead !== "") {
      this.setState({
        dataStatus: "false",
        showHide: !this.state.showHide
      });

      var SQL = "";
      var totalQuery = 0;

      SQL = "SELECT * FROM feedback WHERE";
      if (nameSearchLead !== "") {
        if (totalQuery > 0) {
          SQL += " AND name LIKE '%" + nameSearchLead + "%'";
          totalQuery += 1;
        }
        else {
          SQL += " name LIKE '%" + nameSearchLead + "%'";
          totalQuery += 1;
        }
      }
      if (refBySearchLead !== "") {
        if (totalQuery > 0) {
          SQL += " AND userName LIKE '%" + refBySearchLead + "%'";
          totalQuery += 1;
        }
        else {
          SQL += " userName LIKE '%" + refBySearchLead + "%'";
          totalQuery += 1;
        }
      }
      if (subjectSearchLead !== "") {
        if (totalQuery > 0) {
          SQL += " AND subject LIKE '%" + subjectSearchLead + "%'";
          totalQuery += 1;
        }
        else {
          SQL += " subject LIKE '%" + subjectSearchLead + "%'";
          totalQuery += 1;
        }
      }

      SQL += ";";

      let formData = new FormData();
      formData.append("sql_Query", SQL);

      console.log(SQL);

      var searchURL = this.state.url + "filterData/filterData.php";

      Axios.post(searchURL, formData).then((res) => {
        // alert(res.data);
        this.setState({
          data: res.data,
          dataStatus: "true"
        });
      })

    }
    else {
      swal({
        title: "Error!",
        text: "Please fill at least any one field to filter!",
        button: "Close",
        icon: "error",
        timer: 3000,
        button: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      });
    }
  }

  render() {
    return (
      <>
        {sessionStorage.getItem("aId") ? (
          <>
            <div>
              <div className="page-header">
                <h3 className="page-title">Leads</h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="!#" onClick={(event) => event.t()}>
                        Leads
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      All Leads
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row my-4">
                <div className="col-md-8"></div>
                <div className="col-md-4 text-right sticky">
                  <button className="btn btn-sm btn-info" onClick={this.handleModalShowHide}>Filter <i className="fa fa-filter"></i></button>
                  &nbsp;
                  <button className="btn btn-sm btn-danger" onClick={this.resetFilter}>Rest Filter <i className="fa fa-close"></i></button>
                </div>
              </div>
              <Modal show={this.state.showHide}>
                <Modal.Header>
                  <Modal.Title>Filter Leads</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <div className="row">
                      <div className=" mt-2 col-md-6">
                        <label className="form-label" for="nameSearchLead">Enter Name</label>
                        <input type="text" className="form-control" name="nameSearchLead" id="nameSearchLead" placeholder="Enter Name" ></input>
                      </div>
                      <div className=" mt-2 col-md-6">
                        <label className="form-label" for="refBySearchLead">Enter Ref. By</label>
                        <input type="text" className="form-control" name="refBySearchLead" id="refBySearchLead" placeholder="Enter Ref. By" ></input>
                      </div>
                      <div className="mt-2 col-md-12">
                        <label className="form-label" for="subjectSearchLead">Enter Subject</label>
                        <input type="text" className="form-control" name="subjectSearchLead" id="subjectSearchLead" placeholder="Enter Subject" ></input>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="danger" id="modalSkip" onClick={this.handleModalShowHide}>
                    Cancel
                  </Button>
                  <Button type="submit" onClick={this.filterSearchData} id="modalValidate" variant="success">
                    Filter
                  </Button>
                </Modal.Footer>
              </Modal>
              {this.state.dataStatus === "false" ? (
                <Spinner />
              ) : (
                <div className="row">
                  <div
                    className="col-md-12 grid-margin stretch-card"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title">Leads Details</h4>
                        <div className="table-responsive">
                          <table className="table" id="leadsTable">
                            <thead>
                              <tr>
                                <th>SN.</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Subjects</th>
                                <th>Ref. By</th>
                                <th>View Details</th>
                                <th>Delete Leads</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.data.length === 0 ? (
                                <tr>
                                  <td colSpan="6" className="text-center">
                                    No Records Found
                                  </td>
                                </tr>
                              ) : (
                                this.state.data.map((ele, i) => {
                                  if (
                                    i < this.state.page * 5 &&
                                    this.state.totalCol <= i
                                  ) {
                                    return (
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td>{ele.name}</td>
                                        <td>{ele.email}</td>
                                        <td>{ele.subject}</td>
                                        <td>{ele.userName}</td>

                                        <td>
                                          <div className="reqview">
                                            <NavLink to="/view-leads">
                                              <button
                                                className=""
                                                onClick={this.singleView}
                                                value={ele.id}
                                              >
                                                <NavLink to="/view-leads">
                                                  <button
                                                    className="mdi mdi-eye"
                                                    value={ele.id}
                                                    id="buttonPadding"
                                                  ></button>
                                                </NavLink>
                                              </button>
                                            </NavLink>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="admindelete">
                                            <button
                                              className=""
                                              onClick={this.delete}
                                              className="mdi mdi-window-close"
                                              value={ele.id}
                                              id="buttonPadding"
                                            >
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                })
                              )}
                            </tbody>
                          </table>
                          <Pagination
                            count={parseInt(this.state.data.length / 5) + 1}
                            page={this.state.page}
                            onChange={this.handlePagination}
                            color="primary"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          false
        )}
      </>
    );
  }
}

export default Leads;