import React, { Component } from 'react';
import './singleuserview.css';
import profile from "./face19.jpg";
import profile1 from "./user.jpg";
import hr from "./hr.svg";
import Exprience from './exprience';
import Education from './education';

class Profile extends Component{

    education = () =>{
        if(document.getElementById("usereducation").style.display === "none"){
            document.getElementById("usereducation").style.display = "block";
            document.getElementById("userexprience").style.display = "none";

            document.getElementById("edu").style.color = "red";
            document.getElementById("exp").style.color = "gray";


        }
    }
   exprience = () =>{
        if(document.getElementById("userexprience").style.display === "none"){
            document.getElementById("userexprience").style.display = "block";
            document.getElementById("usereducation").style.display = "none";

            document.getElementById("edu").style.color = "gray";
            document.getElementById("exp").style.color = "red";

        }
    }



    render(){
        return(
            <div className="row">
                <div className="col-md-12 col-12">
                    <div className="User_profile bg-white">
                        <h6 className="pl-3 User_profile_title">Profile</h6>

                        <div className="row">
                            <div className="col-md-4 pt-4 text-center">

                               <img src={profile1} className="img-fluid profile__image" />

                                <div className="hr-image mt-2">
                                    <img src={hr} className="hr img-fluid" />
                                </div>

                                <div className="address text-left mt-4">
                                    <p>
                                        <i className="mdi mdi-email-variant pl-5"></i>
                                        <span className="pl-3">Dummy@gmail.com</span>
                                    </p>
                                    <p>
                                       <i className="mdi mdi-phone-in-talk pl-5"></i>
                                        <span className="pl-3">91+ 123345678</span>
                                    </p>
                                    <p>
                                       <i className="mdi mdi-map-marker-radius pl-5"></i>
                                        <span className="pl-3">San martin , 840001</span><br></br>
                                      
                                    </p>
                                </div>

                                <div className="hr-image mt-2">
                                    <img src={hr} className="hr img-fluid" />
                                </div> 

                                <div className="skill text-left mt-4">
                                    <h5 className="pl-5">Skills</h5>
                                    <ul>
                                        <li>Html</li>
                                        <li>Css</li>
                                        <li>Javascript</li>
                                        <li>React js</li>
                                        <li>UI / UX Designer</li>
                                    </ul>
                                </div>
                            </div>  



                            <div className="col-md-8 px-4 user_details pt-4">
                                <h4>Evan Josh</h4>
                                <p className="post">Web Developer</p>
                                <span className="avaliable">Available FullTime</span>

                                <div className="discription mt-2">
                                    <span>Looking for a position where I can integrate strategies to 
                                        develop and expand existing skills, brand and product evolution,
                                        and media endorsement.
                                    </span>
                                </div>
                                <div className="domain mt-3">
                                   <i className="mdi mdi mdi-web"></i> <a href="!#"> https://example.com </a>
                                </div>

                                <div className="row user_Qualification p-2 mt-5">
                                    <div className="col-md-11 border-bottom">
                                        <ul>
                                            <li id="edu" onClick={this.education}> <i className="mdi mdi-book-open-variant"></i> Education</li>
                                            <li id="exp" onClick={this.exprience}><i className="mdi mdi-account-star"></i> Exprience</li>
                                        </ul>
                                    </div>  
                                </div>


                                <div className="row p-2 mt-4">
                                    <div className="col-md-12" id="usereducation">
                                        <Education />
                                    </div>
                                
                                    <div className="col-md-12" id="userexprience" style={{display:'none'}}>
                                       <Exprience />
                                    </div>
                                
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Profile