import React, { Component } from 'react';
import './coupan.css';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';



export class EditCoupon extends Component {
    // updateCoupon.php
    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            couponId: '',
            limitation: '',
            status: '',
            amount: '',
            dataStatus: "false"
        };
    }

    componentDidMount() {
        var id = sessionStorage.getItem("couponId");
        let formData = new FormData();
        formData.append("id", id);
        // console.log(obj)
        var url = this.state.url + "getSingleCouponData.php";
        Axios.post(url, formData).then((res) => {
            // console.log(res.data);
            this.setState({
                couponId: res.data[0].couponId,
                limitation: res.data[0].limitation,
                status: res.data[0].status,
                image: res.data[0].image,
                amount: res.data[0].amount,
                dataStatus: "true"
            });
        });
    }
    handle = (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value })
    }

    update = (e) => {
        e.preventDefault();
        var id = sessionStorage.getItem("couponId");
        let formData = new FormData();
        formData.append("id", id);
        formData.append("status", this.state.status);
        formData.append("limitation", this.state.limitation);
        // console.log(obj)
        var url = this.state.url + "updateCoupon.php";
        Axios.post(url, formData).then((res) => {
            // console.log(res.data);
            swal({
                title: "Updated!",
                text: "Coupon updated successfully!",
                button: "Close",
                icon: "success",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        });
    }

    render() {
        return (
            <div>
                <div className="page-header">
                    <h3 className="page-title">Coupon</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                Coupon
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Edit Coupon
                            </li>
                        </ol>
                    </nav>
                </div>

                {this.state.dataStatus === "true" ?
                    <div className="row">
                        <div className="col-md-11 mx-auto px-0 bg-white pb-4 coupan_info">
                            <form onSubmit={this.update}>
                                <div className="col-md-12 px-0 mx-0 coupan_heading">
                                    <h1>Create Coupon</h1>
                                </div>
                                <div className="row px-4">

                                    <div className="col-md-4 mt-3">
                                        <label>Status</label>
                                        {this.state.status === "Active" ?
                                            <select id="status" name="status" className="px-2" onChange={this.handle}>
                                                <option value="Active" selected>Active</option>
                                                <option value="Deactive">Deactive</option>
                                            </select> :
                                            <select id="status" name="status" className="px-2" onChange={this.handle}>
                                                <option value="Active">Active</option>
                                                <option value="Deactive" selected>Deactive</option>
                                            </select>
                                        }
                                    </div>

                                    <div className="col-md-4 mt-3">
                                        <label>Limit</label>
                                        <input type="number" id="limitation" name="limitation" value={this.state.limitation} onChange={this.handle} className="px-2" />
                                    </div>

                                    <div className="col-md-4 mt-3">
                                        <label>Amount</label>
                                        <input readOnly type="number" value={this.state.amount} className="px-2" />
                                    </div>

                                    <div className="col-md-4 mt-3">
                                        <label>Coupon Code</label>
                                        <input readOnly type="text" value={this.state.couponId} className="px-2" />
                                    </div>



                                    <div className="col-md-12 mt-4">
                                        <button type="submit" className="Save_changes shadow">Save Changes</button>
                                        &nbsp;&nbsp;
                                        <Link exact to="/view-coupon">
                                            <button className="Save_changes shadow bg-danger">Back</button>
                                        </Link>
                                    </div>


                                </div>
                            </form>
                        </div>
                    </div> :
                    <Spinner />
                }


            </div>
        )
    }
}