import React, { Component } from 'react';
import './coupan.css';
import Axios from 'axios';
import swal from 'sweetalert';

export class Coupan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            couponStatus: 'Active',
            couponAmount: 1,
            couponLimit: 1,
            couponId: "",
        }
    }

    handle = (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value })
    }

    submit = (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append("status", this.state.couponStatus);
        formData.append("amount", this.state.couponAmount)
        formData.append("limitation", this.state.couponLimit)
        formData.append("couponId", this.state.couponId)
        var url = this.state.url + "addCoupon.php";
        Axios.post(url, formData).then((res) => {
            // alert(res.data);
            if (res.data === 1) {
                document.getElementById("couponAddForm").reset();
                swal({
                    title: "Added!",
                    text: "Coupon added successfully!",
                    button: "Close",
                    icon: "success",
                    timer: 3000,
                    button: {
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                        }
                    }
                });
                this.setState({
                    couponStatus: 'Active',
                    couponAmount: 1,
                    couponLimit: 1,
                    couponId: "",
                })
            }
            else {
                swal({
                    title: "Error!",
                    text: "This Coupon Id already exists. Coupon Id must be unique!",
                    button: "Close",
                    icon: "error",
                    timer: 3000,
                    button: {
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                        }
                    }
                });
            }
        })
    }


    render() {
        return (
            <div>
                <div id="couponAddAlert">
                    <p id="coupon"></p>
                </div>
                <div className="page-header">
                    <h3 className="page-title">Coupon</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                Create Coupon
                            </li>
                        </ol>
                    </nav>
                </div>

                <div className="row">
                    <div className="col-md-11 mx-auto px-0 bg-white pb-4 coupan_info">
                        <form onSubmit={this.submit} id="couponAddForm">
                            <div className="col-md-12 px-0 mx-0 coupan_heading">
                                <h1>Create Coupon</h1>
                            </div>
                            <div className="row px-4">

                                <div className="col-md-4 mt-3">
                                    <label>Status</label>
                                    <select className="px-2" id="couponStatus" name="couponStatus" onChange={this.handle}>
                                        <option value="Active" selected>Active</option>
                                        <option value="Deactive">Deactive</option>
                                    </select>
                                </div>

                                <div className="col-md-4 mt-3">
                                    <label>Amount</label>
                                    <input type="number" placeholder="Enter Amount" required onChange={this.handle} value={this.state.couponAmount} id="couponAmount" name="couponAmount" className="px-2" />
                                </div>

                                <div className="col-md-4 mt-3">
                                    <label>Limit</label>
                                    <input type="number" placeholder="Enter Limit" required onChange={this.handle} value={this.state.couponLimit} id="couponLimit" name="couponLimit" className="px-2" />
                                </div>

                                <div className="col-md-6 mt-3">
                                    <label>Coupon Id</label>
                                    <input type="text" placeholder="Enter Coupon Id" onChange={this.handle} value={this.state.couponId} id="couponId" name="couponId" className="px-2" /><br />
                                    <small>Please keep the coupon id field blank to generate the coupon id autometically.</small>
                                </div>


                                <div className="col-md-12 mt-4">
                                    <button type="submit" className="create_coupan shadow">Create Coupon </button>
                                </div>


                            </div>
                        </form>
                    </div>


                    {/* <div className="col-md-12 mt-4">
                        <div className="row">
                            <div className="col-md-1">

                            </div>
                            <div className="col-md-5 coupan_sec">
                                <h6 className="active_coupon">Active</h6>
                                <h6 className="amount_coupon">₹ 400</h6>
                                <h6 className="limit_coupon">Limit - 1 
                                    <i className="mdi mdi-information-outline" data-toggle="tooltip" data-placement="top" title="Only one time user can use this coupon. If you want to use more time then you purchase more coupons."></i>
                                </h6>
                               
                                <h3>DRTG34560</h3>
                            </div>
                        </div>
                    </div> */}

                </div>




            </div>
        )
    }
}